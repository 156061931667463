import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue';
import Index from '../views/Index.vue';
import Dplb from '../views/Dplb.vue';
import Register from '../views/Register.vue';
import Dpxq from '../views/Dpxq.vue';
import Cpxq from '../views/Cpxq.vue';

// 帮助中心
import Lxwm from '../views/bzzx/Lxwm.vue';
import Gsjj from '../views/bzzx/Gsjj.vue';
import Dzgl from '../views/bzzx/Dzgl.vue';
import Zclc from '../views/bzzx/Zclc.vue';
import Yhxy from '../views/bzzx/Yhxy.vue';
import Ysxy from '../views/bzzx/Ysxy.vue';
import Psfw from '../views/bzzx/Psfw.vue';
import Yhxy_ysxy from '../views/bzzx/Yhxy_ysxy.vue'

// 进货单
import Jhd from '../views/jhd/Jhd.vue'
import Tjdhd from '../views/jhd/Tjdhd.vue'
import Tjwx from '../views/jhd/Tjwx.vue'
import Tjxx from '../views/jhd/Tjxx.vue'
import Wxcg from '../views/jhd/Wxcg.vue'

// 个人中心 > 我的订单
import grzx from '../components/Grzx'
import Mydd from '../views/dingdan/Mydd.vue'
import Ddxq from '../views/dingdan/Ddxq.vue'
import Zhxx from '../views/dingdan/Zhxx.vue'
import Wdyhq from '../views/dingdan/Wdyhq.vue'
import Gr_dzgl from '../views/dingdan/Gr_dzgl.vue'

import Yhq from '../views/Yhq.vue'
import Search from '../views/Search.vue'

// 活动页面
import Zdms from '../views/huodong/Zdms.vue'
import Mrxp from '../views/huodong/Mrxp.vue'
import Xsms from '../views/huodong/Xsms.vue'

import Yyzz from '../views/huodong/Yyzz.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: Index  // 首页
	},
	{
		path:'/login',
		component:Login  //登录
	},
	{
		path: '/Dplb',
		component: Dplb  //店铺列表
	},
	{
		path:'/Register',
		component:Register  //注册
	},
	{
		path:'/Dpxq',
		component:Dpxq  //店铺详情
	},
	{
		path:'/Cpxq',
		component:Cpxq  //产品详情
	},
	{
		path:'/Search',
		component:Search  //搜索页
	},
	// 帮助中心
	{
		path:'/Lxwm',
		component:Lxwm // 联系我们
	},
	{
		path:'/Gsjj',
		component:Gsjj  //公司介绍
	},
	{
		path:'/Dzgl',
		component:Dzgl  //地址管理
	},
	{
		path:'/Zclc',
		component:Zclc // 如何注册
	},
	{
		path:'/Yhxy',
		component:Yhxy  //用户协议
	},
	{
		path:'/Ysxy',
		component:Ysxy  //隐私协议
	},
	{
		path:'/Psfw',
		component:Psfw  //配送服务
	},
	{
		path:'/Yhxy_ysxy',
		component:Yhxy_ysxy  //用户详情--隐私协议
	},
	// 进货单
	{
		path:'/Jhd',
		component:Jhd  // 进货单
	},
	{
		path:'/Tjdhd',
		component:Tjdhd  // 提交进货单
	},
	{
		path:'/Tjwx',
		component:Tjwx  // 微信支付
	},
	{
		path:'/Tjxx',
		component:Tjxx  // 线下支付
	},
	{
		path:'/Wxcg',
		component:Wxcg  // 微信支付成功
	},
	// 个人中心
	{
		path:'/grzx',
		component:grzx
	},
	{
		path:'/Mydd',
		component:Mydd // 我的订单
	},
	{
		path:'/Ddxq',
		component:Ddxq  //订单详情
	},
	{
		path:'/Wdyhq',
		component:Wdyhq  //我的优惠券
	},
	{
		path:'/Zhxx',
		component:Zhxx  //账号信息
	},
	{
		path:'/Gr_dzgl',
		component:Gr_dzgl  //地址管理
	},
	// 优惠券
	{
		path:'/Yhq',
		component:Yhq  //优惠券
	},
	// 活动页面
	{
		path:'/Zdms',
		component:Zdms  //整点秒杀
	},
	{
		path:'/Xsms',
		component:Xsms  // 显示秒杀
	},
	{
		path:'/Mrxp',
		component:Mrxp  // 每日新品
	},
	{
		path:'/Yyzz',
		component:Yyzz  //营业执照
	},
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
