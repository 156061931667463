<template>
	<div class="noselect" style="overflow: hidden;">
		<Top></Top>
		<div class="main" style="margin-top: 6px;">
			<div class="center">
				<!-- nav导航 轮播图 -->
				<div class="nav">
					<div class="nav-left" style="">
						<div class="yihang" style="margin-top: 9px;width: 20%;">
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_tool_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;line-height: 39px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_security_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_brush_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_clean_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_lighting_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_wre_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_ele_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_Inte_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_transport_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_chemistry_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;" alt=""></div>
							<div style="height: 39px;"><img src="../assets/image/index/header_icon_tool_default.png" style="width: 24px;height: 23px;margin-top: 8px;margin-left: 16px;line-height: 39px;" alt=""></div>
						</div>
						<div style="display: flex;flex-wrap: wrap;height:39px;line-height: 39px;">
							<!-- <span style="height: 39px;display: flex;flex-wrap: wrap;" v-for="(item,index) in home_list" @click="title_id(item)">
								<div style="width: 95px;margin-top: 7px;margin-left: 16px;height:45px;cursor:pointer;margin-bottom: 6px;line-height: 45px;">{{item.name}}</div>
							</span> -->
							<!-- <ul> -->
								<li style="list-style: none;width: 60px;margin-top: 6.5px;margin-left: 16px;height:39px;font-size: 14px;overflow: hidden;cursor: pointer;" v-for="(item,index) in home_list" @click="title_id(item)" @mousemove="mouseOver(item)">{{item.name}}</li>
							<!-- </ul> -->
						</div>
						
						<!-- <div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_security_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_brush_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_clean_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_lighting_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_wre_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>电动工具/焊接工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_ele_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_Inte_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_transport_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div>
						<div class="yihang" style="margin-top: 9px;">
							<div><img src="../assets/image/index/header_icon_chemistry_default.png" style="width: 24px;height: 23px;margin-top: 8px;" alt=""></div>
							<div>电动</div>
							<div>工具</div>
							<div>电动工具</div>
						</div> -->
						
					</div>
					
					<!-- 移入移出模态框 -->
					<div class="modelBox" style="position: absolute;display:none;" ref="modelBox">
						<!-- <div>1111</div> -->
						<div @mouseleave='mouseleave()' ref="modelBox1" style="height:459px;">
							<div style="display: flex;flex-wrap: wrap;padding-top: 5px;">
								<div style="font-weight: bold;margin-left: 30px;margin-top: 10px;height:30px;">
									{{big_title}}
								</div>
								<div style="width: 150px;padding-left: 30px;margin-top: 10px;height: 30px;" v-for="item in home_list2"  @click="title_id(item)">
									{{item.name}}
								</div>
							</div>
						</div>
					</div>
					<div class="nav-middle">
						<el-carousel height="443px">
						    <el-carousel-item  v-for="(item,index) in banner_list">
								<img style="width: 788px;height: 443px;" :src="'https://em.3qwujin.com/Uploads/image/'+item.thumb" alt="" srcset="">
						    </el-carousel-item>
						</el-carousel>
					</div>
					<div class="nav-right">
						<div class="top">
							<div>
								<img v-if="user_imag==''" src="../assets/image/index/icon_headpor_default.png" style="width: 64px;height: 64px;" alt="" srcset="">
								<img v-else style="width: 64px;height: 64px;" :src="user_imag" alt="" srcset="">
							</div>
							<div>
								<p style="margin-top: 26px;">Hi~欢迎您</p>
								<p v-if="user_news==null" class="logo" style="margin-top: 6px;"><router-link :to="{ path: '/Register' }"><span>注册</span></router-link>|<router-link :to="{ path: '/login' }"><span>登录</span></router-link></p>
								<router-link v-else :to="{ path: '/mydd' }"><p style="color: #D70A16;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;margin-top: 6px;">个人中心 ></p></router-link>
							</div>
						</div>
						<div class="line"></div>
						<div class="middle">
							<div>
								<router-link :to="{ path: '/mydd' }">
									<img src="../assets/image/index/sy_icon_order.png" alt="" srcset="">
									<p>我的订单</p>
								</router-link>
							</div>
							<div>
								<router-link :to="{ path: '/jhd' }">
									<img src="../assets/image/index/sy_icon_shopcart.png" alt="">
									<p>我的进货单</p>
								</router-link>
							</div>
							<div>
								<router-link :to="{ path: '/wdyhq' }">
									<img src="../assets/image/index/sy_icon_coupon.png" alt="">
									<p>我的优惠券</p>
								</router-link>
							</div>
						</div>
						<div class="line" style="margin-top: 12px;"></div>
						<div class="bottom" style="overflow: hidden;">
							<p style="background: linear-gradient(270deg, rgba(255,121,136,0) 0%, #D70A16 100%);font-size: 16px;font-weight: 600;line-height: 32px;color: #FFFFFF;padding-left: 8px;">特价促销</p>
							<ul style="margin: 22px auto;" v-for="item in tj_list" @click="click_shopping(item)">
								<li><img :src="item.big_img" style="width: 64px;height: 64px;" alt=""></li>
								<li style="width: 190px;">
									<p style="font-size: 14px;font-weight: 400;height:40px;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="display: flex;align-items: center;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;">￥{{item.money}}</span> <img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- 领券中心 -->
				<router-link :to="{ path:'/yhq' }">
					<img src="../assets/image/index/sy_coupon.png" style="margin-top: 35px;" alt="" srcset="">
				</router-link>
				<!-- 品牌推荐 -->
				<p style="margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;">品牌推荐</p>
				<img src="../assets/image/index/编组.png" alt="">
				<!-- 限时秒杀 -->
				<div class="seckill">
					<div class="djs" @click="to_xsms()" style="cursor: pointer;">
						<!-- <div style="margin-top: 325px;">
							<span style="margin-left: 52px;">01</span>
							<span style="margin-left: 12px;">02</span>
							<span style="margin-left: 12px;">03</span>
						</div> -->
					</div>
					<div style="display: flex;width: 1100px;overflow: hidden;">
						<el-button size="mini" type="primary" icon="el-icon-arrow-left" @click="xsms_Left()"></el-button>
						<div style="overflow: hidden;">
							<ul style="width: 100%;" ref="wids">
								<li v-for="item in xsms_List" @click="click_shopping(item)">
									<div style="width: 200px;height: 200px;margin: 20px 0px 12px 0px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:-13px;left:-8px;" alt="">
										<img style="width: 200px;height:200px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="margin-bottom: 8px;height: 44px;">{{item.goodsname}}</p>
									<p style="margin-bottom: 8px;font-size: 12px;color: #666666;">产品编码:{{item.codes}}</p>
									<p class="p">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<el-button size="mini" type="primary" icon="el-icon-arrow-right" @click="xsms_Left()"></el-button>
					</div>
				</div>
				<!-- 每日新品 整点秒杀 优质店铺 -->
				<div class="plate">
					<!-- 每日新品板块 -->
					<span v-if="mrxp_List!=''">
						<div class="mrxp">
							<router-link :to="{path:'/mrxp'}"><div style="margin-left: 20px;padding-top: 20px;">每日新品<div><img src="../assets/image/index/sy_new_right_24.png" alt=""></div></div></router-link>
							<div>
								<el-button size="mini" type="primary" icon="el-icon-arrow-left" @click="mrxp_Left()"></el-button>
								<div style="display: flex;overflow: hidden;height: 312px;justify-content: center;width: 100%;">
									<ul ref="wid">
										<li v-for="(item,index) in mrxp_List" :key="index" @click="click_shopping(item)">
											<div style="width: 150px;height: 150px;margin: 20px 0px 12px 0px;position: relative;">
												<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
												<img style="width: 150px;height:150px;" :src="item.img" alt="" class="mengban">
											</div>
											<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
											<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
											<p style="margin-top: 8px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.pcm}}</p>
										</li>
									</ul>
								</div>
								<el-button size="mini" type="primary" icon="el-icon-arrow-right" @click="mrxp_Left()"></el-button>
							</div>
						</div>
					</span>
					<span v-else>
						<img src="../assets/image/index/sy_pic_hjjx.png" alt="" srcset="">
					</span>
					
					<!-- 整点秒杀板块 -->
					<div class="zdms">
						<span v-if="zdmsList!=''">
							<div style="display: flex;justify-content: space-between;cursor:pointer;" @click="to_zdms()">
								<div style="margin-left: 20px;">
									整点秒杀<div><img src="../assets/image/index/sy_new_right_24.png" alt=""></div>
								</div>
								<ul class="bianse" style="width:360px;height:76px;background: #F9F9F9;text-align:center;">
									<li v-for="(item,index) in scekill_time_arr">
										<p :class="{'bianse':isActive=index}" style="font-size:14px;margin-bottom:2px;line-height: 76px;">{{item}}:00</p><!-- <p style="font-size:12px;">已结束</p> -->
									</li>
								</ul>
							</div>
							<div style="overflow-x: scroll;">
								<ul>
									<li v-for="item in zdmsList" @click="click_shopping(item)" style="cursor:pointer;">
										<div style="width: 150px;height: 150px;margin: 20px 0px 12px 0px;position: relative;">
											<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
											<img style="width: 150px;height:150px;" :src="item.images" alt="" class="mengban">
										</div>
										<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;-webkit-line-clamp: 2;overflow: hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
										<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
										<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.price}}</p>
									</li>
								</ul>
							</div>
						</span>
						<span v-else>
							<img src="../assets/image/index/sy_pic_zskf.png" alt="" srcset="" style="width: 100%;height: 100%;">
						</span>
					</div>
					
					<!-- 优质店铺板块 -->
					<!-- <router-link :to="{ path: '/Dplb' }"> -->
						<!-- <div class="yzdp" :to="{ path: '/Dplb' }">
							<p style="width: 96px;margin-top:20px;margin-left:20px;height: 36px;font-size: 24px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 36px;">优质店铺</p>
							<p style="margin-top:4px;margin-left:20px;width: 128px;height: 24px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 24px;">精选多家品质好店</p>
							<p class="p" style="width: 100px;margin-left: 20px;height: 38px;background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);border-radius: 2px;
							text-align:center;line-height:38px;margin-top:8px;">进入店铺</p>
							<img src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						</div> -->
						<img src="../assets/image/index/sy_pic_yzdp1.png" style="width: 214px;height:390px;" alt="" srcset="">
					<!-- </router-link> -->
				</div>
				<!-- 五金丝网 -->
				<!-- <div v-for="item in gorylist"> -->
					<div class="wjsw">
						<div class="left1 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">仪器仪表</p> <br>
								<div v-for="item in classlist">
									<li style="overflow: hidden;" @click="title_id(item)">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;height:38px;font-weight: 400;margin-bottom:8px;overflow: hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="wjsw">
						<div class="left2 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist1}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">工控配电</p> <br>
								<div v-for="item in classlist1">
									<li style="overflow: hidden;" @click="title_id(item)">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList1" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList1" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;font-weight: 400;margin-bottom:8px;height: 38px;overflow: hidden;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="wjsw">
						<div class="left3 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist2}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">传动密封</p> <br>
								<div v-for="item in classlist2">
									<li style="overflow: hidden;" @click="title_id(item)">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList2" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList2" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;font-weight: 400;margin-bottom:8px;height: 38px;overflow: hidden;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="wjsw">
						<div class="left4 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist3}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">清洁办公</p> <br>
								<div v-for="item in classlist3">
									<li style="overflow: hidden;" @click="title_id(item)">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList3" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList3" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;font-weight: 400;margin-bottom:8px;height: 38px;overflow: hidden;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="wjsw">
						<div class="left5 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist4}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">动力工具</p> <br>
								<div v-for="item in classlist4">
									<li style="overflow: hidden;" @click="title_id(item)">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList4" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList4" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;font-weight: 400;margin-bottom:8px;height: 38px;overflow: hidden;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="wjsw">
						<div class="left6 left">
							<ul>
								<!-- <p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">{{gorylist5}}</p> <br> -->
								<p style="width:259px;margin-top: 40px;font-size: 32px;font-weight: 500;margin-bottom: 32px;font-family: PingFangSC-Medium, PingFang SC;color: #FFFFFF;text-align: center;">工具耗材</p> <br>
								<div v-for="item in classlist5">
									<li style="overflow: hidden;">{{item.name}}</li>
								</div>
							</ul>
						</div>
						<div class="center">
							<ul>
								<li v-for="item in goodsList5" @click="click_shopping(item)">
									<div style="width: 150px;height: 150px;margin: 20px 16px 12px 16px;position: relative;">
										<img src="../assets/image/3Qshuiyin.png" style="width: 150px;height:140px;position: absolute;top:7px;left:0px;" alt="">
										<img style="width: 150px;height:150px;" :src="item.image" alt="" class="mengban">
									</div>
									<p style="width: 150px;height: 44px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 22px;overflow:hidden;text-overflow: ellipsis;">{{item.goodsname}}</p>
									<p style="margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码:{{item.id}}</p>
									<p style="margin-top: 8px;height: 30px;font-size: 20pxfont-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">￥{{item.protmoney}}</p>
								</li>
							</ul>
						</div>
						<div class="right">
							<p style="font-size: 24px;font-weight:500;margin-left:20px;margin-top:20px;"><span style="margin:8px;"><img src="../assets/image/index/sy_icon_rank_fire.png" alt=""></span>销量排行榜</p>
							<ul style="margin: 16px auto;" v-for="item in RankingList5" @click="click_shopping(item)">
								<li style="width: 64px;height: 64px;margin-left:20px;position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 57px;height:53px;position: absolute;top:6px;left:3px;" alt="">
									<img class="px" :src="item.image" style="width: 64px;height: 64px;" alt="">
								</li>
								<li style="margin-left:16px;width:148px;">
									<p style="font-size: 14px;font-weight: 400;margin-bottom:8px;height: 38px;overflow: hidden;">{{item.goodsname}}</p>
									<p style="display: flex;justify-content: space-between;"><span style="font-size: 16px;font-weight: 600;color: #D70A16;margin-right:25px;overflow: hidden;text-overflow: ellipsis;">￥{{item.protmoney}}</span><img src="../assets/image/index/sy_butten_buy.png" alt="" srcset=""></p>
								</li>
							</ul>
						</div>
					</div>
				<!-- </div> -->
				<!-- 产品推荐 -->
				<div class="recommend">
					<p style="margin-top:40px;">产品推荐<img style="margin-left:14px;width:32px;height:32px;" src="../assets/image/index/sy_new_right_24.png" alt="" srcset=""></p>
					<ul>
						<li v-for="(item, index) in red_List" :key="index" @click="click_shopping(item)" class="rules">
							<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 16px 28px 16px;">
								<img src="../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
								<img style="width: 228px;height: 228px;" :src="item.image" alt="">
							</div>
							<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.goodsname}}</p>
							<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：{{item.codes}}</p>
							<div style="margin-top: 8px;">
								<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥{{item.protmoney}}</span>
								<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">已售{{item.sales}}</span>
							</div>
						</li>
					</ul>
					<el-pagination style="text-align: center;margin-bottom: 48px;"
					  @current-change="handleCurrentChange"
					  :current-page.sync="currentPage"
					  background
					  layout="prev, pager, next"
					  :total="counts"
					  :page-size="20">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../components/Top.vue';
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Index',
		data(){
			return{
				big_title:'',
				home_list:[], //一级导航标签
				home_list1:[],
				home_list2:[],
				red_List:[], // 商品推荐列表
				mrxp_List:[], // 每日新品列表
				gorylist:'', // 模块一级标签
				gorylist1:'', // 模块一级标签
				gorylist2:'', // 模块一级标签
				gorylist3:'', // 模块一级标签
				gorylist4:'', // 模块一级标签
				gorylist5:'', // 模块一级标签
				l:0  ,// 点击左右切换
				xsms_List:[] ,// 限时购/Detail/oppay
				cid:183,
				cid1:'',
				classlist:[], // 商品板块分类列表
				goodsList:[],  // 8个商品列表
				RankingList:[],  // 商品排行榜
				
				classlist1:[], // 商品板块分类列表
				goodsList1:[],  // 8个商品列表
				RankingList1:[],  // 商品排行榜
				
				classlist2:[], // 商品板块分类列表
				goodsList2:[],  // 8个商品列表
				RankingList2:[],  // 商品排行榜
				
				classlist3:[], // 商品板块分类列表
				goodsList3:[],  // 8个商品列表
				RankingList3:[],  // 商品排行榜
				
				classlist4:[], // 商品板块分类列表
				goodsList4:[],  // 8个商品列表
				RankingList4:[],  // 商品排行榜
				
				classlist5:[], // 商品板块分类列表
				goodsList5:[],  // 8个商品列表
				RankingList5:[],  // 商品排行榜
				
				zdmsList:[] ,// 整点秒杀
				zdms_timeList:[],
				user_news:[],  // 用户信息
				user_imag:'',  // 用户头像
				city_list:[],
				tj_list:[],  // 特价促销
				one:'',
				two:'',
				three:'',
				four:'',
				five:'',
				six:'',
				onename:'',
				onename1:'',
				onename2:'',
				onename3:'',
				onename4:'',
				onename5:'',
				banner_list:[],
				scekill_time_arr:[],
				isActive:'',
				index:'',
				currentPage:1, // 当前页
				counts:null, // 总页数
			}
		},
		components: {
			Top,Footer
		},
		created(){
			this.recommended_List(); // 商品推荐
			this.everyproduct_List()
			this.goryList()
			this.xsms()
			this.zdms()
			this.zdms_time()
			this.cuxiao()
			this.homelist()
			setTimeout(res=>{
				this.model_list()
				this.Ranking()
				
			},1000)
			this.banner()
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.user_imag=this.user_news.avatar
		},
		methods:{
			// 一级导航标签
			homelist(){
				this.$axios.get('/Home/home_list').then(res=>{
					this.home_list=res.data.data
				})
			},
			banner(){
				this.$axios.get('/home/banner_list').then(res=>{
					this.banner_list=res.data.data
				})
			},
			// 移入展示二级分类
			mouseOver(item){
				let move=this.$refs.modelBox
				move.style.display='block'
				let move1=this.$refs.modelBox1
				move1.style.display='block'
				this.home_list2=item.list
				this.big_title=item.name
			},
			// 移除隐藏二级分类
			mouseleave(){
				let move=this.$refs.modelBox1
				move.style.display='none'
				let move1=this.$refs.modelBox
				move1.style.display='none'
			},
			cuxiao(){
				this.$axios.get('/Home/salet').then(res=>{
					this.tj_list=res.data.data
				})
			},
			// 每日新品
			everyproduct_List(){
				this.$axios.get('/Home/everyproduct').then(res=>{
					if(res.data.data==''){
						this.mrxp_List=''
					}else{
						this.mrxp_List=res.data.data
					}
				})
			},
			mrxp_Left(){
				let wid=this.$refs.wid
				this.l-=153
				wid.style.marginLeft=this.l+'px'
				if(wid.style.marginLeft=='-2448px'){
					this.l=153
				}
			},
			// 限时秒杀
			xsms(){
				this.$axios.get('/Detail/oppay').then(res=>{
					this.xsms_List=res.data.data
				})
			},
			xsms_Left(){
				let wid=this.$refs.wids
				this.l-=153
				wid.style.marginLeft=this.l+'px'
				if(wid.style.marginLeft=='-1530px'){
					this.l=153
				}
			},
			// 整点秒杀
			zdms(){
				this.$axios.get('/Home/msdate').then(res=>{
					if(res.data.data==''){
						this.zdmsList=''
					}else{
						this.zdmsList=res.data.data
					}
				})
			},
			zdms_time(){
				this.$axios.get('Home/mskill').then(res=>{
					this.zdms_timeList=res.data.data
					this.scekill_time_arr=res.data.data.scekill_time_arr
				})
			},
			// 商品左侧的一级分类
			goryList(){
				this.$axios.get('/Home/gorylist').then(res=>{
					// console.log(res.data.data)
					// 商品分类的一级标题
					// this.gorylist=res.data.data[0].name
					// this.gorylist1=res.data.data[1].name
					// this.gorylist2=res.data.data[2].name
					// this.gorylist3=res.data.data[3].name
					// this.gorylist4=res.data.data[4].name
					// this.gorylist5=res.data.data[5].name
					
					this.one=res.data.data[0].id
					
					this.two=res.data.data[1].id
					this.three=res.data.data[2].id
					this.four=res.data.data[3].id
					this.five=res.data.data[4].id
					this.six=res.data.data[5].id
					
					
				})
			},
			// 模块展示商品
			model_list(){
				this.$axios.get('/Home/categoods',{params:{cid:this.one}}).then(res=>{
					this.classlist=res.data.fl
					this.goodsList=res.data.data
				})
				this.$axios.get('/Home/categoods',{params:{cid:this.two}}).then(res=>{
					this.classlist1=res.data.fl
					this.goodsList1=res.data.data
				})
				this.$axios.get('/Home/categoods',{params:{cid:this.three}}).then(res=>{
					this.classlist2=res.data.fl
					this.goodsList2=res.data.data
				})
				this.$axios.get('/Home/categoods',{params:{cid:this.four}}).then(res=>{
					this.classlist3=res.data.fl
					this.goodsList3=res.data.data
				})
				this.$axios.get('/Home/categoods',{params:{cid:this.five}}).then(res=>{
					this.classlist4=res.data.fl
					this.goodsList4=res.data.data
				})
				this.$axios.get('/Home/categoods',{params:{cid:this.six}}).then(res=>{
					this.classlist5=res.data.fl
					this.goodsList5=res.data.data
				})
			},
			// // 商品排行榜
			Ranking(){
				this.$axios.get('/Home/saleph',{params:{cid:this.one}}).then(res=>{
					this.RankingList=res.data.data
					if(this.RankingList.length>6){
						this.RankingList.splice(7)
					}
				})
				this.$axios.get('/Home/saleph',{params:{cid:this.two}}).then(res=>{
					this.RankingList1=res.data.data
					if(this.RankingList1.length>6){
						this.RankingList1.splice(7)
					}
				})
				this.$axios.get('/Home/saleph',{params:{cid:this.three}}).then(res=>{
					this.RankingList2=res.data.data
					if(this.RankingList2.length>6){
						this.RankingList2.splice(7)
					}
				})
				this.$axios.get('/Home/saleph',{params:{cid:this.four}}).then(res=>{
					this.RankingList3=res.data.data
					if(this.RankingList3.length>6){
						this.RankingList3.splice(7)
					}
				})
				this.$axios.get('/Home/saleph',{params:{cid:this.five}}).then(res=>{
					this.RankingList4=res.data.data
					if(this.RankingList4.length>6){
						this.RankingList4.splice(7)
					}
				})
				this.$axios.get('/Home/saleph',{params:{cid:this.six}}).then(res=>{
					this.RankingList5=res.data.data
					if(this.RankingList5.length>6){
						this.RankingList5.splice(7)
					}
				})
			},
			// 商品推荐
			recommended_List(){
				this.$axios.get('/Home/recommended',{params:{page:this.currentPage}}).then(res=>{
					this.red_List=res.data.data
					this.counts=Number(res.data.$count[0].count)
				})
			},
			handleCurrentChange(val) {
				this.recommended_List()
			},
			// 点击商品跳转详情页
			click_shopping(item){
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
			to_xsms(){
				this.$router.push({
					path:'/xsms',
				})
			},
			to_jhd(){
				this.$router.push({
					path:'/jhd',
				})
			},
			to_zdms(){
				this.$router.push({
					path:'/zdms',
				})
			},
			// 根据分类id跳转查询
			title_id(item){
				console.log(item)
				this.$router.replace({
					path:'/Search',
					query:{
						'title_id':item.id
					}
				})
			}
		},
	}
</script>

<style scoped lang="less">
	.rules{
		-moz-user-select: none;
		
		-webkit-user-select: none;
		
		-ms-user-select: none;
		
		-khtml-user-select: none;
		
		user-select: none;
	}
	.el-carousel__item h3 {
	    color: #475669;
	    font-size: 14px;
	    opacity: 0.75;
	    line-height: 150px;
	    margin: 0;
	  }
	
	  .el-carousel__item:nth-child(2n) {
	     background-color: #99a9bf;
	  }
	  
	  .el-carousel__item:nth-child(2n+1) {
	     background-color: #d3dce6;
	  }
	// 模态框
	.modelBox{
		width: 804px;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0px 4px 12px 4px rgba(0,0,0,0.08), 0px 2px 4px 0px rgba(0,0,0,0.12), 0px 1px 2px -2px rgba(0,0,0,0.16);
		border-radius: 4px;
		margin-left: 270px;
		z-index: 999;
		div:hover{
			cursor: pointer;
			// display: flex;
		}
	}
	.main{
		// width: 1903px;
		overflow: hidden;
		background-color: #F4F5F5;
		padding: 1px;
	}
	.nav{
		width: 100%;
		display: flex;
		.nav-left{
			width: 270px;
			height: auto;
			background: #FFFFFF;
			border-radius: 0px 0px 4px 4px;
			display: flex;
			.yihang{
				background-color: #FFFFFF;
				div{
					height: 39px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 39px;
					text-align: center;
					margin-bottom: 6px;
				}
			}
			// .yihang:hover{
			// 	background-color: #EEEEEE;
			// 	cursor:pointer;
			// }
		}
		.nav-middle{
			width: 788px;
			height: 443px;
			margin-top: 16px;
			margin-left: 16px;
			background-color: #FFFFFF;
		}
		.nav-right{
			width: 270px;
			height: 443px;
			background: #FFFFFF;
			border-radius: 2px;
			margin-left: 16px;
			margin-top: 16px;
			.top{
				display: flex;
				font-size: 14px;
				img{
					margin-top: 20px;
					margin-left: 20px;
					margin-right: 16px;
				}
				.logo{
					width: 91px;
					display: flex;
					justify-content: space-between;
				}
			}
			.line{
				width: 238px;
				height: 1px;
				background: #EEEEEE;
				margin: auto;
			}
			.middle{
				margin-top: 20px;
				display: flex;
				justify-content: space-around;
				div{
					text-align: center;
					img{
						width: 48px;
						height: 48px;
						background: #FFE7E6;
					}
					p{
						width: 70px;
						// margin-top: 8px;
						height: 22px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
						line-height: 22px;
					}
				}
			}
			.bottom{
				// width: 212px;
				height: 227px;
				margin:10px auto;
				p{
					height: 32px;
					border-radius: 2px;
					margin: auto;
					margin-left: 16px;
					margin-right: 16px;
				}
				ul{
					display: flex;
					margin-left: 16px;
					li{
						cursor:pointer;
						img{
							margin-left: 16px;
						}
						p{
							margin-left: 12px;
						}
					}
				}
			}
		}
	}
	// 限时秒杀
	.seckill{
		height: 408px;
		background: #FFFFFF;
		border-radius: 4px;
		margin-top: 40px;
		display: flex;
		.djs{
			width: 259px;
			height: 409px;
			border-radius: 4px;
			background: url('../assets/image/index/sy_pic_sale1.png');
			background-size: 100% 100%;
			span{
				width: 44px;
				height: 44px;
				background: #323436;
				border-radius: 2px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				padding: 7px 10px ;
				margin-top: 318px;
			}
		}
		.el-button--primary{
			width: 32px;
			height: 64px;
			background: #000000;
			opacity: 0.2;
			margin-top: 163px;
		}
		ul{
			display: flex;
			li{
				cursor:pointer;
				width: 248px;
				height: 392px;
				// background: #FFFFFF;
				margin-left: 11px;
				margin-top: 24px;
				p{
					width: 220px;
				}
				.p{
					width: 220px;
					height: 38px;
					line-height: 38px;
					background-image: url('../assets/image/index/sy_butten_salebuy.png');
					padding-left: 8px;
					color: #D70A16;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
	}
	// 每日新品
	.plate{
		width: 100%;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		.mrxp{
			width: 557px;
			height: 390px;
			background: #FFFFFF;
			border-radius: 4px;
			div{
				// width: 100%;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				line-height: 36px;
				display: flex;
				div{
					width: 24px;
					height: 24px;
					margin-left: 14px;
					margin-top: 7px;
				}
			}
			.el-button--primary{
					width: 32px;
					height: 64px;
					background: #000000;
					opacity: 0.2;
					margin-top: 107px;
				}
			ul{
				width: 100%;
				display: flex;
				// text-align: center;
				justify-content: space-between;
				li{
					width: 153px;
					margin-left: 11px;
					cursor:pointer;
				}
			}
		}
		.zdms{
			width: 557px;
			height: 390px;
			background: #FFFFFF;
			border-radius: 4px;
			div{
				div{
					// width: 100%;
					height: 36px;
					font-size: 24px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333333;
					line-height: 36px;
					display: flex;
					// justify-content: space-between;
					margin-top: 20px;
					div{
						width: 24px;
						height: 24px;
						margin-left: 14px;
						margin-top: 7px;
					}
				}
				.bianse{
					background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
					cursor:pointer;
				}
			}
			
			ul{
				width: 100%;
				display: flex;
				// text-align: center;
				// justify-content: space-evenly;
				.el-button--primary{
					width: 32px;
					height: 64px;
					background: #000000;
					opacity: 0.2;
					margin-top: 107px;
				}
				li{
					width: 150px;
					margin-left: 16px;
				}
			}
		}
		.yzdp{
			padding: 1px;
			width: 214px;
			height: 400px;
			background: linear-gradient(180deg, rgba(240,228,199,0.88) 0%, rgba(249,240,229,0.53) 100%);
			cursor:pointer;
			img{
				width: 202px;
				height: 250px;
				margin-top: 20px;
				margin-left: 12px;
			}
		}
	}
	// 五金丝网
	.wjsw{
		width: 100%;
		height: 664px;
		background-color: #FFF;
		border-radius: 4px;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		.left{
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					cursor:pointer;
					margin-left: 20px;
					margin-top: 20px;
					height: 30px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 40px;
					text-align: center;
					width: 96px;
					height: 40px;
					background: rgba(255,255,255,0.25);
					border-radius: 2px;
					border: 1px solid rgba(255,255,255,0.5);
				}
			}
		}
		.left1{ // 仪器仪表
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_wjw.png");
			background-image: url('../assets/image/fl/yqyb1.png');
			background-size: 100% 100%;
		}
		.left2{ // 工控配电
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_gjdq.png");
			background-image: url('../assets/image/fl/gkpd1.png');
			background-size: 100% 100%;
		}
		.left3{ // 传动密封
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_sgnj.png");
			background-image: url('../assets/image/fl/cdmf1.png');
			background-size: 100% 100%;
		}
		.left4{ // 清洁办公
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_djzm.png");
			background-image: url('../assets/image/fl/qjbg1.png');
			background-size: 100% 100%;
		}
		.left5{ // 动力工具
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_dz (3).png");
			// background-image: url("../assets/image/index/sy_pic_dz (4).png");
			background-image: url('../assets/image/fl/dlgj1.png');
			background-size: 100% 100%;
		}
		.left6{ // 工具耗材
			width: 259px;
			height: 664px;
			// background-image: url("../assets/image/index/sy_pic_lbaf.png");
			background-image: url('../assets/image/fl/gjhc1.png');
			background-size: 100% 100%;
		}
		.center{
			width: 830px;
			ul{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				li{
					cursor:pointer;
				}
			}
		}
		.right{
			width: 270px;
			height: 664px;
			background-image: url("../assets/image/index/sy_pic_rankingList .png");
			ul{
				display: flex;
				justify-content: space-around;
				cursor:pointer;
				.px{
					// background: url("../assets/image/index/sy_icon_rank_top1.png") no-repeat;
					z-index: 999;
				}
			}
		}
	}
	// 产品推荐
	.recommend{
		p{
			width: 175px;
			height: 48px;
			font-size: 32px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 48px;
			display: flex;
			align-items: center;
		}
		ul{
			width: 102%;
			display: flex;
			margin-top: 32px;
			justify-content: flex-start;
			margin-left: -16px;
			flex-wrap: wrap;
			margin-bottom: 48px;
			li{
				cursor:pointer;
				margin-top: 10px;
				width: 260px;
				height: 400px;
				background: #FFFFFF;
				border-radius: 4px;
				margin-left: 16px;
				div{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}
</style>