<template>
	<div class="noselect" style="">
		<div class="whole">
			<div class="top">
				<div class="center">
					<div class="top-head">
						<div>
							<img style="width: 16px;height: 16px;" src="../../assets/image/index/header_icon_address_default.png" alt="">
							<span style="color: #777777;margin-left: 4px;">
								<div class="block">
								  <el-cascader size="mini"
								    :options="options"
								  	placeholder="天津市"
								    ></el-cascader>
								</div>
							</span>
							<span style="width: 180px;margin-left: 25px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
								<div v-if="user_news==null">
									<router-link :to="{ path: '/' }"><span>登录</span></router-link>
									<span style="margin-left: 10px;margin-right: 10px;">|</span>
									<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
								</div>
								<div style="width: 230px;" v-else>
									Hi~ {{telephone}} 欢迎您
									<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
								</div>
							</span>
						</div>
						<div>
							<img style="width: 13px;height: 13px;" src="../../assets/image/index/header_icon_iphone_default.png" alt="">
							<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
						</div>
					</div>
				</div>
			</div>
			<div class="top1">
				<div class="center">
					<div class="top-main">
						<div class="logo">
							<div class="logo">
								<img src="../../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;cursor:pointer;" @click="to_index()">
							</div>
							<div class="steps">
								<el-steps :space="320" :active="2" finish-status="success">
								  <el-step title="进货单"></el-step>
								  <el-step title="提交订单"></el-step>
								  <el-step title="完成"></el-step>
								</el-steps>
							</div>
						</div>
					</div>
				</div>
				<div class="line" style="width: 100%;height: 1px;background-color: #eee;"></div>
			</div>
			<el-dialog title="收货地址" :visible.sync="dialogFormVisible">
				<el-form ref='form' :model="form" label-width='120px'>
					<div style="display: flex;justify-content: space-around;">
						<div><span><span style="color: #D70A16;">* </span>姓名: </span><input v-model='form.name' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						<div><span><span style="color: #D70A16;">* </span>电话: </span><input v-model='form.telephone' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span><span style="color: #D70A16;">* </span>地址: </span>
							<el-select placeholder="请选择省份" v-model="form.province_id" @change="sheng()" ref='sheng'>
								<el-option v-for="(item,index) in shengList" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select placeholder="请选择城市" @change="shi()" v-model="form.city_id" ref="shi">
								<el-option v-for="item in shiList" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select placeholder="请选择县/乡/街道" v-model="form.country_id" ref="qu">
								<el-option v-for="item in quList" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span><span style="color: #D70A16;">* </span>详细地址: </span><input v-model="form.address" placeholder="请填写详细地址" type="text" style="width: 650px;height: 70px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span>邮政编码: </span><input placeholder="如果不清楚,请填写000000" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						<div><span>邮箱地址: </span><input placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;margin-top: 36px;margin-left: 85px;">
						<div><span>是否为默认: </span><input type="checkbox" v-model="form.is_default" ref="moren"><span> 默认收货地址</span></div>
					</div>
				</el-form>  
				<div slot="footer" class='dialog-footer'>
					<el-button @click="dialogFormVisible=false">取消</el-button>
					<el-button type="primary" @click="addAddress()">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="修改地址" :visible.sync="dialogFormVisible1">
				<el-form ref='form' :model="obj" label-width='120px'>
					<div style="display: flex;justify-content: space-around;">
						<div><span><span style="color: #D70A16;">* </span>姓名: </span><input v-model='obj.name' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						<div><span><span style="color: #D70A16;">* </span>电话: </span><input v-model='obj.telephone' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span><span style="color: #D70A16;">* </span>地址: </span>
							<el-select placeholder="请选择省份" v-model="obj.province_id" @change="sheng1()" ref='sheng'>
								<el-option v-for="(item,index) in shengList" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select placeholder="请选择城市" @change="shi1()" v-model="obj.city_id" ref="shi">
								<el-option v-for="item in shiList" :label="item.name" :value="item.id"></el-option>
							</el-select>
							<el-select placeholder="请选择县/乡/街道" v-model="obj.country_id" ref="qu">
								<el-option v-for="item in quList" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span><span style="color: #D70A16;">* </span>详细地址: </span><input v-model="obj.address" placeholder="请填写详细地址" type="text" style="width: 650px;height: 70px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;justify-content: space-around;margin-top: 36px;">
						<div><span>邮政编码: </span><input placeholder="如果不清楚,请填写000000" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						<div><span>邮箱地址: </span><input placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
					</div>
					<div style="display: flex;margin-top: 36px;margin-left: 85px;">
						<div><span>是否为默认: </span><input type="checkbox" @click="moren($event)" v-model="obj.is_default"><span> 默认收货地址</span></div>
					</div>
				</el-form>  
				<div slot="footer" class='dialog-footer'>
					<el-button @click="dialogFormVisible1=false">取消</el-button>
					<el-button type="primary" @click="modify()">保存</el-button>
				</div>
			</el-dialog>
			<div class="center">
				<!-- 收货地址 -->
				<div class="address">
					<p>收货地址 <span><el-button type="text" @click="dialogFormVisible = true">+ 新增收货地址</el-button></span></p>
					<div v-for="(item,index) in addressList">
						<!-- <div v-if="address.cide==1"><img src="../../assets/image/jhd/submit_icon_noplace.png" style="width: 32px;height: 32px;" alt=""> 收货地址还是空的，请<span>添加</span></div> -->
						<ul ref="address" @click="on_address(index,item)"  :class="{'active':isActive===index}" style="cursor: pointer;">
							<li style="width: 100%;display: flex;margin-left: 24px;margin-top: 16px;user-selec:none;" ref="addBox">
								<div>{{item.name}}</div>
								<div style="margin-left: 12px;white-space:nowrap;">{{item.province_name}}--{{item.city_name}}--{{item.country_name}}----{{item.address}} {{item.name}} {{item.telephone}}</div>
								<div style="white-space: nowrap;">
									<span style="width: 36px;color:white;height: 22px;background: #F67B3E;border-radius: 1px;font-size:14px;margin-left: 24px;padding:0 4px 0 4px;" v-if="item.is_default==1">默认</span>
									<span style="color:#257FE6;margin-left: 24px;cursor:pointer;" @click="del_address(item,index)">删除</span>
									<span style="color:#257FE6;margin-left: 5px;cursor:pointer;" @click="modify_address(item,index)">修改</span>
								</div>
							</li>
						</ul>
					</div>
					
				</div>
				<!-- 支付方式 -->
				<div class="zhifu">
					<p>支付方式<span style="font-size: 12px;color: red;margin-left: 20px;">请选择下方支付方式</span></p>
					<div>
						<span>
							<img src="../../assets/image/jhd/details_butten_wxpay.png" alt="" ref="wxpay" @click="zhifu_wx()" style="cursor: pointer;">
							<img src="../../assets/image/jhd/details_butten_xxpay.png" alt="" ref="xxpay" @click="zhifu_xx()" style="cursor: pointer;">
						</span>
						
						<div><span>如需开具发票</span>，请联系客服电话：022-26482742</div>
					</div>
				</div>
				<!-- 商品清单 -->
				<div class="qingdan">
					<p>商品清单</p>
					<div class="right">
						<el-popover
						    placement="top-start"
						    width="200"
						    trigger="hover"
						    content="因可能存在系统缓存、页面更新导致价格变动异常等不确定性情况出现，商品售价以本结算页商品价格为准。">
						    <el-button slot="reference"><img style="margin-top: 2px;" src="../../assets/image/jhd/paymode_icon_tips.png" alt=""><span>价格说明</span></el-button>
						</el-popover>
						<div>返回进货单修改 ></div>
					</div>
				</div>
				<div class="gouwuche">
					<table>
						<thead>
							<tr>
								<td style="width: 380px;">产品信息</td>
								<td style="width: 76px;">单位</td>
								<td style="width: 375px;">产品规格</td>
								<td style="width: 94px;">单价(元)</td>
								<td style="width: 170px;">数量</td>
								<td style="width: 125px;">小计(元)</td>
							</tr>
						</thead>
						<tbody>
							<tr style="cursor: pointer;" v-for="(item,index) in cartList" @click="click_shopping(item)">
								<td style="width: 380px;display: flex;align-items: center;">
									<img :src="item.imgurl" style="width: 100px;height: 100px;margin: 20px;" alt="">
									<div style="padding-right: 29px;">
										<span>{{item.title}}</span><br><br>
										<span style="color: #666666;">产品编码: {{item.id}}</span>
									</div>
								</td>
								<td style="width: 76px;">{{item.company}}</td>
								<td style="width: 375px;padding-right: 26px;">{{item.goodstype}}</td>
								<td style="width: 94px;">{{item.protmoney}}</td>
								<td style="width: 170px;">
									{{item.goodsnum}}
								</td>
								<td style="width: 125px;">
									{{(item.goodsnum*item.protmoney).toFixed(2)}}
								</td>
							</tr>
						</tbody>
					</table>
					<!-- 优惠券 -->
					<div class="coupon">
						<p>优惠券</p>
						<div style="display: flex;justify-content: space-around;margin-top: 20px;">
							<div class="quan"  v-if="quanList==''">
								<div  v-for="item in quanList">
									<div class="big">
										<div class="small">
											<div>
												<span style="width: 48px;height: 64px;font-size: 40px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #999999;line-height: 64px;">￥99</span><br>
												<span style="width: 42px;height: 22px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 22px;">新人券</span>
											</div>
											<div class="line" style="height: 80px;border: 1px dashed #E6E6E6;"></div>
											<div>
												<span style="width: 109px;height: 27px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 27px;">满1000减99</span><br>
												<span style="width: 75px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">有效期: 30天</span>
											</div>
										</div>
									</div>
									<span style="width: 30px;height:102px;background-color: #DDDDDD;color: #FFFFFF;text-align: center;padding-top: 12px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 22px;">不可使用</span>
								</div>
								
							</div>
							<div v-else style="color: red;">
								温馨提示:暂无优惠券可用~~
							</div>
						</div>
						<div class="di" v-if="quanList==''"><p>抵用金额：<span style="color: #D70A16;">¥20</span></p><span style="color: #666666;margin-left: 15px;">优惠券1张</span></div>
					</div>
					<!-- 底部备注 -->
					<div class="remarks">
						<div style="display: flex;">
							<p style="font-size: 24px;">备注</p>
							<input type="text" v-model="comment" placeholder="若您需对订单有特殊要求，请告知我们，我们客服会第一时间跟您沟通，尽量满足您的需求!">
						</div>
						<div>
							<p>商品总件数：<span>{{allshop()}}</span></p>
							<p style="margin-top: 20px;">商品总金额：<span>{{allmoney().toFixed(2)}}</span></p>
							<!-- <p style="margin-top: 20px;">&nbsp;&nbsp;&nbsp;商品优惠：<span>-¥10</span></p> -->
							<!-- <p style="margin-top: 20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;运费：<span>¥15</span></p> -->
						</div>
					</div>
					<div class="jiesuan">
						<div style="margin-left: 1040px;">应付款: <span style="font-size: 20px;color: #D70A16;font-weight: 600;">￥{{allmoney().toFixed(2)}}</span></div>
						<div>
							<el-popover
							    placement="top-start"
							    width="50"
							    trigger="hover"
							    content="请仔细核对您的商品">
							    <el-button slot="reference" @click="go_tjdd()">提交订单</el-button>
							</el-popover>
						</div>
						
					</div>
					<!-- <div class="share_box">
					        <div id="qrcode" ref="qrcode"></div>
					</div> -->
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2';
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Tjdhd',
		data(){
			return{
				options: [{
					value: 'tainjin',
					label: '天津',
					children: [
						{
							value: '1',
							label: '河东区',
						},{
							value: '2',
							label: '红桥区',
						},{
							value: '3',
							label: '西青区',
						},{
							value: '4',
							label: '津南区',
						},{
							value: '5',
							label: '河西区',
						}
					]
				}],
				box:1,
				cartList:[],
				dialogFormVisible: false,
				dialogFormVisible1: false,
				// formLabelWidth: '120px',
				user_news:[], // 用户信息
				addressList:[],  //地址列表
				quanList:[],  // 优惠券列表
				form:{
					member_id:'',
					name:'',
					telephone:'',
					address:'',
					province_id:'',
					city_id:'',
					country_id:'',
					is_default:0,
					province_name:'',
					city_name:'',
					country_name:''
				},
				obj:{
					member_id:'',
					address_id:'',
					name:'',
					telephone:'',
					address:'',
					province_id:'',
					city_id:'',
					country_id:'',
					is_default:'',
					province_name:'',
					city_name:'',
					country_name:''
				},
				shengList:[],
				shiList:[],
				quList:[],
				member_id:'',  // 用户id
				orderList:[],   //商品清单列表
				codes:'',  // 商品id
				telephone:'',
				wxpay:'',  // 微信支付
				xxpay:'',  // 线下支付
				pay_method:'',  // 支付类型
				ziti_name:'',
				ziti_mobile:'',
				province_name:'',
				city_name:'',
				country_name:'',
				address_name:'',
				color:'',
				dispatching:'express',
				comment:'',
				cid:'',
				aid:'',
				isActive:'',
				key_car:'',
				mat:'',
				msg:'',
				quantity:'', // 商品数量
				goods_id:'', // 商品ID
				sku_str:'' // 型号ID
			}
		},
		components:{
			Footer
		},
		created () {
			let t=this
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.telephone=this.user_news.telephone
			// 立即购买
			this.codes=this.$router.currentRoute.query.order_id
			this.mat=this.$router.currentRoute.query.mat
			this.quantity=this.$router.currentRoute.query.quantity
			this.goods_id=this.$router.currentRoute.query.goods_id
			this.sku_str=this.$router.currentRoute.query.sku_str
			this.key_car=this.$router.currentRoute.query.key_car
			// this.petty=Number(this.num)*Number(this.danjia)
			this.get_quan()
			this.get_address()
			this.sheng()
			// this.shi()
			// this.qu()
			this.order_list()
		},
		methods:{
			to_index(){
				// console.log(15165132)
				this.$router.replace({
					path:'/',
				})
				this.$router.go()
			},
			 handleChange(value) {
				console.log(value)
			},
			// 总价
			allmoney() {
			    let arr = [...this.cartList];
				// console.log(arr)
			    let goodsnum = 0;
			    for (var i in this.cartList) {
					goodsnum += arr[i].protmoney * arr[i].goodsnum
			    }
			    return goodsnum
			},
			// 总件数
			allshop() {
			    let goodsnum = 0;
			    for (var i in this.cartList) {
					goodsnum += parseInt(this.cartList[i].goodsnum);
			    }
			    return goodsnum
			},
			// 修改地址
			modify_address(item){
				// console.log(item)
				this.dialogFormVisible1=true;//关闭弹窗
				this.obj.name=item.name
				this.obj.telephone=item.telephone
				this.obj.address=item.address
				this.obj.address_id=item.address_id
				this.obj.province_id=item.province_name
				this.obj.city_id=item.city_name
				this.obj.country_id=item.country_name
			},
			modify(){
				this.user_news = JSON.parse(localStorage.getItem('user'));
				this.obj.member_id = this.user_news.member_id
				this.obj.province_name=this.$refs.sheng.$children[0].value
				this.obj.city_name=this.$refs.shi.$children[0].value
				this.obj.country_name=this.$refs.qu.$children[0].value
				this.$axios.get('/Detail/upaddress',{params:this.obj}).then(res=>{
					// console.log(res)
					if(res.data.cide==0){
						this.dialogFormVisible1=false;//关闭弹窗
						this.$message.success('地址修改成功!');
						this.get_address()
					}
				})
			},
			// 获取地址列表
			get_address(){
				let t=this
				t.user_news = JSON.parse(localStorage.getItem('user'));
				this.member_id = t.user_news.member_id
				this.$axios.get('/Detail/get_address',{params:{mid:this.member_id}}).then(res=>{
					// console.log(res.data.data);
					this.addressList=res.data.data
					// this.addressList.forEach(val=>{
					// 	console.log(val.is_default)
					// 	if(val.is_default==1){
					// 		t.ziti_name=res.data.data.name,
					// 		t.ziti_mobile=res.data.data.telephone,
					// 		t.province_name=res.data.data.province_name,
					// 		t.city_name=res.data.data.city_name,
					// 		t.country_name=res.data.data.country_name,
					// 		t.address_name=res.data.data.address
					// 	}
					// })
				})
			},
			// 获取优惠券
			get_quan(){
				let t=this
				t.user_news = JSON.parse(localStorage.getItem('user'));
				this.member_id = t.user_news.member_id
				this.$axios.get('/Detail/get_vouchers',{params:{mid:this.member_id}}).then(res=>{
					// console.log(res);
					this.quanList=res.data.data
					// this.msg=res.data.msg
					// if(res.data.cide==1){
					// 	this.quList=res.data.msg
					// }
				})
			},
			// 点击选中地址
			on_address(index,item){
				let t=this
				this.isActive=index
				t.ziti_name=item.name,
				t.ziti_mobile=item.telephone,
				t.province_name=item.province_name,
				t.city_name=item.city_name,
				t.country_name=item.country_name,
				t.address_name=item.address
			},
			// 获取省
			sheng(){
				this.$axios.get('/Detail/ad_province').then(res=>{
					this.shengList=res.data.data
					// console.log(this.form.province_id)
					this.cid=this.form.province_id
					this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
						this.shiList=res.data.data
						this.aid=this.form.city_id
						this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
							this.quList=res.data.data
						})
					})
				})
				this.form.city_id=''
				this.form.country_id=''
			},
			shi(){
				this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
					this.shiList=res.data.data
					this.aid=this.form.city_id
					this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
						this.quList=res.data.data
					})
				})
				this.form.country_id=''
			},
			// 获取省
			sheng1(){
				this.$axios.get('/Detail/ad_province').then(res=>{
					this.shengList=res.data.data
					// console.log(this.form.province_id)
					this.cid=this.obj.province_id;
					this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
						this.shiList=res.data.data
						this.aid=this.obj.city_id
						this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
							this.quList=res.data.data
						})
					})
				})
				this.obj.city_id=''
				this.obj.country_id=''
			},
			shi1(){
				this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
					this.shiList=res.data.data
					this.aid=this.obj.city_id
					this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
						this.quList=res.data.data
					})
				})
				this.obj.country_id=''
			},
			del_address(item,index){
				console.log(item.address_id)
				this.$axios.get('/Detail/del_address',{params:{address_id:item.address_id}}).then(res=>{
					this.addressList.splice(index, 1)
					if(res.data.code==0){
						this.$message.success('地址删除成功!');
					}
				})
			},
			// 添加地址
			addAddress(){
				let t=this
				if(this.form.name==''){
					this.$message.error('请填写收货人的昵称');
				}else if(this.form.telephone==''){
					this.$message.error('请填写您的手机号');
				}else if(this.form.address==''){
					this.$message.error('请填写您的收货地址')
				}else{
					var obj=this.form;
					t.user_news = JSON.parse(localStorage.getItem('user'));
					obj.member_id = t.user_news.member_id
					obj.province_name=t.$refs.sheng.$children[0].value
					obj.city_name=t.$refs.shi.$children[0].value
					obj.country_name=t.$refs.qu.$children[0].value
					obj.is_default=t.form.is_default==true ? 1 : 0
					this.$axios.get('/Detail/adress',{params:obj}).then(res=>{
						// if(res.data.cide==0){
							this.dialogFormVisible=false;//关闭弹窗
							this.get_address();
						// }
					})
				}
			},
			// 获取商品清单列表
			order_list(){
				let t=this
				t.user_news = JSON.parse(localStorage.getItem('user'));
				this.member_id = t.user_news.member_id
				this.$axios.get('/Detail/suborder',{params:{
					mid:this.member_id,
					id:this.codes,
					mat:this.mat,
					key_car:this.key_car,
					quantity:this.quantity,
					goods_id:this.goods_id,
					sku_str:this.sku_str
				}}).then(res=>{
					this.cartList=res.data.carts[1].shopcarts
					// console.log(this.orderList)
				})
			},
			// 支付类型
			zhifu_wx(){
				let wxpay=this.$refs.wxpay
				let xxpay=this.$refs.xxpay
				this.pay_method='wxpay'
				console.log(this.pay_method)
				wxpay.style.border='1px solid red'
				xxpay.style.border='none'
				// console.log(wxpay)
			},
			zhifu_xx(){
				let xxpay=this.$refs.xxpay
				let wxpay=this.$refs.wxpay
				this.pay_method='outline'
				console.log(this.pay_method)
				xxpay.style.border='1px solid red'
				wxpay.style.border='none'
			},
			// 提交订单
			go_tjdd(){
				let t=this
				if(t.ziti_name==''){
					this.$message.error('请选择您的收货地址');
					return
				}else if(t.pay_method==''){
					this.$message.error('请选择您的支付方式');
					return
				}
				t.user_news = JSON.parse(localStorage.getItem('user'));
				t.member_id = t.user_news.member_id
				this.$axios.get('/Detail/buygoods',{params:{
						mid:t.member_id,
						ziti_name:t.ziti_name,
						ziti_mobile:t.ziti_mobile,
						province_name:t.province_name,
						city_name:t.city_name,
						country_name:t.country_name,
						address_name:t.address_name,
						buy_type:"dan",
						use_score:0,
						pick_up_id:0,
						ck_yupay:0,
						quan_arr:0,
						pay_method:t.pay_method,
						dispatching:t.dispatching,
						latitude:0,
						longitude:0,
						comment:t.comment,
						mat:t.mat,
						key_car:t.key_car,
						quantity:t.quantity,
						goods_id:t.goods_id,
						sku_str:t.sku_str
					}}).then(res=>{
						console.log(res)
						if(t.pay_method=='wxpay'){
							this.$router.replace({
								path:'/tjwx',
								query:{
									'url':res.data.data.code_url,
									'order_all_id':res.data.order_info[0].order_num_alias,
									'total_money':res.data.order_info[0].total_money
								}
							})
						}else if(t.pay_method=='outline'){
							this.$router.replace({
								path:'/tjxx',
								query:{
									'order_all_id':res.data.order_all_id,
									'total_money':res.data.total_money
								}
							})
						}
						// let qrcode = new QRCode('qrcode', {
						// 	width: 100, // 二维码宽度，单位像素
						// 	height: 100, // 二维码高度，单位像素
						// 	text:res.data.code_url
						           
						// });
						
				})
			},
			// 退出登录
			loginOut(){
				localStorage.clear();
				this.$router.go()
			},
			// 跳转商品详情页面
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
		}
	}
</script>

<style scoped lang="less">
	.active li div{
		color: red;
		// border: 1px solid red;
	}
	/deep/.el-input{
		width: 85%;
	}
	/deep/.el-input__suffix{
		display: none;
	}
	/deep/ .el-cascader .el-input .el-input__inner{
		border: none;
		background-color: #F8F8F8;
		width: 70%;
		padding: 0px;
	}
	.el-dialog {
	    width: 785px;
	}
	.el-dialog__header {
		border: 1px solid #eee;
	}
	
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.top-main{
		height: 215px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.steps{
				width: 960px;
				margin-top: 86px;
			}
		}
	}
	// 收货地址
	.address{
		width: 100%;
		height: auto;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		margin-top: 46px;
		margin-bottom: 40px;
		padding-bottom: 20px;
		.colorred{
			border: 1px solid red;
		}
		p{
			height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 36px;
			margin-left: 24px;
			margin-top: 24px;
			span{
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #257FE6;
				line-height: 36px;
			}
		}
		div{
			height: 24px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			line-height: 24px;
			text-align: center;
			span{
				color: #D70A16;
			}
		}
	}
	// 支付方式
	.zhifu{
		width: 100%;
		height: 140px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		p{
			height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			line-height: 36px;
			margin-left: 24px;
			margin-top: 24px;
		}
		div{
			display: flex;
			img{
				margin-left: 24px;
				margin-top: 24px;
			}
			div{
				margin-left: 40px;
				margin-top: 29px;
				span{
					color: #D70A16;
				}
			}
		}
	}
	// 商品清单
	.qingdan{
		.el-button{
			border: none;
		}
		height: 100px;
		display: flex;
		line-height: 100px;
		justify-content: space-between;
		p{
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			margin-left: 24px;
		}
		.right{
			display: flex;
			img{
				width: 16px;
				height: 16px;
			}
			div{
				margin-left: 40px;
				color: #257FE6;
				font-size: 14px;
			}
			span{
				margin-left: 4px;
				color: #999999;
				font-size: 14px;
			}
		}
	}
	// 购物车
	.gouwuche{
		font-size: 14px;
		input{
			margin-right: 12px;
			margin-left: 12px;
		}
		margin-top: 10px;
		margin-bottom: 48px;
		table{
			width: 100%;
			border-collapse: collapse;
			thead{
				tr{
					background: #EEEEEE;
				}
				height: 54px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #666666;
				line-height: 54px;
			}
			tbody{
				height: auto;
				tr{
					height: 140px;
					border-bottom: 1px solid #EEEEEE;
					
				}
			}
			td{
				padding-left: 12px;
			}
		}
		.jiesuan{
			display: flex;
			justify-content: space-between;
			height: 54px;
			background: #F5F5F5;
			border-radius: 4px;
			line-height: 54px;
			margin-top: 40px;
			button{
				width: 124px;
				height: 54px;
				background: #D70A16;
				border-radius: 4px;
				border: none;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
			}
		}
	}
	// 优惠券
	.coupon{
		width: 1360px;
		height: 252px;
		background: #F5F5F5;
		border-radius: 4px;
		border: 1px solid #DDDDDD;
		margin-top: 40px;
		p{
			font-size: 24px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #333333;
			margin-left: 24px;
			margin-top: 24px;
		}
		.di{
			display: flex;
			margin-left: 953px;
			margin-top: 12px;
			p,span{
				margin-top: 0;
				
				font-size: 16px;
			}
		}
		.quan{
			display: flex;
			.big{
				width: 343px;
				height: 112px;
				background: #FFFFFF;
				border: 1px solid #DDDDDD;
				background-image: url('../../assets/image/jhd/date_coupon_bg_nor.png');
				display: flex;
				justify-content: space-between;
			}
			.small{
				width: 343px;
				height: 112px;
				background: #CCCCCC;
				border-radius: 4px;
				background-image: url('../../assets/image/jhd/date_coupon_bg_sel.png');
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				span{
					margin-left: 34px;
					margin-right: 34px;
				}
			}
		}
	}
	// 底部备注
	.remarks{
		display: flex;
		margin-top: 40px;
		justify-content: space-between;
		padding-left: 24px;
		padding-right: 24px;
		input{
			width: 633px;
			height: 140px;
			background: #FFFFFF;
			border-radius: 4px;
			border: 1px solid #DDDDDD;
			margin-left: 24px;
			padding-left: 15px;
		}
		span{
			margin-left: 64px;
			color: #D70A16;
		}
	}
</style>