<template>
	<div style="overflow: hidden;">
		<Top></Top>
		<div class="main">
			<div class="center">
				<div class="top">
					<div class="top1">店铺列表</div>
					<div class="top2"><input type="text" placeholder="请输入店铺名称或店铺号"><div>搜索</div></div>
				</div>
				<div class="bottom">
					<ul>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
						<li>
							<div class="li1">
								<div><img src="../assets/dianhua.png" alt=""></div>
								<div>
									<p style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #333333;">大鹏五金店</p>
									<p style="font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;">店铺号:001</p>
								</div>
							</div>
							<div class="line"></div>
							<div class="li2">
								主营业务: <span>五金</span> <span>扳手</span>
							</div>
						</li>
					</ul>
				</div>
				<el-pagination style="text-align: center;margin-top: 32px;"
				  background
				  layout="prev, pager, next"
				  :total="100">
				</el-pagination>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../components/Top.vue';
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Index',
		data(){
			return{
				  
			}
		},
		components: {
			Top,Footer
		},
		created(){
			
		},
		methods:{
			
		},
	}
</script>

<style lang="less" scoped>
	.main{
		background: #F4F5F5;
		padding: 1px;
		padding-bottom: 48px;
		margin-top: 6px;
	}
	.center{
		padding: 1px;
	}
	.top{
		margin-bottom: 48px;
		width: 100%;
		height: 48px;
		display: flex;
		margin-top: 32px;
		justify-content: space-between;
		.top1{
			width: 128px;
			height: 48px;
			font-size: 32px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 48px;
		}
		.top2{
			width: 386px;
			height: 46px;
			background: #FFFFFF;
			border-radius: 2px;
			border: 2px solid #EEEEEE;
			display: flex;
			align-items: center;
			input{
				width: 287px;
				width: 386px;
				height: 46px;
				background: #FFFFFF;
				// border-radius: 2px;
				// border: 2px solid #EEEEEE;
				border: none;
				padding-left: 16px;
			}
			div{
				border-radius: 2px;
				border: 2px solid #D70A16;
				width: 99px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #D70A16;
				line-height: 46px;
				text-align: center;
			}
		}
	}
	.line{
		width: 260px;
		height: 1px;
		background: #EEEEEE;
		border-radius: 4px;
	}
	.bottom{
		ul{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li{
				width: 260px;
				height: 192px;
				background: #FFFFFF;
				border-radius: 4px;
				border: 1px solid #EEEEEE;
				margin-bottom: 16px;
				div{
					img{
						width: 65px;
						height: 65px;
						background: #FFFFFF;
						border-radius: 2px;
						border: 1px solid #EEEEEE;
						margin-left: 20px;
					}
				}
				.li1{
					display: flex;
					margin-top: 20px;
					margin-bottom: 20px;
					p{
						margin-top: 9px;
						margin-left: 12px;
					}
				}
				.li2{
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					margin-top: 13px;
					margin-left: 13px;
					span{
						border: 1px solid #EEEEEE;
						padding-left: 8px;
						padding-right: 8px;
						padding-top: 1px;
					}
				}
			}
		}
	}
</style>