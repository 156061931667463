<template>
	<div style="">
		<div class="whole">
			<div class="top">
				<div class="center">
					<div class="top-head">
						<div>
							<img style="width: 16px;height: 16px;" src="../assets/image/index/header_icon_address_default.png" alt="">
							<span style="color: #777777;margin-left: 4px;">
								<div class="block">
								  <el-cascader size="mini"
								    :options="options"
									placeholder="天津市"
								    ></el-cascader>
								</div>
							</span>
							<span style="width: 220px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
								<div v-if="user_news==null">
									<router-link :to="{ path: '/login' }"><span>登录</span></router-link>
									<span style="margin-left: 10px;margin-right: 10px;">|</span>
									<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
								</div>
								<div v-else>
									Hi~ {{telephone}} 欢迎您
									<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
								</div>
							</span>
						</div>
						<div>
							<img style="width: 13px;height: 13px;" src="../assets/image/index/header_icon_iphone_default.png" alt="">
							<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
						</div>
					</div>
				</div>
			</div>
			<div class="top1">
				<div class="center">
					<div class="top-main">
						<div class="logo">
							<div class="logo">
								<img src="../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;" @click="to_index()">
							</div>
							<div class="search">
								<input type="text" placeholder="请输入产品名称或产品编码" v-model="name" ref="name">
								<div @click="search()"><span>搜索</span></div>
							</div>
							<div class="jinhuo">
								<img style="width: 21px;height: 20px;color: #333333;" src="../assets/image/index/header_icon_shopcart_default.png" alt="">
								<span class="text1" @click="to_jhd()">我的进货单</span>
								<!-- <span class="text2">5</span> -->
							</div>
						</div>
						<div style="width:538px;height: 22px;margin:24px auto;font-size: 14px;">
							热门搜索：好货1元抢先试   尺子  口罩  手套  劳保鞋  工具  压力表  轴承  安全帽  扳手
						</div>
						<div class="sort">
							<div class="div1"><img style="width: 19px;height: 17px;" src="../assets/image/index/header_icon_pm_default.png" alt=""><span>产品分类</span></div>
							<router-link  :to="{ path: '/' }"><div class="div2">首页</div></router-link>
							<div class="div2 div3">品牌馆</div>
							<div class="div2 div3">我要合作</div>
							<router-link  :to="{ path: '/Gsjj' }"><div class="div2 div3">关于我们</div></router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<!-- 一级导航分类 -->
			<div class="yiji" style="overflow: hidden;" ref="yiji">
				<div class="center">
					<div style="text-overflow:ellipsis; white-space:nowrap;">分类 :</div>
					<ul style="cursor: pointer;">
						<li v-for="item in flList" @click="title_ids(item)">{{item.name}}</li>
					</ul>
					<div style="text-overflow:ellipsis; white-space:nowrap;margin-top: 18px;">
						<button @click="open_up()" ref="taggle" style="display: block;">展开</button>
						<button @click="open_ups()" ref="taggle1" style="display: none;">收起</button>
					</div>
				</div>
			</div>
			<!-- 二级导航分类 -->
			<div class="erji">
				<div class="center">
					<span>商品排序 :</span>
					<el-select v-model="value" placeholder="请选择" style="width: 105px;margin-left: 15px;" @change="paixu(value)">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="center">
				<div class="recommend">
				<ul>
					<li v-for="item in goodsList" @click="click_shopping(item)">
						<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 16px 28px 16px;">
							<img src="../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
							<img style="width: 228px;height: 228px;" :src="item.image" alt="">
						</div>
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.goodsname}}</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：{{item.id}}</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥{{item.protmoney}}</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">已售{{item.sales}}</span>
						</div>
					</li>
				</ul>
				</div>
				<el-pagination style="text-align: center;margin-bottom: 48px;"
				  @current-change="handleCurrentChange"
				  :current-page.sync="currentPage1"
				  background
				  layout="prev, pager, next"
				  :total="counts"
				  :page-size="20">
				</el-pagination>
				<div class="fy" style="text-align: center;margin-top: 32px;margin-bottom: 48px;" v-html="fy"></div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../components/Top.vue"
	import Footer from "../components/Footer.vue"
	export default {
		name: 'Search',
		data() {
			return {
				options:[{
					value: 'double',
					label: '综合排序'
				}, {
					value: 'money',
					label: '价格'
				}, {
					value: 'total',
					label: '销量'
				}, {
					value: 'product',
					label: '新品'
				}],
				value: 'double',
				label:'综合排序',
				goodsList:[],  // 商品列表
				flList:[], // 分类列表
				fy:'',
				name:'',
				page:1,
				user_news:'',
				user_name:'',
				telephone:'',
				title_id:'',
				currentPage1:1, // 当前页
				counts:null, // 总页数
				activeName: '1',
				isSel:true,
				isSels:false,
			}
		},
		components:{
			Footer,Top
		},
		created(){
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.telephone=this.user_news.telephone
			this.user_name=this.user_news.username
			this.name=this.$router.currentRoute.query.name
			this.title_id=this.$router.currentRoute.query.title_id
			this.search()
			this.fl()
		},
		methods:{
			open_up(){
				let yiji=this.$refs.yiji
				yiji.style.overflow='none'
				yiji.style.height='auto'
				let tag=this.$refs.taggle
				let tag1=this.$refs.taggle1
				tag.style.display='none'
				tag1.style.display='block'
			},
			open_ups(){
				let yiji=this.$refs.yiji
				yiji.style.overflow='hidden'
				yiji.style.height='54px'
				let tag=this.$refs.taggle
				let tag1=this.$refs.taggle1
				tag.style.display='block'
				tag1.style.display='none'
			},
			handleCurrentChange(val) {
				// console.log(val);
				// console.log(this.currentPage1)
				this.search()
			},
			search(){
				// if(this.$refs.name.value==''){
				// 	this.$message.error('请输入您要搜索的内容');
				// }
					this.$axios.get('/search/search',{params:{name:this.name,page:this.currentPage1,odr:this.value,cid:this.title_id}},).then(res=>{
						// console.log(res)
						this.goodsList=res.data.data
						this.counts=Number(res.data.categroup)
						// console.log(typeof(this.counts))
						if(res.data.error_code==1){
							this.$message.error('暂无该商品,请重新输入');
						}
						if(res.data.cide==1){
							this.$message.error('该分类暂无商品,请重新选择');
						}
						// this.fy=res.data.categroup
					})
			},
			to_index(){
				// console.log(15165132)
				this.$router.replace({
					path:'/',
				})
				this.$router.go()
			},
			paixu(){
				this.search()
			},
			fl(){
				this.$axios.get('/search/get_tepclas',{params:{cid:this.title_id}}).then(res=>{
					// console.log(res.data.data)
					this.flList=res.data.data
				})
				this.$axios.get('/search/get_double').then(res=>{
					this.flList=res.data.data
				})
			},
			title_ids(item){
				// console.log(item)
				this.title_id=item.id
				this.search()
			},
			// 点击商品跳转详情页
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
			to_jhd(){
				this.$router.replace({
					path:'/jhd',
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	/deep/.el-input{
		width: 110%;
	}
	/deep/.el-input__suffix{
		display: none;
	}
	/deep/ .el-cascader .el-input .el-input__inner{
		border: none;
		background-color: #F8F8F8;
		width: 70%;
		padding: 0px;
	}
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
			
		}
	}
	.top-main{
		height: 215px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.search{
				width: 732px;
				height: 46px;
				margin-top: 49px;
				display: flex;
				margin-left: 83px;
				input{
					width: 633px;
					height: 46px;
					border: none;
					background: #FFFFFF;
					border-radius: 2px;
					border: 2px solid #D70A16;
					padding-left: 16px;
				}
				div{
					width: 99px;
					height:49px;
					line-height: 49px;
					background-color: #D70A16;
					color: white;
					span{
						cursor:pointer;
						width: 47px;
						height: 30px;
						font-size: 20px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 30px;
						margin-left: 28px;
						margin-top: 8px;
					}
				}
			}
			.jinhuo{
				width: 180px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #D70A16;
				display: flex;
				// justify-content: space-around;
				align-items: center;
				margin-top: 49px;
				margin-left: 48px;
				img{
					margin-right: 10px;
					margin-left: 21px;
				}
				.text1{
					width: 80px;
					height: 22px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					margin-right: 10px;
					cursor:pointer;
				}
				.text2{
					width: 20px;
					height: 20px;
					background: #D70A16;
					border-radius: 10px;
					text-align: center;
					color: white;
				}
			}
		}
		.sort{
			height: 46px;
			// background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
			border-radius: 2px 2px 0px 0px;
			margin-top: 65px;
			display: flex;
			margin-bottom: 5px;
			.div1{
				width: 270px;
				height: 46px;
				line-height: 46px;
				background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
				border-radius: 2px 2px 0px 0px;
				img{
					margin-left: 82px;
					width: 19px;
					height: 17px;
					color: #FFFFFF;
				}
				span{
					width: 80px;
					height: 30px;
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 30px;
					margin-left: 15px;
				}
			}
			.div2{
				width: 99px;
				height: 46px;
				span-align: center;
				line-height: 46px;
				margin-left: 16px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #D70A16;
			}
			.div3{
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #333333;
			}
		}
	}
	.main{
		background-color: #F4F5F5;
		padding: 1px;
		margin-top: 5px;
	}
	.topimg{
		margin-top: 21px;
	}
	// 一级分类
	.yiji{
		width: 1920px;
		height: 54px;
		background: #FFFFFF;
		margin-top: 58px;
		line-height: 54px;
		.center{
			display: flex;
			div{
				width:100px;
				margin-right: 40px;
			}
			ul{
				display: flex;
				flex-wrap: wrap;
				li{
					margin-right: 32px;
				}
			}
		}
	}
	// 二级导航分类
	.erji{
		width: 1920px;
		height: 54px;
		background: #FFFFFF;
		margin-top: 16px;
		line-height: 54px;
	}
	.recommend{
		ul{
			width: 100%;
			display: flex;
			margin-top: 20px;
			flex-wrap: wrap;
			margin-bottom: 48px;
			li{
				margin-left: 12px;
				margin-top: 10px;
				width: 260px;
				height: 400px;
				background: #FFFFFF;
				border-radius: 4px;
				div{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}
	// 分页
	.fy{
		#page{
			.num{
				width: 34px;
				height: 34px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #BFBFBF;
			}
		}
	}
</style>