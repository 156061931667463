<template>
	<div style="">
		<Top></Top>
		<div class="box">
			<div class="center">
				<Grzx></Grzx>
				<div class="main">
					<div class="top">
						<p>我的订单</p>
						<div style="margin-left:385px;">
							<input style="padding-left: 20px;" placeholder="产品名称/订单号" v-model="keyword" type="text"><div @click="search()" style="cursor: pointer;">查询</div>
						</div>
					</div>
					<div class="line"></div>
					<div class="cont">
						<div class="topxinxi">
							<ul>
								<li style="width: 346px;">订单信息</li>
								<li style="width: 117px;">收货人</li>
								<li style="width: 137px;">订单金额</li>
								<li style="width: 130px;">
									<el-select v-model="value" placeholder="请选择" size="mini" style="width: 95px;" @change="orders(value)">
									    <el-option
									      v-for="item in options"
									      :key="item.value"
									      :label="item.label"
									      :value="item.value">
									    </el-option>
									</el-select>
								</li>
								<li style="width: 117px;">
									<el-select v-model="value1" placeholder="请选择" size="mini" style="width: 95px;" @change="dates(value)">
									    <el-option
									      v-for="item in options1"
									      :key="item.value"
									      :label="item.label"
									      :value="item.value">
									    </el-option>
									</el-select>
								</li>
								<li style="width: 147px;">操作</li>
							</ul>
						</div>
						<div class="cartList" v-for="(item,index) in cartList1" ref="order">
							<div class="bg">
								<input type="checkbox">
								<span>订单号: </span><span>{{item.order_num_alias}}</span>
								<el-button type="text">
									<!-- <el-tooltip class="item" effect="dark" content="请先勾选订单号前的选项再进行删除" placement="top"> -->
									<i class="el-icon-delete" v-if="item.order_status_id==5" @click="open(item,index)"></i>
									</el-tooltip>
								</el-button>
							</div>
							<ul>
								<li style="width: 346px;display: flex;align-items: center;">
									<img v-if="item.images==null" :src="item.goods[0].images" style="width: 100px;height: 100px;margin: 20px;" alt="">
									<img v-else :src="item.images" style="width: 100px;height: 100px;margin: 20px;" alt="">
									<div style="padding-right: 29px;">
										<span v-if="item.name==null">{{item.goods[0].name}} {{item.goods[0].str}} {{item.goods[0].number}}{{item.goods[0].danwei}}</span>
										<span v-else>{{item.name}} {{item.str}} {{item.number}}{{item.danwei}}</span><br><br>
										<span v-if="item.codes==null" style="color: #666666;">产品编码: {{item.goods[0].codes}}</span>
										<span v-else style="color: #666666;">产品编码: {{item.codes}}</span>
									</div>
								</li>
								<li style="width: 117px;">{{item.shipping_name}}</li>
								<li style="width: 137px;flex-direction: column;">
									<div v-if="item.price==null" style="color: #D70A16;">￥{{item.goods[0].price}}</div>
									<div v-else style="color: #D70A16;">￥{{item.price}}</div>
								</li>
								<li style="width: 130px;padding-left: 23px;">{{timestampToTime(item.addtime)}}</li>
								<li style="width: 117px;flex-direction: column;">
									<div v-if="item.order_status_id==5">已取消</div>
									<div v-else-if="item.order_status_id==3">待付款</div>
									<div v-else-if="item.order_status_id==22">待收货</div>
									<div v-else-if="item.order_status_id==23">待收货</div>
									<div v-else-if="item.order_status_id==14">待收货</div>
									<div v-else-if="item.order_status_id==1">待发货</div>
									<div v-else-if="item.order_status_id==17">待收货</div>
									<div v-else-if="item.order_status_id==4">待发货</div>
									<div v-else-if="item.order_status_id==6">待收货</div>
									<div v-else-if="item.order_status_id==7">已退款</div>
									<div v-else-if="item.order_status_id==55">待收货</div>
									<div v-else-if="item.order_status_id==11">已完成</div>
									<div style="margin-top: 4px;color: #007EEE;cursor: pointer;" @click="to_order(item)">查看详情</div>
								</li>
								<li style="width: 147px;">
									<div v-if="item.order_status_id==5">已取消</div>
									<div v-else-if="item.order_status_id==3">
										<p style="padding-bottom: 10px;width: 100%;font-size: 16px;">{{countDown(item.surplus)}}</p>
										<button style="margin-top: 5px;" @click="click_zhifu(item)">立即支付</button>
										<button style="margin-top: 10px;border-color: black;color: black;" @click="cancel_order(item)">取消订单</button>
									</div>
									<div v-else-if="item.order_status_id==22">待收货</div>
									<div v-else-if="item.order_status_id==23">待收货</div>
									<div v-else-if="item.order_status_id==14">待收货</div>
									<div v-else-if="item.order_status_id==1">待发货</div>
									<div v-else-if="item.order_status_id==17">待收货</div>
									<div v-else-if="item.order_status_id==4">待发货</div>
									<div v-else-if="item.order_status_id==6">待收货</div>
									<div v-else-if="item.order_status_id==7">已退款</div>
									<div v-else-if="item.order_status_id==55">待收货</div>
									<div v-else-if="item.order_status_id==11"><button style="margin-left: 5px;" @click="click_shopping(item)">再次购买</button></div>
								</li>
							</ul>
						</div>
					</div>
					<el-pagination style="text-align: center;margin-bottom: 48px;"
					  @current-change="handleCurrentChange"
					  :current-page.sync="currentPage1"
					  background
					  layout="prev, pager, next"
					  :total="counts"
					  :page-size="5">
					</el-pagination>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../../components/Top.vue"
	import Footer from "../../components/Footer.vue"
	import Grzx from "../../components/Grzx.vue"
	export default {
		name: 'Mydd',
		data() {
			return {
				currentPage1:1, // 当前页
				counts:null, // 页数
				cartList:[],  // 已支付订单
				cartList1:[],  // 未支付订单
				// mid:152,
				addtime:[],
				user_news:'',
				member_id:'',
				keyword:"",
				options: [{
					value: '0',
					label: '全部订单'
				}, {
					value: '3',
					label: '待付款'
				}, {
					value: '4',
					label: '待发货'
				}, {
					value: '55',
					label: '待收货'
				}, {
					value: '11',
					label: '已完成'
				}, {
					value: '5',
					label: '已取消'
				}, {
					value: '7',
					label: '已退款'
				}],
				value: '0',
				options1: [{
					value: '1',
					label: '全部日期'
				}, {
					value: '2',
					label: '三天以内'
				}, {
					value: '3',
					label: '一个月以内'
				}, {
					value: '4',
					label: '三个月以内'
				}],
				value1: '1',
				pay:'',
			};
		},
		created() {
			this.user_news = JSON.parse(localStorage.getItem('user'));
			if(this.user_news==null){
				this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
				setTimeout(res=>{
					this.$router.replace({
						path:'/login',
					})
				},3000)
			}
			this.member_id = this.user_news.member_id
			this.orders()
		},
		components:{
			Grzx,Footer,Top
		},
		methods:{
			handleCurrentChange(val) {
				this.get_order()
			},
			countDown(surplus) {
				this.get_order()
				let d = parseInt(surplus / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(surplus / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(surplus / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(surplus % 60);
				s = s < 10 ? "0" + s : s
				return d + '天' + h + '时' + m + '分' + s + '秒'
			},
			// 再次购买
			click_shopping(item){
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.codes
					}
				})
				window.open(Detail.href,'_blank')
			},
			// 立即付款
			click_zhifu(item){
				let t=this
				this.pay=item.goods[0].payment_code
				if(this.pay=='wxpay'){
					this.$axios.get('/Detail/strpay',{params:{
							order_id:item.order_id,
						}}).then(res=>{
							this.$router.replace({
								path:'/tjwx',
								query:{
									'url':res.data.res.code_url,
									'order_all_id':res.data.data.order_num_alias,
									'total_money':res.data.data.total
								}
							})
					})
				}else if(this.pay=='outline'){
					this.$message.error('线下汇款订单,请在查看详情中,立即支付');
					// this.$router.replace({
					// 	path:'/tjxx',
					// 	query:{
					// 		'order_all_id':item.order_num_alias,
					// 		'total_money':item.goods[0].price
					// 	}
					// })
				}
			},
			// 取消订单
			cancel_order(item){
				this.$confirm('确认要取消此条订单吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get('/Detail/OrderOut',{params:{order_id:item.order_id}}).then(res=>{
						if(res.data.code==0){
							this.$message.success('已成功取消订单');
						}
						location.reload();
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			open(item,index) {
				this.$confirm('确认要删除此条订单吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get('/Detail/del_order',{params:{order_id:item.order_id}}).then(res=>{
						this.cartList.splice(index,1);
						if(res.data.code==0){
							this.$message({
								type: 'success',
								message: '删除成功!',
							});
						}
						location.reload();
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			get_order(){
				this.$axios.get('/Order/order_oneself',{params:{mid:this.member_id,statust:this.value,date:this.value1,keyword:this.keyword,page:this.currentPage1}}).then(res=>{
					// res.data.ordercenter[0].goods[0].addtime 下单时间
					this.cartList1=res.data.ordercenter
					this.counts=res.data.counts
				})
			},
			orders(value){
				this.get_order()
			},
			dates(value){
				this.get_order()
			},
			search(){
				this.get_order()
			},
			// 转换时间戳
			timestampToTime(timestamp) {
				var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
				var D = date.getDate() + ' ';
				var h = date.getHours() + ':';
				var m = date.getMinutes() + ':';
				var s = date.getSeconds();
				return Y+M+D+h+m+s;
			},
			// 跳转订单详情页面
			to_order(item){
				// console.log(item.order_id)
				this.$router.replace({
					path:'/Ddxq',
					query:{
						'order_id':item.order_id
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		.top{
			display: flex;
			p{
				margin-top: 24px;
				margin-bottom: 24px;
				margin-left: 40px;
				font-size: 32px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}
			input{
				width: 386px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 2px solid #EEEEEE;
			}
			div{
				display: flex;
				align-items: center;
				justify-content: center;
				div{
					width: 99px;
					height: 46px;
					border-radius: 2px;
					border: 2px solid #D70A16;
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #D70A16;
					line-height: 46px;
					text-align: center;
				}
			}
			
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			width: 980px;
			height: auto;
			margin: auto;
			padding-bottom: 67px;
			.topxinxi{
				width: 102%;
				height: 54px;
				line-height: 54px;
				border-radius: 4px 4px 0px 0px;
				margin-top:23px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #666666;
				ul{
					display: flex;
					flex-wrap: wrap;
				}
			}
			.cartList{
				width: 994px;
				margin-top: 17px;
				.bg{
					width: 994px;
					height: 54px;
					background: #F4F5F5;
					border-radius: 4px 4px 0px 0px;
					line-height: 54px;
					i{
						margin-left: 670px;
					}
					input{
						margin: 20px;
					}
				}
				ul{
					display: flex;
					li{
						display: flex;
						align-items: center;
						justify-content: center;
						height: 148px;
						border: 1px solid #EEEEEE;
						button{
							margin-top: 15px;
							width: 88px;
							height: 30px;
							border-radius: 2px;
							border: 1px solid #D70A16;
							color: #D70A16;
							margin-left: 30px;
							background-color: #FFFFFF;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>