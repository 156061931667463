<template>
	<div style="">
		<div class="center">
			<div class="top">
				<img src="../assets/image/3q_logo.png" alt="" srcset="">
			</div>
		</div>
		<div class="bg">
			<div class="center">
				<p class="title">数字化工业用品服务平台</p>
				<div class="mtk">
					<p>手机验证码登录</p>
					<div>
						<span><img src="../assets/image/login/sign_icon_iphone.png" alt="" srcset=""></span><input style="padding-left: 16px;" type="text" v-model="phone" placeholder="请输入手机号">
					</div>
					<div class="hq">
						<div>
							<span><img src="../assets/image/login/sign_icon_code.png" alt="" srcset=""></span><input style="width: 190px;padding-left: 16px;" type="text" v-model="code" placeholder="请输入验证码">
						</div>
						<div class="yzm" style="cursor: pointer;">
							<div @click="getCode" v-if="back_time <=0">获取验证码</div>
							<div v-else style="font-size: 12px;">{{back_time}}秒后重新发送</div>
						</div>
					</div>
					<button @click="submit()">立即登录</button>
					<p class="zhuce">还没有账号?<router-link :to="{ path: '/Register' }"><span>立即注册</span></router-link></p>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Login',
		data(){
			return{
				phone:"",
				code:"",
				code1:"",
				// cut:false,
				back_time:0, // 验证码倒计时
				user_news:[]
			}
		},
		components:{
			Footer
		},
		methods:{
			// 提交验证
			submit(){
				let t=this
				if(t.phone==''){
					 this.$message.error('请您输入您的手机号码');
				}else if(t.code==''){
					this.$message.error('请您先获取验证码,并正确输入');
				}else{
					// console.log(t.code1)
					// console.log(t.code)
					this.$axios.get('/Index/val_phone',{params:{phone:t.phone,code:t.code,code1:t.code1}}).then(res=>{
						// console.log(res.data.error_code)
						// console.log(res.data.data.member_id)
						t.user_news=res.data.data
						// console.log(t.user_news)
						window.localStorage.setItem('user',JSON.stringify(t.user_news))
						if(res.data.error_code==1){
							this.$message.error('账号不存在,请注册账号');
							// this.$router.replace({
							// 	path:'/Register',
							// })
						}
						else if(t.code!=t.code1){
							t.cut==true
							this.$message.error('请输入正确的验证码');
							return
						}
						else{
							this.$message.success('登录成功')
							this.$router.replace({
								path:'/',
							})
						}
					}).catch(function (error) {
						console.log(error);
					});
				}
			},
			// 获取验证码
			getCode(){
				let t=this
				if(t.phone==''){
					 this.$message.error('请您输入您的手机号码');
				}else{
					this.$axios.get('/Index/send_sale',{params:{phone:t.phone,type:1}}).then(res=>{
						if(res.data.error_code==1){
							this.$message.error('短信发送失败,请10分钟后重试');
						}else if(res.data.error_code==2){
							this.$message.error('账号不存在,请先注册');
							// return
							this.$router.replace({
								path:'/',
							})
						}else{
							// console.log(res.data)
							t.code1 = res.data.data
							console.log(t.code1)
							t.back_time = 60
							t.startBackTime()
						}
					})
				}
			},
			// 验证码倒计时
			startBackTime(){
				let t = this
				let interval = setInterval(()=>{
					if(t.back_time <=0){
						clearInterval(interval)
						t.back_time = 0
					}else{
						t.back_time--
					}
				},1000)
			},
		}
	}
</script>

<style scoped lang="less">
	.top{
		height: 215px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		div{
			width: 188px;
			height: 43px;
			font-size: 41px;
			font-family: ReeJi-BigRuixain-BlackGBV1.0, ReeJi-BigRuixain;
			font-weight: normal;
			color: #000000;
			line-height: 43px;
			margin-left: 6px;
		}
	}
	.bg{
		width: 100%;
		height: 821px;
		background: url('../assets/register_pic_bg.png') no-repeat;
		background-size: 100%;
		padding: 1px;
		.title{
			height: 120px;
			font-size: 100px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 120px;
			margin-top: 40px;
			text-align: center;
		}
		.mtk{
			padding: 1px;
			margin:24px auto;
			width: 486px;
			height: 389px;
			background: #FFFFFF;
			box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 9px 4px 0px rgba(0,0,0,0.05), 0px 12px 48px 16px rgba(0,0,0,0.03);
			p{
				width: 168px;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: 36px;
				margin:40px auto;
			}
			div{
				width: 390px;
				display: flex;
				align-items: center;
				margin-left: 48px;
				margin-top: 24px;
				input{
					width: 342px;
					height: 48px;
					background: #FFFFFF;
					border-radius: 2px;
					border: 1px solid #EEEEEE;
				}
				span{
					width: 48px;
					height: 48px;
					background: #F5F5F5;
					border-radius: 2px;
					text-align: center;
					line-height: 48px;
					img{
						width: 24px;
						height: 24px;
						margin-top: 12px;
					}
				}
				.yzm{
					margin-left: 0px;
					div{
						width: 98px;
						height: 48px;
						background: #FFFFFF;
						border-radius: 2px;
						border: 1px solid #EEEEEE;
						margin-left: 12px;
						margin-top: 0px;
						// margin-right: 48px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #D70A16;
						padding-left: 22px;
					}
				}
			}
			.hq{
				display: flex;
				margin-left: 0px;
				margin-top: 0px;
				align-items: center;
			}
			button{
				width: 390px;
				height: 51px;
				background: #CD2E2F;
				border-radius: 2px;
				border: none;
				margin-left: 48px;
				margin-top: 42px;
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
			}
			.zhuce{
				width: 140px;
				height: 22px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #606060;
				line-height: 22px;
				margin-left: 48px;
				margin-top: 16px;
				span{
					color: #D70A16;
					margin-left: 5px;
				}
			}
		}
	}
</style>