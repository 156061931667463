import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

import axios from 'axios'
Vue.prototype.$axios=axios;

axios.defaults.baseURL = '/api'

import './assets/css/global.css'  // 公共样式
// import './assets/css/media.css'  // 媒体查询

router.beforeEach((to, from, next) => {
     // 让页面回到顶部
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    next()
 })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


