<template>
	<div style="">
		<Top></Top>
		<div class="box">
			<div class="center">
				<Grzx></Grzx>
				<div class="main">
					<div class="top">
						<p>账号信息</p>
					</div>
					<div class="line"></div>
					<div class="cont">
						<p>个人信息</p>
						<div class="message">
							<div style="margin-left: 40px;margin-top: 40px;">
								<span class="span1">注册手机号 :</span><span class="span2">{{telephone}}</span>
							</div>
							<div style="margin-left: 40px;margin-top: 40px;">
								<span class="span1">头像 :</span><span class="span2"><img src="../../assets/image/index/am_icon_upload.png" alt=""></span>
							</div>
							<div style="margin-left: 40px;margin-top: 40px;">
								<span class="span1">昵称 :</span><input type="text" placeholder="请输入昵称"><span style="font-size: 14px;font-weight: 400;color: #007EEE;">保存修改</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../../components/Top.vue"
	import Footer from "../../components/Footer.vue"
	import Grzx from "../../components/Grzx.vue"
	export default {
		name: 'Zhxx',
		data() {
			return {
				user_news:'',
				member_id:'',
				telephone:''
			};
		},
		components:{
			Grzx,Footer,Top
		},
		created() {
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.member_id = this.user_news.member_id
			this.telephone=this.user_news.telephone
			if(this.user_news==null){
				this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
				setTimeout(res=>{
					this.$router.replace({
						path:'/login',
					})
				},3000)
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		.top{
			display: flex;
			p{
				margin-top: 24px;
				margin-bottom: 24px;
				margin-left: 40px;
				font-size: 32px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			width: 980px;
			height: auto;
			margin: auto;
			padding-bottom: 67px;
			p{
				font-size: 24px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #D70A16;
				margin: 40px 0px 24px 40px;
				margin-left: 0px;
			}
		}
	}
	.message{
		width: 994px;
		height: 312px;
		background: #FFFFFF;
		border: 1px solid #EEEEEE;
		.span1{
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
		}
		.span2{
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			margin-left: 16px;
		}
		input{
			width: 240px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 2px;
			border: 1px solid #999999;
			margin-left: 16px;
			padding-left: 16px;
			margin-right: 36px;
		}
	}
</style>