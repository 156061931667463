<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>个人中心 > 用户协议</p>
					<div class="line"></div>
					<div class="cont">
						<br><br>
						<router-link style="margin-top: 48px;margin-bottom: 16px;" :to="{ path: '/Yhxy' }">·《3Q五金城网站服务协议》</router-link><br><br>
						<router-link :to="{ path: '/Ysxy' }">·《3Q五金城网站隐私政策》</router-link>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Yhxy_ysxy',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			margin-left: 40px;
			a{
				color: #257FE6;
			}
		}
	}
</style>