<template>
	<div style="">
		<Top></Top>
		<div class="box">
			<div class="center">
				<Grzx></Grzx>
				<div class="main">
					<div class="top">
						<p>我的优惠券</p>
					</div>
					<div class="line"></div>
					<div class="cont">
						<div class="coupon">
							<div class="quan"  v-if="quanList==''">
								<div  v-for="item in quanList">
									<div class="big">
										<div class="small">
											<div>
												<span style="width: 48px;height: 64px;font-size: 40px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #999999;line-height: 64px;">￥99</span><br>
												<span style="width: 42px;height: 22px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 22px;">新人券</span>
											</div>
											<div class="line" style="height: 80px;border: 1px dashed #E6E6E6;"></div>
											<div>
												<span style="width: 109px;height: 27px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 27px;">满1000减99</span><br>
												<span style="width: 75px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">有效期: 30天</span>
											</div>
										</div>
									</div>
									<span style="width: 30px;height:102px;background-color: #DDDDDD;color: #FFFFFF;text-align: center;padding-top: 12px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 22px;">不可使用</span>
								</div>
								
							</div>
							<div v-else style="color: red;">
								温馨提示:暂无优惠券可用~~
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../../components/Top.vue"
	import Footer from "../../components/Footer.vue"
	import Grzx from "../../components/Grzx.vue"
	export default {
		name: 'Wdyhq',
		data() {
			return {
				user_news:'',
				member_id:'',
				quanList:[],  // 优惠券列表
			};
		},
		components:{
			Grzx,Footer,Top
		},
		created() {
			this.user_news = JSON.parse(localStorage.getItem('user'));
			if(this.user_news==null){
				this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
				setTimeout(res=>{
					this.$router.replace({
						path:'/login',
					})
				},3000)
			}
			this.member_id = this.user_news.member_id
			this.yhq()
		},
		methods:{
			yhq(){
				let t=this
				t.user_news = JSON.parse(localStorage.getItem('user'));
				this.member_id = t.user_news.member_id
				this.$axios.get('/Detail/get_vouchers',{params:{mid:this.member_id}}).then(res=>{
					// console.log(res);
					this.quanList=res.data.data
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		.top{
			display: flex;
			p{
				margin-top: 24px;
				margin-bottom: 24px;
				margin-left: 40px;
				font-size: 32px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			width: 980px;
			height: auto;
			margin: auto;
			padding-bottom: 67px;
			.coupon{
				height: auto;
				border-radius: 4px;
				margin-top: 40px;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				.quan{
					display: flex;
					margin-top: 40px;
					margin-left: 40px;
					.big{
						width: 343px;
						height: 112px;
						background: #FFFFFF;
						border: 1px solid #DDDDDD;
						background-image: url('../../assets/image/jhd/date_coupon_bg_nor.png');
						display: flex;
						justify-content: space-between;
					}
					.small{
						width: 343px;
						height: 112px;
						background: #CCCCCC;
						border-radius: 4px;
						background-image: url('../../assets/image/jhd/date_coupon_bg_sel.png');
						display: flex;
						justify-content: space-around;
						flex-wrap: wrap;
						align-items: center;
						span{
							margin-left: 34px;
							margin-right: 34px;
						}
					}
				}
			}
		}
	}
	
</style>