<template>
	<div style="">
		<div style="background-color: #F4F5F5;height: auto;padding: 1px;">
			<!-- <div class="center" style="padding: 1px;"> -->
				<el-breadcrumb style="margin-top: 20px;margin-bottom: 20px;" separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="main">
					<div class="left">
						<p>帮助中心</p>
						<div class="line"></div>
						<el-menu
						      default-active="1"
						      class="el-menu-vertical-demo"
						      @open="handleOpen"
						      @close="handleClose">
						      <el-submenu index="1">
						        <template slot="title">
						          <i class="el-icon-caret-bottom"></i>
						          <span>个人中心</span>
						        </template>
						        <el-menu-item-group>
						          <router-link :to="{ path: '/Zclc' }"><el-menu-item index="1-1">如何注册</el-menu-item></router-link>
						          <router-link :to="{ path: '/Yhxy_ysxy' }"><el-menu-item index="1-2">用户协议</el-menu-item></router-link>
								  <!-- <router-link :to="{ path: '/Mydd' }"><el-menu-item index="1-3">我的订单</el-menu-item></router-link>
								  <router-link :to="{ path: '/Zhxx' }"><el-menu-item index="1-4">账号信息</el-menu-item></router-link>
								  <router-link :to="{ path: '/Gr_dzgl' }"><el-menu-item index="1-5">地址管理</el-menu-item></router-link>
								  <router-link :to="{ path: '/Wdyhq' }"><el-menu-item index="1-6">我的优惠券</el-menu-item></router-link> -->
						        </el-menu-item-group>
						      </el-submenu>
							  <el-submenu index="2">
							    <template slot="title">
							      <i class="el-icon-caret-bottom"></i>
							      <span>配送政策</span>
							    </template>
							    <el-menu-item-group>
							      <router-link :to="{ path: '/Psfw' }"><el-menu-item index="2-1">配送服务</el-menu-item></router-link>
							    </el-menu-item-group>
							  </el-submenu>
							  <el-submenu index="3">
							    <template slot="title">
							      <i class="el-icon-caret-bottom"></i>
							      <span>常见问题</span>
							    </template>
							    <el-menu-item-group>
							      <router-link :to="{ path: '/Dzgl' }"><el-menu-item index="3-1">地址管理</el-menu-item></router-link>
							    </el-menu-item-group>
							  </el-submenu>
							  <el-submenu index="4">
							    <template slot="title">
							      <i class="el-icon-caret-bottom"></i>
							      <span>关于我们</span>
							    </template>
							    <el-menu-item-group>
							      <router-link :to="{ path: '/Gsjj' }"><el-menu-item index="4-1">公司介绍</el-menu-item></router-link>
								  <router-link :to="{ path: '/Lxwm' }"><el-menu-item index="4-2">联系我们</el-menu-item></router-link>
							    </el-menu-item-group>
							  </el-submenu>
						</el-menu>
					</div>
				</div>
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Bzzx',
		data() {
		      return {
					index:''
		      };
		},
		components:{
			
		},
		methods:{
			handleOpen(key, keyPath) {
				console.log(key);
				this.index=key
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}

		}
	}
</script>

<style lang="less" scoped>
	.main{
		width: 270px;
		margin-bottom: 48px;
		.left{
			width: 270px;
			height: 757px;
			background: #FFFFFF;
			border-radius: 4px;
			padding: 1px;
			p{
				width: 96px;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #000000;
				line-height: 36px;
				margin: 24px;
			}
		}
		.line{
			width: 270px;
			height: 1px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.el-menu{
			border: none;
		}
	}
</style>