<template>
	<div style="">
		<div class="whole">
			<div class="top">
				<div class="center">
					<div class="top-head">
						<div>
							<img style="width: 16px;height: 16px;" src="../../assets/image/index/header_icon_address_default.png" alt="">
							<span style="color: #777777;margin-left: 4px;">
								<div class="block">
								  <el-cascader size="mini"
								    :options="options"
								  	placeholder="天津市"
								    ></el-cascader>
								</div>
							</span>
							<span style="width: 180px;margin-left: 25px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
								<div v-if="user_news==null">
									<router-link :to="{ path: '/' }"><span>登录</span></router-link>
									<span style="margin-left: 10px;margin-right: 10px;">|</span>
									<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
								</div>
								<div style="width: 230px;" v-else>
									Hi~ {{telephone}} 欢迎您
									<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
								</div>
							</span>
						</div>
						<div>
							<img style="width: 13px;height: 13px;" src="../../assets/image/index/header_icon_iphone_default.png" alt="">
							<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
						</div>
					</div>
				</div>
			</div>
			<div class="top1">
				<div class="center">
					<div class="top-main">
						<div class="logo">
							<div class="logo">
								<img src="../../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;" @click="to_index()">
							</div>
							<div class="steps">
								<el-steps :space="320" :active="2" finish-status="success">
								  <el-step title="微信支付"></el-step>
								  <el-step title="完成"></el-step>
								</el-steps>
							</div>
						</div>
						<div style="width:538px;height: 22px;margin:24px auto;font-size: 14px;">
							
						</div>
					</div>
				</div>
			</div>
			<div class="line" style="width: 100%;height: 1px;background: #EEEEEE;"></div>
			<div class="center" style="border-top: 4px solid #D70A16;border: 1px solid #979797;height: 726px;">
				<div style="margin: auto;width: 100px;">
					<img style="margin-top: 56px;" src="../../assets/image/jhd/pay_icon_succ_100.png" alt="">
					<p style="margin-top:24px;width: 96px;height: 32px;font-size: 24px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #000000;line-height: 32px;">支付成功</p>
					<button style="width: 88px;margin-top: 24px;height: 32px;border-radius: 2px;border: 1px solid #D9D9D9;" @click="go_order()">查看订单</button>
				</div>
				
			</div>
		</div>
		
		
		
		<Footer></Footer>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2';
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Tjwx',
		data(){
			return{
				options: [{
					value: 'tainjin',
					label: '天津',
					children: [
						{
							value: '1',
							label: '河东区',
						},{
							value: '2',
							label: '红桥区',
						},{
							value: '3',
							label: '西青区',
						},{
							value: '4',
							label: '津南区',
						},{
							value: '5',
							label: '河西区',
						}
					],
				}],
				url:'',
				user_news:[],  // 用户信息
				telephone:'',
				count: '', //倒计时
				seconds: 432000 ,// 10天的秒数
				total_money:'', // 应支付价格
				order_all_id:'', // 订单号
			}
		},
		components:{
			Footer
		},
		created () {
			let t=this
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.telephone=this.user_news.telephone
			// this.petty=Number(this.num)*Number(this.danjia)
			this.url=this.$router.currentRoute.query.url
			this.order_all_id=this.$router.currentRoute.query.order_all_id
			this.total_money=this.$router.currentRoute.query.total_money
			// console.log(this.url)
		},
		methods:{
			
			qecode(){
				
				let qrcode = new QRCode('qrcode', {
					width: 238, // 二维码宽度，单位像素
					height: 238, // 二维码高度，单位像素
					text:this.url,
				});
				// console.log (qrcode)
			},
			// 退出登录
			loginOut(){
				localStorage.clear();
				this.$router.go()
			},
			to_index(){
				console.log(15165132)
				this.$router.replace({
					path:'/',
				})
				this.$router.go()
			},
			go_order(){
				this.$router.replace({
					path:'/Mydd',
				})
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-input{
		width: 85%;
	}
	/deep/.el-input__suffix{
		display: none;
	}
	/deep/ .el-cascader .el-input .el-input__inner{
		border: none;
		background-color: #F8F8F8;
		width: 70%;
		padding: 0px;
	}
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.top-main{
		height: 200px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.steps{
				width: 960px;
				margin-top: 86px;
			}
		}
	}
	
	.orderNew{
		width: 1280px;
		height: 206px;
		background: #FAF4F4;
		margin: auto;
		text-align: center;
		margin-top: 40px;
		padding: 1px;
		div{
			height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #52C41A;
			line-height: 36px;
			span{
				margin-left: 10px;
			}
		}
		p{
			margin-top: 23px;
		}
	}
	.saoma{
		margin-top: 41px;
		text-align: center;
		div{
			width: 240px;
			height: 240px;
			background: #FFFFFF;
			// box-shadow: 0px 4px 12px 4px rgba(0,0,0,0.08), 0px 2px 4px 0px rgba(0,0,0,0.12), 0px 1px 2px -2px rgba(0,0,0,0.16);
			margin:25px auto;
		}
	}
</style>