<template>
	<div class="noselect" style="">
		<Top></Top>
		<div class="box">
			<div class="center">
				<Grzx></Grzx>
				<div class="main">
					<div class="top">
						<p>订单详情</p>
					</div>
					<div class="line"></div>
					<div class="cont">
						<!-- 订单号 -->
						<div class="orderid">
							<div style="margin-left: 24px;">订单号：{{order_num_alias}}</div>
							<div style="margin-right: 24px;"><span>支付方式：{{pay}}</span>
							<span style="margin-left:24px;">
							订单状态：<span v-if="order_status_id==5">已取消</span>
							<span v-else-if="order_status_id==3">待付款</span>
							<span v-else-if="order_status_id==22">待抢单</span>
							<span v-else-if="order_status_id==23">待取件</span>
							<span v-else-if="order_status_id==14">配送中</span>
							<span v-else-if="order_status_id==1">待入库</span>
							<span v-else-if="order_status_id==4">待发货</span>
							<span v-else-if="order_status_id==17">已入仓</span>
							<span v-else-if="order_status_id==6">待出库</span>
							<span v-else-if="order_status_id==7">已退款</span>
							<span v-else-if="order_status_id==55">待收货</span>
							<span v-else-if="order_status_id==11">已完成</span>
							</span></div>
						</div>
						<!-- 订单流程 -->
						<div>
							<div class="orderStpes" v-if="order_status_id==11">
								<el-steps :active="4" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="商品发货" description=""></el-step>
								  <el-step title="收货完成" description=""></el-step>
								</el-steps>
							</div>
							<div class="orderStpes" v-else-if="order_status_id==4">
								<el-steps :active="2" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="商品发货" description=""></el-step>
								  <el-step title="收货完成" description=""></el-step>
								</el-steps>
							</div>
							<div class="orderStpes" v-else-if="order_status_id==3">
								<el-steps :active="1" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="商品发货" description=""></el-step>
								  <el-step title="收货完成" description=""></el-step>
								</el-steps>
							</div>
							<div class="orderStpes" v-else-if="order_status_id==5">
								<el-steps :active="1" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="商品发货" description=""></el-step>
								  <el-step title="收货完成" description=""></el-step>
								</el-steps>
							</div>
							<div class="orderStpes" v-else-if="order_status_id==55">
								<el-steps :active="3" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="商品发货" description=""></el-step>
								  <el-step title="收货完成" description=""></el-step>
								</el-steps>
							</div>
							<div class="orderStpes" v-else-if="order_status_id==7">
								<el-steps :active="3" align-center finish-status="success">
								  <el-step title="提交订单" description=""></el-step>
								  <el-step title="订单支付" description=""></el-step>
								  <el-step title="已退款" description=""></el-step>
								</el-steps>
							</div>
						</div>
						<!-- 已取消 已完成 未支付 -->
						<div class="nodes">
							<p v-if="order_status_id==5">已取消</p>
							<p v-else-if="order_status_id==3">待付款</p>
							<p v-else-if="order_status_id==22">待收货</p>
							<p v-else-if="order_status_id==23">待收货</p>
							<p v-else-if="order_status_id==14">待收货</p>
							<p v-else-if="order_status_id==1">待发货</p>
							<p v-else-if="order_status_id==17">待收货</p>
							<p v-else-if="order_status_id==4">待发货</p>
							<p v-else-if="order_status_id==6">待收货</p>
							<p v-else-if="order_status_id==7">已退款</p>
							<p v-else-if="order_status_id==55">待收货</p>
							<p v-else-if="order_status_id==11">已完成</p>
							
							<span v-if="order_status_id==5" style="margin-left: 40px;font-size: 14px;color: #D70A16;">取消原因：超时支付，系统自动取消/用户取消</span><br>
							<button v-if="order_status_id==11" @click="click_shopping()">再次购买</button>
							
							<!-- <p style="margin-top: -25px;width: 100%;font-size: 16px;" v-if="order_status_id==3">{{count}}</p> -->
							<button style="margin-top: 5px;" v-if="order_status_id==3" @click="click_zhifu()">立即支付</button>
						</div>
						<!-- 收货地址 -->
						<div class="address">
							<p style="margin-top: 40px;">收货人: {{username}}</p>
							<p style="margin-top: 16px;">收货电话: {{userphone}}</p>
							<p style="margin-top: 16px;">收货地址:{{str}} {{useraddress}}</p>
							<div>
								<p>备注信息: 暂无</p>
							</div>
						</div>
						<!-- 订单信息 -->
						<div class="topxinxi">
							<ul>
								<li style="width: 346px;margin-left: 24px;">商品信息</li>
								<li style="width: 117px;">单价（元）</li>
								<li style="width: 137px;">数量*单位</li>
								<li style="width: 130px;">小计</li>
								<li style="width: 117px;margin-left: 24px;">状态</li>
							</ul>
						</div>
						<div class="cartList" v-for="(item,index) in cartList">
							<ul>
								<li style="width: 346px;display: flex;align-items: center;">
									<!-- <img :src="'https://em.3qwujin.com/Uploads/image/'+item.goods_images" style="width: 100px;height: 100px;margin: 20px;" alt=""> -->
									<img :src="item.goods_images" style="width: 100px;height: 100px;margin: 20px;" alt="">
									<div style="padding-right: 29px;">
										<span>{{item.goodsname}} {{item.name}}</span><br><br>
										<span style="color: #666666;">产品编码: {{item.goods_id}}</span>
									</div>
								</li>
								<li style="width: 117px;">{{item.price}}</li>
								<li style="width: 137px;flex-direction: column;">
									{{item.quantity}}*{{item.company}}
								</li>
								<li style="width: 130px;padding-left: 23px;">{{item.total}}</li>
								<li style="width: 117px;flex-direction: column;">
									<div v-if="item.order_status_id==5">已取消</div>
									<div v-else-if="item.order_status_id==3">待付款</div>
									<div v-else-if="item.order_status_id==22">待收货</div>
									<div v-else-if="item.order_status_id==23">待收货</div>
									<div v-else-if="item.order_status_id==14">待收货</div>
									<div v-else-if="item.order_status_id==1">待发货</div>
									<div v-else-if="item.order_status_id==17">待收货</div>
									<div v-else-if="item.order_status_id==4">待发货</div>
									<div v-else-if="item.order_status_id==6">待收货</div>
									<div v-else-if="item.order_status_id==7">已退款</div>
									<div v-else-if="item.order_status_id==55">待收货</div>
									<div v-else-if="item.order_status_id==11">已完成</div>
								</li>
							</ul>
						</div>
						
						<div class="remarks">
							<div style="display: flex;">
							</div>
							<div>
								<p>商品总件数：<span>{{quantity}}</span></p>
								<p style="margin-top: 20px;">商品总金额：<span>{{allmoney().toFixed(2)}}</span></p>
								<!-- <p style="margin-top: 20px;">&nbsp;&nbsp;&nbsp;商品优惠：<span>-¥10</span></p>
								<p style="margin-top: 20px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;运费：<span>¥15</span></p> -->
							</div>
						</div>
						<div class="jiesuan">
							<div>实付款: <span style="font-size: 20px;color: #D70A16;font-weight: 600;">￥{{allmoney().toFixed(2)}}</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../../components/Top.vue"
	import Footer from "../../components/Footer.vue"
	import Grzx from "../../components/Grzx.vue"
	export default {
		name: 'Mydd',
		data() {
			return {
				cartList:[],
				codes:'',
				username:'',
				useraddress:'',
				userphone:'',
				// money:'',
				quantity:'',
				order_num_alias:'',
				order_status_id:'',
				order_id:'',
				order_id1:'',
				str:'',
				user_news:[], // 用户信息
				member_id:'',  // 用户id
				dispatching:'express',
				count: '', //倒计时
				seconds: 432000 ,// 5天的秒数
				Total_time:'', // 下单时间 + 5天
				pay:'', // 支付类型
			};
		},
		components:{
			Grzx,Footer,Top
		},
		created() {
			this.codes=this.$router.currentRoute.query.order_id
			this.order()
			this.Time() //5天倒计时
		},
		methods:{
			// 5天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.Total_time / (24 * 60 * 60 * 1000))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.Total_time / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.Total_time / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.Total_time % 60);
				s = s < 10 ? "0" + s : s
				this.count = d + '天' + h + '时' + m + '分' + s + '秒'
			},
			//定时器没过1秒参数减1
			Time() {
				let set=setInterval(() => {
					this.Total_time -= 1
					this.countDown()
				}, 1000)
			},
			order(){
				this.$axios.get('/Detail/order_details',{params:{order_id:this.codes}}).then(res=>{
					console.log (res.data)
					this.cartList=res.data.lsit
					// this.cartList.forEach(val=>{
					// 	console.log(val)
					// })
					this.Total_time=Number(new Date().getTime()) - (Number(res.data.lsit[0].addtime)*1000)
					this.username=res.data.data.shipping_name
					this.order_status_id=res.data.data.order_status_id
					this.order_id=res.data.lsit[0].goods_id
					this.order_id1=res.data.lsit[0].order_id
					this.userphone=res.data.data.shipping_tel
					this.useraddress=res.data.data.shipping_address
					this.pay=res.data.data.payment_code
					if(this.pay=='wxpay'){
						this.pay='微信支付'
					}else if(this.pay='outline'){
						this.pay='线下转账'
					}
					this.str=res.data.data.str
					this.quantity=res.data.lsit.length
					this.order_num_alias=res.data.data.order_num_alias
				})
			},
			allmoney() {
			    let arr = [...this.cartList];
				// console.log(arr)
			    let goodsnum = 0;
			    for (var i in this.cartList) {
					goodsnum += arr[i].quantity * arr[i].price
			    }
			    return goodsnum
			},
			// 再次购买
			click_shopping(){
				// console.log(this.order_id)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':this.order_id
					}
				})
				window.open(Detail.href,'_blank')
			},
			// 立即付款
			click_zhifu(){
				let t=this
				if(this.pay=='wxpay'){
					this.$axios.get('/Detail/strpay',{params:{
							order_id:t.order_id1, // 用户id
						}}).then(res=>{
							console.log(res)
							this.$router.replace({
								path:'/tjwx',
								query:{
									'url':res.data.res.code_url,
									'order_all_id':res.data.data.order_num_alias,
									'total_money':res.data.data.total
								}
							})
					})
				}else if(this.pay='outline'){
					this.$router.replace({
						path:'/tjxx',
						query:{
							'order_all_id':this.order_num_alias,
							'total_money':this.allmoney().toFixed(2)
						}
					})
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		.top{
			display: flex;
			p{
				margin-top: 24px;
				margin-bottom: 24px;
				margin-left: 40px;
				font-size: 32px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}
			input{
				width: 386px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 2px solid #EEEEEE;
			}
			div{
				display: flex;
				align-items: center;
				justify-content: center;
				div{
					width: 99px;
					height: 46px;
					border-radius: 2px;
					border: 2px solid #D70A16;
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #D70A16;
					line-height: 46px;
					text-align: center;
				}
			}
			
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			margin-bottom: 150px;
			.orderid{
				display: flex;
				justify-content: space-between;
				width: 994px;
				height: 54px;
				line-height: 54px;
				background: #F4F5F5;
				border-radius: 4px 4px 0px 0px;
				margin: auto;
				margin-top: 40px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #666666;
			}
			.orderStpes{
				width: 994px;
				height: 200px;
				background: #FFFFFF;
				border: 1px solid #EEEEEE;
				margin: auto;
				.el-steps--horizontal{
					margin-top: 34px;
				}
			}
			.nodes{
				width: 994px;
				height: 143px;
				background: #FFFFFF;
				border: 1px solid #EEEEEE;
				margin: auto;
				p{
					width: 60px;
					height: 30px;
					font-size: 20px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #000000;
					line-height: 30px;
					margin: 30px 0px 8px 40px;
				}
				button{
					margin-top: 15px;
					width: 88px;
					height: 30px;
					border-radius: 2px;
					border: 1px solid #D70A16;
					color: #D70A16;
					margin-left: 40px;
					background-color: #FFFFFF;
					cursor: pointer;
				}
			}
			.address{
				width: 994px;
				height: 282px;
				background: #FFFFFF;
				border: 1px solid #EEEEEE;
				margin: auto;
				margin-top: 24px;
				p{
					margin-left: 40px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #666666;
				}
				div{
					width: 994px;
					height: 54px;
					line-height: 54px;
					background: #F8F8F8;
					border-radius: 4px 4px 0px 0px;
					margin-top: 93px;
				}
			}
			
			.topxinxi{
				width: 994px;
				height: 54px;
				line-height: 54px;
				background: #F4F5F5;
				border-radius: 4px 4px 0px 0px;
				ul{
					display: flex;
					flex-wrap: wrap;
				}
				margin: auto;
				margin-top: 24px;
			}
			.cartList{
				margin: auto;
				width: 994px;
				.bg{
					width: 994px;
					height: 54px;
					background: #F4F5F5;
					border-radius: 4px 4px 0px 0px;
					line-height: 54px;
					i{
						margin-left: 700px;
					}
					input{
						margin: 20px;
					}
				}
				ul{
					display: flex;
					li{
						display: flex;
						align-items: center;
						justify-content: center;
						height: 148px;
						border: 1px solid #EEEEEE;
					}
				}
			}
			.remarks{
				display: flex;
				margin-top: 40px;
				justify-content: space-between;
				padding-left: 24px;
				padding-right: 45px;
				input{
					width: 633px;
					height: 140px;
					background: #FFFFFF;
					border-radius: 4px;
					border: 1px solid #DDDDDD;
					margin-left: 24px;
					padding-left: 15px;
				}
				span{
					margin-left: 64px;
					color: #D70A16;
				}
			}
			.jiesuan{
				width: 1010px;
				height: 54px;
				line-height: 54px;
				background: #F5F5F5;
				border-radius: 4px;
				margin: auto;
				margin-top: 40px;
				div{
					margin-left: 810px;
				}
			}
		}
	}
</style>