<template>
	<div class="noselect" style="">
		<div class="whole">
			<div class="top">
				<div class="center">
					<div class="top-head">
						<div>
							<img style="width: 16px;height: 16px;" src="../../assets/image/index/header_icon_address_default.png" alt="">
							<span style="color: #777777;margin-left: 4px;">
								<div class="block">
								  <el-cascader size="mini"
								    :options="options"
									placeholder="天津市"
								    ></el-cascader>
								</div>
							</span>
							<span style="width: 180px;margin-left: 25px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
								<div v-if="user_news==null">
									<router-link :to="{ path: '/' }"><span>登录</span></router-link>
									<span style="margin-left: 10px;margin-right: 10px;">|</span>
									<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
								</div>
								<div style="width: 230px;" v-else>
									Hi~ {{telephone}} 欢迎您
									<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
								</div>
							</span>
						</div>
						<div>
							<img style="width: 13px;height: 13px;" src="../../assets/image/index/header_icon_iphone_default.png" alt="">
							<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
						</div>
					</div>
				</div>
			</div>
			<div class="top1">
				<div class="center">
					<div class="top-main">
						<div class="logo">
							<div class="logo">
								<img src="../../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;cursor:pointer;" @click="to_index()">
							</div>
							<div class="steps">
								<el-steps :space="320" :active="1" finish-status="success">
								  <el-step title="进货单"></el-step>
								  <el-step title="提交订单"></el-step>
								  <el-step title="完成"></el-step>
								</el-steps>
							</div>
						</div>
						<div style="width:538px;height: 22px;margin:24px auto;font-size: 14px;">
							
						</div>
						<div class="sort">
							<div class="div1"><span>我的进货单(全部)</span></div>
						</div>
					</div>
				</div>
			</div>
			<!-- 购物车 -->
			<div class="center">
				<div class="gouwuche">
					<table>
						<thead>
							<tr>
								<td style="width: 80px;"><input v-model="isSel" type="checkbox" @click="quanxuan()">全选</td>
								<td style="width: 380px;">产品信息</td>
								<td style="width: 76px;">单位</td>
								<td style="width: 375px;">产品规格</td>
								<td style="width: 94px;">单价(元)</td>
								<td style="width: 170px;">数量</td>
								<td style="width: 125px;">小计(元)</td>
								<td style="width: 85px;">操作</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item,index) in cartList">
								<td style="width: 80px;"><input type="checkbox" v-model="item.isselect">{{danxuan(item)}}</td>
								<td style="width: 380px;display: flex;align-items: center;cursor: pointer;" @click="click_shopping(item)">
									<img :src="item.imgurl" style="width: 100px;height: 100px;margin: 20px;" alt="">
									<div style="padding-right: 29px;">
										<span>{{item.title}}</span><br><br>
										<span style="color: #666666;">产品编码: {{item.id}}</span>
									</div>
								</td>
								<td style="width: 76px;">{{item.company}}</td>
								<td style="width: 375px;padding-right: 26px;">{{item.goodstype}}</td>
								<td style="width: 94px;">{{item.currntprice}}</td>
								<td style="width: 170px;">
									<el-input-number v-model="item.goodsnum" @change="handleChange" size="mini" :min="1" :max="1000" label="描述文字"></el-input-number>
								</td>
								<td style="width: 125px;">
									{{(item.goodsnum*item.currntprice).toFixed(2)}}
								</td>
								<td style="width: 85px;cursor:pointer;" @click="delCart(item,index)">删除</td>
							</tr>
						</tbody>
					</table>
					<div class="jiesuan">
						<div>
							<input type="checkbox" v-model="isSel" @click="quanxuan()">全选
						</div>
						<!-- <div @click="delxuan(index)">删除选中商品</div> -->
						<div style="margin-left: 750px;">已选择<span style="color: #D70A16;padding-left: 5px;padding-right: 5px;font-size: 16px;">{{allshop()}}</span>件商品</div>
						<div>商品总金额: <span style="font-size: 20px;color: #D70A16;font-weight: 600;">￥{{allmoney().toFixed(2)}}</span></div>
						<div><button @click="to_tjdhd">去结算</button></div>
					</div>
				</div>
			</div>
			
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Jhd',
		data(){
			return{
				options: [{
					value: 'tainjin',
					label: '天津',
					children: [
						{
							value: '1',
							label: '河东区',
						},{
							value: '2',
							label: '红桥区',
						},{
							value: '3',
							label: '西青区',
						},{
							value: '4',
							label: '津南区',
						},{
							value: '5',
							label: '河西区',
						}
					]
				}],
				box:1,
				isSel:false,
				isSels:'',
				cartList:[],
				user_news:'',
				user_id:'',
				telephone:'',
				num: 1,
				tableData:[],
				carKey:''
			}
		},
		components:{
			Footer
		},
		created () {
			// this.petty=Number(this.num)*Number(this.danjia)
			this.user_news = JSON.parse(localStorage.getItem('user'));
			if(this.user_news==null){
				this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
				setTimeout(res=>{
					this.$router.replace({
						path:'/login',
					})
				},3000)
			}
			this.user_id = this.user_news.member_id
			this.telephone=this.user_news.telephone
			this.get_order()
		},
		methods:{
			// 退出登录
			loginOut(){
				localStorage.clear();
				this.$router.go()
			},
			to_index(){
				// console.log(15165132)
				this.$router.replace({
					path:'/',
				})
				this.$router.go()
			},
			 handleChange(value) {
				console.log(value)
			},
			// 获取进货单商品
			get_order(){
				this.$axios.get('/Detail/come_truck',{params:{member_id:this.user_id}}).then(res=>{
					// console.log(res.data.carts[1])
					// console.log(res.data.carts[1].shopcarts)
					if(res.data.carts==''){
						this.$message.error('暂时还没有添加商品哦!3秒后为您跳转首页');
						setTimeout(res=>{
							this.$router.replace({
								path:'/',
							})
						},3000)
					}else{
						this.cartList=res.data.carts[1].shopcarts
					}
				})
			},
			// 删除商品
			delCart(item,index){
				this.$confirm('确认要删除此条商品吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get('/Detail/del_car',{params:{member_id:this.user_id,goods_id:item.id}}).then(res=>{
						console.log(res)
						if(res.data.code==0){
							this.cartList.splice(index, 1)
							this.$message.success('商品删除成功!');
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 全选
			quanxuan(){
				for(var i in this.cartList){
					// console.log(this.cartList[i].isselect)
					if(this.isSel==true){
						this.cartList[i].isselect=false
					}else{
						this.cartList[i].isselect=true
					}
				}
			},
			// 单选全部选中  让全选按钮同时选中  否则反之
			danxuan(){
				if(this.cartList.length==this.allshop()){
					this.isSel=true
				}else{
					this.isSel=false
				}
			},
			// 总价
			allmoney() {
				var money=0;
				this.cartList.forEach(item=>{
					if(item.isselect){
						money+=item.goodsnum*item.currntprice;
					}
				})
				return money;
			},
			// 总件数
			allshop() {
			    let goodsnum = 0;
			    this.cartList.forEach(item=>{
			    	if(item.isselect==true){
			    		goodsnum++
			    	}
			    })
			    return goodsnum
			},
			// 删除选中
			delxuan(index){
				for (var i in this.cartList) {
				    if (this.cartList[i].isSel==true) {
						console.log(this.cartList[i].isSel)
				        this.cartList.splice(index, 1)
				    }
				}
			},
			// 去结算
			to_tjdhd(){
				for(var i in this.cartList){
					// console.log(this.cartList[i].isselect)
					if(this.cartList[i].isselect==true){
						this.carKey+=this.cartList[i].key+','
						console.log(this.carKey)
					}
				}
				if(this.allmoney() ==''){
					this.$message.error('请先选择商品再进行结算');
				}else{
					this.$router.replace({
						path:'/tjdhd',
						query:{
							'key_car':this.carKey
						}
					})
				}
				
			},
			// 点击商品跳转详情页
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-input{
		width: 85%;
	}
	/deep/.el-input__suffix{
		display: none;
	}
	/deep/ .el-cascader .el-input .el-input__inner{
		border: none;
		background-color: #F8F8F8;
		width: 70%;
		padding: 0px;
	}
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.top-main{
		height: 215px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.steps{
				width: 960px;
				margin-top: 86px;
			}
		}
		.sort{
			height: 46px;
			// background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
			border-radius: 2px 2px 0px 0px;
			margin-top: 65px;
			display: flex;
			margin-bottom: 5px;
			.div1{
				width: 270px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px 2px 0px 0px;
				border: 2px solid #DDDDDD;
				border-top: 2px solid #D70A16;
				text-align: center;
				line-height: 46px;
				color: #D70A16;
			}
		}
	}
	// 购物车
	.gouwuche{
		font-size: 14px;
		input{
			margin-right: 12px;
			margin-left: 12px;
		}
		margin-top: 10px;
		margin-bottom: 48px;
		table{
			width: 100%;
			border-collapse: collapse;
			thead{
				tr{
					background: #EEEEEE;
				}
				height: 54px;
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #666666;
				line-height: 54px;
			}
			tbody{
				height: auto;
				tr{
					height: 140px;
					border-bottom: 1px solid #EEEEEE;
				}
			}
			td{
				padding-left: 12px;
			}
			/deep/.el-input__inner{
				width: 118%;
			}
		}
		.jiesuan{
			display: flex;
			justify-content: space-between;
			height: 54px;
			background: #F5F5F5;
			border-radius: 4px;
			line-height: 54px;
			margin-top: 40px;
			button{
				width: 124px;
				height: 54px;
				background: #D70A16;
				border-radius: 4px;
				border: none;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				cursor:pointer;
			}
		}
	}
</style>