<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>常见问题 > 地址管理</p>
					<div class="line"></div>
					<div class="cont">
						<div class="left">
							<div style="height: 400px;margin: 40px;color: #D70A16;">
							  <el-steps direction="vertical" :active="2">
							    <el-step></el-step>
							    <el-step></el-step>
							  </el-steps>
							</div>
						</div>
						<div class="right">
							<p class="p1">第一步</p>
							<p class="p2">在网站首页，找到“个人中心”或者直接点击登录头像跳转到“个人中心”</p>
							<img src="../../assets/image/index/hope_adrss_one.png" alt="">
							<p class="p1">第二步</p>
							<p class="p2">在“个人中心-地址管理”中对收货地址进行管理。</p>
							<img src="../../assets/image/index/hope_adrss_two.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Dzgl',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			display: flex;
			.p1{
				height: 24px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: rgba(0,0,0,0.85);
				line-height: 24px;
			}
			.p2{
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: rgba(0,0,0,0.85);
				line-height: 22px;
			}
		}
	}
</style>