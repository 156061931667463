<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>关于我们 > 联系我们</p>
					<div class="line"></div>
					<div class="cont">
						<div class="left">
							<img src="../../assets/image/index/contactus_pic.png" alt="" srcset="">
						</div>
						<div class="right">
							<div style="margin-top: 40px;">
								<span>公司名称：</span>
								<p>天津大鹏展程科技有限公司</p>
							</div>
							<div>
								<span>公司地址：</span>
								<p>天津市北辰区喜凤花园34号楼</p>
							</div>
							<div>
								<span>负责人：</span>
								<p>王鹏</p>
							</div>
							<div>
								<span>联系电话：</span>
								<p>15900288806</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Lxwm',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: 757px;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			display: flex;
			.left{
				img{
					margin: 40px;
				}
			}
			.right{
				span{
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #666666;
					margin-left: 5px;
				}
				p{
					font-size: 20px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #333333;
					margin-left: 5px;
					margin-top: 4px;
					margin-bottom: 12px;
				}
			}
		}
	}
</style>