<template>
	<div style="">
		<Top></Top>
		<div class="main">
			<div class="center">
				<div class="tit">
					<div class="top">
						<div style="display: flex;align-items: center;">
							<p>限时秒杀</p>
							<span>｜更多好货限时疯抢</span>
						</div>
						<div style="margin-right: 24px;" class="djs">
							<!-- 本场距结束还剩<span>03</span><span>10</span><span>10</span> -->
						</div>
					</div>
				</div>
				<ul>
					<li v-for="item in xsms_List" @click="click_shopping(item)">
						<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 16px 28px 16px;">
							<img src="../../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
							<img style="width: 228px;height: 228px;" :src="item.image" alt="">
						</div>
						<p style="margin-bottom: 8px;height: 42px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.goodsname}}</p>
						<p style="margin-bottom: 8px;font-size: 12px;color: #666666;">产品编码:{{item.codes}}</p>
						<p class="p">￥{{item.protmoney}}</p>
					</li>
				</ul>
				<el-pagination style="text-align: center;margin-bottom: 48px;"
				  @current-change="handleCurrentChange"
				  :current-page.sync="currentPage1"
				  background
				  layout="prev, pager, next"
				  :total="counts"
				  :page-size="20">
				</el-pagination>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../../components/Top.vue';
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Index',
		data(){
			return{
				xsms_List:[],
				currentPage:1, // 当前页
				counts:null, // 总页数
			}
		},
		components: {
			Top,Footer
		},
		created(){
			this.xsms()
		},
		methods:{
			handleCurrentChange(val) {
				console.log(val);
				// console.log(this.currentPage)
				this.xsms()
			},
			xsms(){
				this.$axios.get('/Detail/oppay',{params:{page:this.currentPage}}).then(res=>{
					console.log(res.data.count.count)
					this.xsms_List=res.data.data
					this.counts=Number(res.data.count.count) 
				})
			},
			// 点击商品跳转详情页
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.codes
					}
				})
				window.open(Detail.href,'_blank')
			}
		},
	}
</script>

<style lang="less" scoped>
	.main{
		background-color: #F4F5F5;
		padding: 1px;
		margin-top: 6px;
	}
	.center{
		height: auto;
		padding-bottom: 20px;
	}
	.tit{
		background-image: url('../../assets/huodong/ltsale_pic_bg.png');
		height: 500px;
	}
	.top{
		display: flex;
		margin-top: 40px;
		color: #FFFFFF;
		align-items: center;
		justify-content: space-between;
		p{
			font-size: 36px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			margin: 32px 0px 32px 24px;
		}
		span{
			font-size: 20px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
		.djs{
			span{
				width: 44px;
				height: 44px;
				background: #323436;
				border-radius: 2px;
				margin-left: 12px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				padding: 7px 7px;
			}
		}
	}
	ul{
		height: auto;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: -420px;
		padding-bottom: 40px;
		.el-button--primary{
			width: 32px;
			height: 64px;
			background: #000000;
			opacity: 0.2;
			margin-top: 163px;
		}
		li{
			width: 248px;
			height: 392px;
			background: #FFFFFF;
			margin-top: 16px;
			margin-left: 19px;
			p{
				width: 220px;
				margin-left: 16px;
			}
			.p{
				width: 220px;
				height: 38px;
				line-height: 38px;
				background-image: url('../../assets/image/index/sy_butten_salebuy.png');
				padding-left: 6px;
				color: #D70A16;
				font-size: 20px;
				font-weight: 600;
			}
		}
	}
</style>