<template>
	<div class="noselect" style="">
		<Top></Top>
		<div class="box">
			<div class="center">
				<Grzx></Grzx>
				<div class="main">
					<div class="top">
						<p>地址管理</p>
					</div>
					<div class="line"></div>
					<div class="cont">
						<div class="tit">
							<p>您已创建<span>{{address_number}}</span>个地址,最多可创建<span>100</span>个</p>
							<button @click="dialogFormVisible = true">新增地址</button>
						</div>
						<table>
							<thead>
								<tr>
									<td style="width: 206px;">收货人</td>
									<td style="width: 211px;">电话</td>
									<td style="width: 467px;">收货地址</td>
									<td style="width: 108px;">操作</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,index) in addressList">
									<td style="width: 206px;">{{item.name}}</td>
									<td style="width: 211px;">{{item.telephone}}</td>
									<td style="width: 467px;">{{item.province_name}}--{{item.city_name}}--{{item.country_name}}--{{item.address}} </td>
									<td style="width: 108px;color: #007EEE;cursor: pointer;">
										<p @click="modify_address(item,index)">修改</p>
										<p @click="del_address(item,index)">删除</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<el-dialog title="收货地址" :visible.sync="dialogFormVisible">
					<el-form ref='form' :model="form" label-width='120px'>
						<div style="display: flex;justify-content: space-around;">
							<div><span><span style="color: #D70A16;">* </span>姓名: </span><input v-model='form.name' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
							<div><span><span style="color: #D70A16;">* </span>电话: </span><input v-model='form.telephone' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span><span style="color: #D70A16;">* </span>地址: </span>
								<el-select placeholder="请选择省份" v-model="form.province_id" @change="sheng()" ref='sheng'>
									<el-option v-for="(item,index) in shengList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-select placeholder="请选择城市" @change="shi()" v-model="form.city_id" ref="shi">
									<el-option v-for="(item,index) in shiList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-select placeholder="请选择县/乡/街道" v-model="form.country_id" ref="qu">
									<el-option v-for="(item,index) in quList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span><span style="color: #D70A16;">* </span>详细地址: </span><input v-model="form.address" placeholder="请填写详细地址" type="text" style="width: 650px;height: 70px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span>邮政编码: </span><input placeholder="如果不清楚,请填写000000" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
							<div><span>邮箱地址: </span><input placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;margin-top: 36px;margin-left: 85px;">
							<div><span>是否为默认: </span><input type="checkbox" @click="moren($event)" v-model="form.is_default"><span> 默认收货地址</span></div>
						</div>
					</el-form>  
					<div slot="footer" class='dialog-footer'>
						<el-button @click="dialogFormVisible=false">取消</el-button>
						<el-button type="primary" @click="addAddress()">确定</el-button>
					</div>
				</el-dialog>
				<el-dialog title="修改地址" :visible.sync="dialogFormVisible1">
					<el-form ref='form' :model="obj" label-width='120px'>
						<div style="display: flex;justify-content: space-around;">
							<div><span><span style="color: #D70A16;">* </span>姓名: </span><input v-model='obj.name' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
							<div><span><span style="color: #D70A16;">* </span>电话: </span><input v-model='obj.telephone' placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span><span style="color: #D70A16;">* </span>地址: </span>
								<el-select placeholder="请选择省份" v-model="obj.province_id" @change="sheng1()" ref='sheng'>
									<el-option v-for="(item,index) in shengList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-select placeholder="请选择城市" @change="shi1()" v-model="obj.city_id" ref="shi">
									<el-option v-for="(item,index) in shiList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-select placeholder="请选择县/乡/街道" v-model="obj.country_id" ref="qu">
									<el-option v-for="(item,index) in quList" :key="index" :label="item.name" :value="item.id"></el-option>
								</el-select>
							</div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span><span style="color: #D70A16;">* </span>详细地址: </span><input v-model="obj.address" placeholder="请填写详细地址" type="text" style="width: 650px;height: 70px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;justify-content: space-around;margin-top: 36px;">
							<div><span>邮政编码: </span><input placeholder="如果不清楚,请填写000000" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
							<div><span>邮箱地址: </span><input placeholder="长度不超过20个字符" type="text" style="width: 220px;height: 46px;border-radius: 2px;border: 1px solid #D9D9D9;padding-left: 12px;"></div>
						</div>
						<div style="display: flex;margin-top: 36px;margin-left: 85px;">
							<div><span>是否为默认: </span><input type="checkbox" @click="moren($event)" v-model="obj.is_default"><span> 默认收货地址</span></div>
						</div>
					</el-form>  
					<div slot="footer" class='dialog-footer'>
						<el-button @click="dialogFormVisible1=false">取消</el-button>
						<el-button type="primary" @click="modify()">保存</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from "../../components/Top.vue"
	import Footer from "../../components/Footer.vue"
	import Grzx from "../../components/Grzx.vue"
	export default {
		name: 'Gr_dzgl',
		data() {
			return {
				dialogFormVisible: false,
				dialogFormVisible1: false,
				form:{
					member_id:'',
					name:'',
					telephone:'',
					address:'',
					province_id:'',
					city_id:'',
					country_id:'',
					is_default:'',
					province_name:'',
					city_name:'',
					country_name:''
				},
				obj:{
					member_id:'',
					address_id:'',
					name:'',
					telephone:'',
					address:'',
					province_id:'',
					city_id:'',
					country_id:'',
					is_default:'',
					province_name:'',
					city_name:'',
					country_name:''
				},
				shengList:[],
				shiList:[],
				quList:[],
				user_news:[], // 用户信息
				member_id:'',  // 用户id
				addressList:[],  //地址列表
				address_number:''
			};
		},
		components:{
			Grzx,Footer,Top
		},
		created(){
			this.if_user()
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.member_id = this.user_news.member_id
			this.get_address()
			this.sheng()
		},
		methods:{
			if_user(){
				if(this.user_id==''){
					this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
					setTimeout(res=>{
						this.$router.replace({
							path:'/login',
						})
					},3000)
				}
			},
			handleChange(value) {
				console.log(value)
			},
			// 删除地址
			del_address(item,index){
				this.$confirm('确认要删除此条地址吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios.get('/Detail/del_address',{params:{address_id:item.address_id}}).then(res=>{
						this.addressList.splice(index, 1)
						this.address_number=this.addressList.length
						if(res.data.code==0){
							this.$message.success('地址删除成功!');
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 修改地址
			modify_address(item){
				console.log(item)
				this.dialogFormVisible1=true;//关闭弹窗
				this.obj.name=item.name
				this.obj.telephone=item.telephone
				this.obj.address=item.address
				this.obj.address_id=item.address_id
				this.obj.province_id=item.province_name
				this.obj.city_id=item.city_name
				this.obj.country_id=item.country_name
			},
			modify(){
				this.user_news = JSON.parse(localStorage.getItem('user'));
				this.obj.member_id = this.user_news.member_id
				this.obj.province_name=this.$refs.sheng.$children[0].value
				this.obj.city_name=this.$refs.shi.$children[0].value
				this.obj.country_name=this.$refs.qu.$children[0].value
				this.$axios.get('/Detail/upaddress',{params:this.obj}).then(res=>{
					// console.log(res)
					if(res.data.cide==0){
						this.dialogFormVisible1=false;//关闭弹窗
						this.$message.success('地址修改成功!');
						this.get_address()
					}
				})
			},
			// 获取地址列表
			get_address(){
				let t=this
				t.user_news = JSON.parse(localStorage.getItem('user'));
				this.member_id = t.user_news.member_id
				this.$axios.get('/Detail/get_address',{params:{mid:this.member_id}}).then(res=>{
					// console.log(res);
					this.addressList=res.data.data
					this.address_number=res.data.data.length
				})
			},
			// 获取省
			sheng(){
				this.$axios.get('/Detail/ad_province').then(res=>{
					this.shengList=res.data.data
					// console.log(this.form.province_id)
					this.cid=this.form.province_id;
					
					this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
						this.shiList=res.data.data
						this.aid=this.form.city_id
						
						this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
							this.quList=res.data.data
						})
					})
				})
				this.form.city_id=''
				this.form.country_id=''
			},
			shi(){
				this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
					this.shiList=res.data.data
					this.aid=this.form.city_id
					
					this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
						this.quList=res.data.data
					})
				})
				this.form.country_id=''
			},
			// 获取省
			sheng1(){
				this.$axios.get('/Detail/ad_province').then(res=>{
					this.shengList=res.data.data
					// console.log(this.form.province_id)
					this.cid=this.obj.province_id;
					
					this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
						this.shiList=res.data.data
						this.aid=this.obj.city_id
						
						this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
							this.quList=res.data.data
						})
					})
				})
				this.obj.city_id=''
				this.obj.country_id=''
			},
			shi1(){
				this.$axios.get('/Detail/ad_city',{params:{cid:this.cid}}).then(res=>{
					this.shiList=res.data.data
					this.aid=this.obj.city_id
					
					this.$axios.get('/Detail/ad_area',{params:{aid:this.aid}}).then(res=>{
						this.quList=res.data.data
					})
				})
				this.obj.country_id=''
			},
			// 添加地址
			addAddress(){
				let t=this
				if(this.form.name==''){
					this.$message.error('请填写收货人的昵称');
					return
				}else if(this.form.telephone==''){
					this.$message.error('请填写您的手机号');
				}else if(this.form.address==''){
					this.$message.error('请填写您的收货地址')
				}else{
					var obj=this.form;
					t.user_news = JSON.parse(localStorage.getItem('user'));
					obj.member_id = t.user_news.member_id
					obj.province_name=t.$refs.sheng.$children[0].value
					obj.city_name=t.$refs.shi.$children[0].value
					obj.country_name=t.$refs.qu.$children[0].value
					obj.is_default=0
					// console.log(obj.province_name);
					this.$axios.get('/Detail/adress',{params:obj}).then(res=>{
						// if(res.data.cide==0){
							this.dialogFormVisible=false;//关闭弹窗
							this.get_address();
						// }
					})
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		.top{
			display: flex;
			p{
				margin-top: 24px;
				margin-bottom: 24px;
				margin-left: 40px;
				font-size: 32px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
			}
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			width: 980px;
			height: auto;
			margin: auto;
			padding-bottom: 67px;
			.tit{
				display: flex;
				align-items: center;
				margin-left: 610px;
				margin-top: 40px;
			}
			button{
				width: 104px;
				height: 38px;
				background: #EDEDED;
				border-radius: 2px;
				border: 1px solid #DDDDDD;
				margin-left: 16px;
			}
			span{
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #D70A16;
				line-height: 24px;
			}
		}
	}
	table{
		margin-top: 20px;
		thead{
			height: 54px;
			background: #DDDDDD;
			td{
				padding-left: 24px;
			}
		}
		tbody{
			font-size: 14px;
			tr{
				height: 54px;
				td{
					padding-left: 24px;
					border-bottom: 1px solid #eee;
				}
				span{
					width: 64px;
					height: 26px;
					background: #F67B3E;
					border-radius: 2px;
					padding: 4px 8px;
				}
			}
			
		}
	}
</style>