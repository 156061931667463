<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>个人中心 > 用户协议 > 网站服务协议</p>
					<div class="line"></div>
					<div class="cont">
						<p>3Q五金城网站服务协议</p>
						<span>
							本协议是您(“用户”)与3Q五金城（以下简称为“本网站”）的所有者和运营方3Q五金城（以下简称为“3Q五金城”）之间就本网站的服务相关事宜所订立的契约。<br>
							请您务必仔细阅读本协议全文，当您点击“我已阅读”并“提交注册”后，即表明您已完全了解及明白本协议的全部内容并同意接受全部条款，则本协议即构成对双方有约束力的法律文件。如您不同意本协议各条款的条件，您应当停止注册本网站并停止使用本网站提供的服务。<br>
							根据国家法律法规变化及本网站运营需要，3Q五金城有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在本网站上即生效，并取代此前相关内容，用户应不时关注本网站公告、提示信息及协议、规则等相关内容的变动。用户不同意更新后的内容的，应立即停止使用本网站；用户继续使用本网站的，即视为知悉变动内容并同意接受。<br>
							第1条 用户注册<br>
							1.1 使用本网站服务的自然人、法人或其他组织应当先行完成用户注册手续，成为本网站的正式用户。用户提供的注册资料应当真实、准确、完整、合法、有效，注册资料如发生变动的，用户应当及时更新。<br>
							1.2 本网站服务供依法有权订立具有法律约束力合约的自然人、法人及非法人组织使用。因此，用户应为具备完全民事行为能力的自然人，或依法成立、存续的法人或其他组织。如果用户是18周岁以下的自然人的，则应当在监护人的监护参与下使用本网站。<br>
							1.3 用户应当妥善保管本网站账户名及密码信息，注册账户下产生的一切行为由用户独立承担责任。<br>
							第2条 网站服务<br>
							2.1 用户在完全同意本协议及本网站规定的情况下，方有权使用本网站的相关服务。本网站中所记载的产品(以下称“产品”)及相关销售服务及售后服务(以下合称“服务”)应当按照本协议执行。<br>
							2.2 用户可以通过本网站，按照本协议的规定，根据相关条件(包括且不限于本网站所记载的型号、规格、价格、订购条件、使用上的注意事项等等)购入产品。但是，由于包括且不限于产品的性质、3Q五金城的情况、外加工方、产品的生产方、相关供应商或用户自身延迟确认、延迟付款等等因素，产品或/和本网站以及相关服务中的一部分会在没有事先通知的情况下发生调整或者从本网站中删除。本网站所提供的所有信息仅供参考；尤其提醒用户注意的是，本网站中的价格一般不包含现场服务费，具体请咨询客服予以核实；本网站中的价格均不包含运费及与运输相关的费用，且仅为参考价格，价格不仅会因为受到前述各项因素的影响而有所变动，亦会因为受到包括且不限于用户的所在地、采购数量、付款方式以及运输方式、原材料变动、市场因素、供需关系变化等因素的影响还有所变动，产品或/和服务的价格应以3Q五金城的最终确认为准。<br>
							2.3 本网站相关服务的可提供范围以3Q五金城在本网站中公布的范围以及3Q五金城的其他说明为准，3Q五金城将不提供超出范围的服务，由于包括且不限于产品的性质、3Q五金城的情况、外加工方、产品的生产方、相关供应商或用户自身延迟确认、延迟付款等等因素，相关服务的范围会在没有事先通知的情况下进行调整。<br>
							第3条 用户守则<br>
							3.1 为使用本网站的服务，用户必须：<br>
							（1）自行配备上网设备，包括但不限于电脑、手机或其他上网终端、调制解调器、路由器或其他必备的上网装置；<br>
							（2）自行承担上网或为为获得本网站服务所支出的费用，包括但不限于网络接入、移动网络流量费、电话费用等。<br>
							3.2 用户在本网站发布信息、评论等内容的，不得发布含有以下内容的信息：<br>
							（1）反对宪法所确定的基本原则的；<br>
							（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br>
							（3）损害国家社会公共利益和涉及国家安全的信息资料或言论；<br>
							（4）煽动民族仇恨、民族歧视，破坏民族团结的；<br>
							（5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br>
							（6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br>
							（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br>
							（8）侮辱或者诽谤他人，侵害他人合法权益的；<br>
							（9）含有法律、行政法规禁止的其他内容的。<br>
							3.3 用户使用本网站服务过程中，还应当遵循以下规则：<br>
							（1）遵守中国法律法规；<br>
							（2）不得为任何非法目的而使用网络服务系统；<br>
							（3）遵守所有与本网站网络服务有关的网络协议、规定和程序；<br>
							（4） 不得利用本网站网络服务系统进行任何可能对互联网的正常运转造成不利影响的行为，不得干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；<br>
							（5）不得使用虚假信息或冒用他人信息注册本网站或使用本网站服务；<br>
							（6）不得进行任何未经3Q五金城及本网站许可的商业广告行为；不得对本网站上的任何数据作商业性利用，包括且不限于在未经3Q五金城及本网站事先书面同意的情况下，以复制、发行、传播、出租、展览等等任何方式使用本网站的任何资料；<br>
							（7） 不得使用任何装置、软件或例行程序干预或试图干预本网站的正常运作或正在本网站上进行的任何交易、活动；不得采取任何将导致不合理的庞大数据负载加诸本网站网络设备的行动；<br>
							（8）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；<br>
							（9）用户应不时关注并遵守本网站或3Q五金城不时公布或修改的各类合法规则规定。<br>
							3.4用户不得将在本网站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。<br>
							3.5用户享有检举权，用户在使用本网站服务过程中若发现违法、违规、侵权或其他任何违反本协议的行为，有权通过专门的客服途径向3Q五金城或本网站进行检举，3Q五金城或本网站将通过对检举内容的核实采取相应的措施。用户保证其检举事项的真实性、公正性，同时尊重3Q五金城及本网站对被检举事项的处置措施；被检举用户有权通过专门的客服途径进行辩解，但不影响3Q五金城或本网站先行采取相应措施，3Q五金城或本网站在接纳被检举用户的辩解后，将恢复对其的服务但3Q五金城或本网站不对先行采取相应措施承担任何责任。本网站将对用户检举进行保密。<br>
							3.6用户违反本条本协议，本网站有权视情节的严重采取警告、限期改正、暂停服务、取消合同、禁止交易、封闭账户、永久禁止注册等措施，给第三方、3Q五金城或本网站造成损失的，3Q五金城或本网站有权采取一切合法的诉讼与非诉手段进行索赔；用户行为构成犯罪的，3Q五金城或本网站有权通知和协助国家主管部门进行查处。<br>
							3.7本网站及3Q五金城保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。若用户未遵守以上规定的，本网站或3Q五金城有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。<br>
							3.8企业用户不属于为生活消费进行网购的消费者，不适用“七天无理由退货”规则。个人用户为生活消费需要购买产品的，售后服务按照相关法律法规执行。<br>
							第4条 用户信息<br>
							4.1 用户有权随时通过本网站访问和更正用户信息，对于无法在本网站直接修改的信息，可以联系3Q五金城客服，客服会及时响应。<br>
							4.2 以下情形，用户有权要求删除用户信息：<br>
							（1）本网站处理用户信息的行为违反法律法规；<br>
							（2）本网站处理用户信息的行为违反了与用户的约定；<br>
							（3）用户注销了本网站的账户；<br>
							（4）本网站终止服务及运营。<br>
							4.3 用户可以通过删除信息、关闭设备功能、在本网站或软件中进行设置等方式改变用户授权我们继续收集用户信息的范围或撤回授权；可以通过注销账户的方式，终止我们继续收集您用户信息的全部授权。用户理解，每个业务功能需要一些基本的用户信息才能得以实现，用户撤回同意或授权后，可能无法继续获得所对应的服务，也不再处理您相应的用户信息。<br>
							4.4 用户可以通过联系本网站客服申请注销账户，注销账户应提交书面申请及必要的身份证明文件。账户注销后，本网站将停止为用户提供产品或服务，且除法律法规另有规定外，我们将删除用户的信息。<br>
							4.5 促销信息的接收和退订：<br>
							（1）用户同意注册后接收来自3Q五金城及本网站发出的与产品、优惠活动等等相关的电子邮件、短信等。<br>
							（2）我们会基于用户的信息、在本网站的交易记录等向用户推荐个性化的商品。<br>
							（3）若用户不希望接受来自本网站的邮件、短信的，可以通过本网站客服电话联系3Q五金城客服完成信息退订。为了保障用户账户和信息安全，3Q五金城可能会要求用户提供书面申请和其他书面证明资料，我们将在收到申请后的30天内反馈。<br>
							第5条 订单<br>
							5.1 在您使用本网站下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、收货人、收货地址、联系电话等信息。收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。<br>
							5.2 产品的价格在本网站上列明，这类信息可能随时更改且不发出任何通知。产品的价格包含了增值税，产品送达费用及与送达相关的费用则另外结算，送达费用及与送达相关的费用根据用户选择送货方式的不同而异。由于本网站上产品信息(包括)的数量极其庞大，虽然本网站会尽最大努力维护用户所浏览产品信息的准确性，但由于互联网技术因素等等客观原因存在，本网站网页显示的信息可能会有一定的滞后性或差错，本网站及3Q五金城不保证用户从本网站获得的信息的准确性、正确性、可靠性或者其他，用户对此情形知悉并理解。本网站将尽最大努力保证用户所购产品与网站上公布的价格一致，但直至用户发出订单，本网站才能确认产品的价格。如经本网站核实，产品的在用户发出订单时的实际价格与本网站公布的价格不一致的，本网站或3Q五金城将通知用户并与用户就是否仍旧购买产品进行确认。<br>
							5.3 本网站公布和展示的产品信息为要约邀请；用户下订单时，应当仔细确认希望购买的产品的名称、价格、数量、规格、型号、收货人、收货地址、联系电话等信息，经用户选择、填写、确认后本网站生成的订单信息是用户向本网站发出的要约；本网站收到用户的要约并以回复确认等方式作出承诺的则订单成立并生效。如受到包括但不限于产品的性质、种类、数量、外加工方、产品的生产商、相关供应商或用户自身延迟确认、延迟付款等等因素的影响，有时会限定订购的方式，如必须以书面签章的形式确认订购的具体事项，则履行书面签章形式时合同方为成立。<br>
							5.4 如生效合同中有与本网站或本协议不一致之内容者，除非在合同中以独立的条款特意郑重说明排除适用本网站及本协议内容，否则均以本网站及本协议为准而不论本网站或3Q五金城是否实际签署了合同。<br>
							5.5本网站及本协议所称的订单，其形式不限于订单、协议、采购通知、发货通知单等等，用户通过本网站在线下单或其他数据电文形式确认订单的视为承诺的意思表示，凡任何用户和本网站或3Q五金城就利用本网站进行订购或利用本网站相关服务所达成一致的意思表示均为本协议所称之订单，本协议均可予以适用。<br>
							5.6订单生效之后，本网站与用户均应严格按照合同的规定完全履行订单的全部内容。因用户的要求而产生的与订购有关的相关费用(包括且不限于保险费用、额外的运输费用等等)，本网站或3Q五金城有权要求用户承担。<br>
							5.7 订单生效之后，未经本网站书面同意，用户不得变更或取消合同的。本网站或3Q五金城有权根据订单变更或取消的情况向用户收取相关的变更或取消费用，并要求用户承担因此产生的损失。<br>
							第6条 产品的配送与交付<br>
							6.1 本网站所说的交货期是指货到交货地点的所需时日(下称“到货天数”)，到货天数均自订单已经生效且用户已经满足发货条件的后壹日开始计算。所有本网站上列出的到货天数皆为参考时间，仅供用户参照使用。到货天数因订购产品的种类、数量、订购时间、送货地点不同而有所不同，具体由本网站或3Q五金城与用户在生效订单中予以明确记载。<br>
							6.2 产品到货时，用户应指派专人(如采购、仓管、收货专员等等)负责接收货物，用户在接收货物时应认真核对货物品名、品牌、型号、数量、包装、外观、剩余有效期等等是否无误及完好，用户确认无误及完好后应给予本网站书面签收。货物一经用户书面签收/实际收货，即表示用户认可本网站或3Q五金城交付给用户的货物品名、品牌、型号、数量、包装、外观、剩余有效期等等符合合同规定及用户的要求。如您对产品质量有异议的，应当在合理时间内向3Q五金城提出产品毁损、灭失的风险，在产品交货之后由用户承担。<br>
							6.3 产品的交付应在合同中已经指明的交货地点与用户事先已经指定的人员进行，如本网站对交货地点及/或接收人不能完全确认的，本网站或3Q五金城可以要求接收人提供用户的授权委托文件及相关身份证明材料以便确认身份，如用户及接收人不能提供完备的证明材料的，本网站或3Q五金城有权拒绝交货并对于因此而产生的相关损失及一切后果不承担任何责任。但是，本网站及3Q五金城并没有对交货地点及接收人进行甄别的义务。如用户指定的收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。<br>
							6.4 有关产品的可获性将在本网站上指明。用户应在提交订单前仔细核实上述信息，此后本网站将对用户的订单进行审核并在符合发货条件后安排配送。若用户订购的产品发生缺货，则本网站或3Q五金城将通知用户并与用户就是否仍旧购买产品进行确认。本网站将尽量保证产品信息及可获性的准确，但仍可能因人员操作、系统问题及其他多种原因产生错误，对此情形用户知悉并理解。<br>
							6.5 在收货清点时，由于应归责于本网站的原因，送达了比订单中订购数量少的产品时，本网站将在另行承诺的期限内为用户补足。如本网站送达了比订单中订购数量多的产品时，请用户归还本网站或3Q五金城，相关运费由本网站承担，烦请用户在退还该产品之前根据该产品的性质，妥善地按照管理者的注意义务进行保管。如用户要求购买多送的产品，则仍然应根据本协议的规定执行。<br>
							第7条 产品质量及售后服务<br>
							7.1 产品的型号、规格、技术标准与参数、包装、质量相关事项、售后服务等等均以产品原生产企业或其指定生产企业的企业标准为准。如为定制品的，则以生效订单中的用户与本网站或3Q五金城共同确认的型号、规格、技术标准与参数、包装、质量相关事项、售后服务等等规定为准。<br>
							7.2 用户对到货产品应进行验收，如用户对到货的产品的质量有异议的，应于产品到货后十个工作日内向本网站书面提出，否则视为到货产品经验收为符合合同规定及用户要求的合格品。<br>
							7.3 产品的保修以产品原生产企业或其指定生产企业的保修标准为准(可参见保修卡)。<br>
							a)保修单位地点：产品原生产企业或其指定生产企业的售后维修中心或指定当地特约维修点，用户须自行送修或寄回3Q五金城送修。<br>
							b)免费保修期：以产品原生产企业或其指定生产企业规定的免费保修期为准。<br>
							c)免费保修范围：<br>
							①以产品原生产企业或其指定生产企业规定的免费保修范围为准，因使用不当、违规操作、超负荷运转、人为损害、自行拆装、储藏不良等等人为因素或自然损耗或不可抗力/意外事件造成产品不能正常使用或损坏的，产品被转让、转卖或出借他人的，经不正当或非授权机构维修的，以及消耗品及易损件，均不属于保修的范围；<br>
							②除了所附保修单上有明确规定的免费保修范围，其他情况须送修后根据售后维修中心或指定当地特约维修点的检测结果，确定送修项目是否属于免费保修范围；<br>
							③如不属于免费保修范围则由售后维修中心或指定当地特约维修点提供维修项目、收费清单，再由用户决定是否维修。<br>
							d)本保修条款不适用于耗材类、消耗品类、非工具类产品。<br>
							7.4 除本网站、产品原生产企业或其指定生产企业明确作出的保证外，本网站及3Q五金城均未作出任何默示保证或其他保证。产品通过验收或不存在质量问题的，用户不得以任何理由要求退货或换货或提出其他不合理要求。<br>
							7.5 只有当到货产品因本网站的原因导致与本网站记载的产品不一致时，本网站或3Q五金城才予以退货或换货，本网站及3Q五金城均不对产品的适用性、可销售性、与其他产品的适用性、与其他产品的互换性、销售期及其他作出任何保证。到货产品与本网站记载的产品是否一致，以及不一致的原因是否在本网站或3Q五金城，由本网站或3Q五金城确定。用户要求退/换货产品的产品应由用户负责退回3Q五金城后由3Q五金城检验无误并确认予以退/换后，由3Q五金城办理相关手续；如经3Q五金城检验不应予以退/换货的，由用户自费取回，3Q五金城不承担任何责任。<br>
							7.6 以下情况不适用于3Q五金城的保证，用户不能要求退货、换货或保修：<br>
							（1）特别订购产品、3Q五金城为用户特别提供的外购品(指3Q五金城从用户指定供应商处购买的产品)、易耗品、一次性卫生用品、产品技术性能决定无法更换的产品；<br>
							（2）用户错误地选择、使用不当、违规操作、超负荷运转、人为损害、自行拆装、储藏不良等等人为因素或自然损耗或意外事件/不可抗力事件导致损毁、灭失、失效或变质的产品；<br>
							（3）被转让、转卖或出借他人的，或者经不正当或非授权机构维修的产品，以及消耗品和易损件；<br>
							（5）销售时在有效期内的已过期的产品；<br>
							（6）到货之日起超过7日的产品；<br>
							（7）用户因个体偏好(如用户认为产品的颜色应该更深或更浅等等)或用户其他情况引起的要求维修或退、换货；<br>
							（8）非3Q五金城供货的产品；<br>
							（9）用户指定运输公司造成货损的或用户发货3Q五金城的过程中造成损坏的产品；<br>
							（10）3Q五金城认为不能维修或退、换货的其他情况。<br>
							7.7 除非3Q五金城另有明确的书面说明，本网站及3Q五金城提供给用户的全部信息、内容、材料、产品(包括软件)和服务，是由本网站及3Q五金城在"按照现状"和"按照可提供"的基础上提供的。本网站及3Q五金城不对网站的运营及网站上的信息、内容、材料、产品(包括软件)或服务作任何形式的、明示或默示的声明或担保。<br>
							7.8 关于产品、相关服务产生的直接的、间接的、最终的、附带的、特别的、额外的、惩戒性或惩罚性的任何损失、损害、不良后果或影响，除本协议及相关适用法律的强制性规定外，本网站或3Q五金城不承担任何责任。但不论在任何情况下，本网站及3Q五金城的责任限于并不超过用户购入造成损失/损害/不良后果/影响的产品的价款(3Q五金城的出售价格)。用户违反本规则的行为将使用户丧失要求任何补偿、赔偿的权利并应赔偿3Q五金城因此而遭受的损失及承担的费用。本条款无论在何时或在何种情况下都始终有效并且相对于任何合同/协议/书面文件中的任何条款与内容都具有最高的优先适用效力。<br>
							第8条 促销政策<br>
							8.1 为推广之目的，本网站有权不时以网站积分、优惠券、促销活动等方式提升用户体验，具体实施方案及规则以本网站公布并不时更新的规则为准。<br>
							8.2用户了解并承诺因本网站优惠政策获取的抵用券、积分、折扣、实物等仅限用于用户自身，不得赠与、转让或代支付；企业用户应当将所获优惠如实入账，不得将优惠用于任何个人；企业用户应当妥善管理账户，账户管理人代表企业用户进行操作，企业账号下发生的所有行为由企业用户承担相应的法律责任。
							第9条 知识产权<br>
							9.1 用户通过本网站所知悉的任何信息，应根据法律法规的要求予以保密，未得到专利、实用新型、外观设计、著作权、知识产权所有人的许可之前，不应在法律认可的范围外使用。<br>
							9.2 用户违反法律法规及本协议的，用户须对本网站、3Q五金城、第三方承担侵权行为带来的一切赔偿及法律责任。<br>
							9.3 本网站含许多3Q五金城、本网站以及第三方的商标，所有商标都是各公司的财产。本网站包含的所有知识产权包括且不限于著作权，商标权，商业秘密权以及专利权等等各项知识产权。用户访问本网站但无权复制或使用3Q五金城、本网站或其他供应商的任何知识产权。<br>
							9.4 本网站上全部内容，包括且不限于文字、图片、图表、照片、软件、声音、图像，以及广告中的全部内容、本网站以通告形式发出的全部内容、各类订单信息、宣传信息、促销信息、商业信息以及3Q五金城合作伙伴信息等等各类信息。所有这些内容均受著作权、商标权、物权及其他知识产权和所有权法律的保护。用户只能在本网站或3Q五金城或权利人的授权下才能使用这些内容，而不能擅自对这些内容进行复制、编辑、改编、传播等等，或对内容进行任何形式的权利申请，或创造与内容有关的派生产品等等，不能对这些内容执行任何知识产权权能。同时，这些内容不可以被用于任何可能引起第三方对本网站或3Q五金城的服务产生混淆或者贬低的商品或者服务上。<br>
							9.5 未经本网站或3Q五金城书面同意，用户不得用任何方式对本网站内容进行修改，也不得复制、下载本网站网页内容、数据、信息进行转售或商业利用。用户被授予有限的、可撤销的和非独家的权利建立连接到本网站或3Q五金城主页的超链接，只要这个链接不以虚假、误导、贬低或其他方式侵害本网站或3Q五金城的商品和服务。<br>
							9.6 3Q五金城是本网站的制作者，拥有本网站内容及资源的著作权等合法权利，受国家法律保护，3Q五金城有权不时地对本协议及本网站的内容进行修改，并在本网站张贴，无须另行通知用户。在法律允许的最大限度范围内，3Q五金城对本协议及本网站的任何内容拥有解释权。
							第10条 争议管辖<br>
							本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议或本协议项下订单发生任何争议，双方应尽力友好协商解决；协商不成时，应向协议签订地天津市滨海新区人民法院提起诉讼。<br>
							第11条 附则<br>
							11.1 本协议各条款的标题仅为方便而设，不影响标题下属条款的理解与解释。所有交易适用中华人民共和国的法律。<br>
							11.2 本协议的解除、终止、被撤销或者本协议全部或部分条款的无效均不影响本合同关于合同的解释、违约及赔偿责任、保密责任、法律适用、权利保留及争议解决等相关约定的效力。本协议的部分条款如应法律的原因而无效的，并不影响其他条款的效力，其他条款继续有效。<br>
							11.3 任何一方未主张本协议中的一项权利或者未坚持一项条款或继续履行合同的行为，不构成放弃任何权利或容许任何一方对任何条款或条件的违反，所有放弃应书面做出。<br>
							11.4 除本网站及3Q五金城另行以独立的书面形式做出同意的意思表示外，对本网站、本协议的任何条款的任何修改或删除以及与本网站、本协议不一致的内容均对本网站或3Q五金城不具有任何约束力，以本协议为准。<br>
							11.5 用户自本网站或3Q五金城购买的任何产品及相关服务即使未列在本网站或本协议中本协议同样适用。另外，关于相关服务的具体情况，本网站或3Q五金城可以另行制定相关规定，用户亦应遵照相关规定利用。<br>
							11.6 用户利用本网站进行订购或使用相关服务的行为，均视为用户同意本协议，将本协议作为合同的一部分予以适用，用户愿意遵守本协议的各项条款。<br>
							11.7 本协议自用户注册成为本网站用户时起对用户产生法律约束力，且持续有效。<br>
						</span>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Yhxy',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			height: 659px;
			overflow: auto;
			padding-left: 48px;
			padding-right: 48px;
			span{
				text-indent:2em;
			}
			p{
				text-align: center;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 36px;
			}
		}
	}
</style>