<template>
	<div style="overflow: hidden;padding: 1px;">
		<Top></Top>
		<div class="line" style="margin-top: 5px;"></div>
		<div class="center">
			<div class="dp">
				<div class="left">
					<img style="width: 136px;height: 136px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
				</div>
				<div class="right">
					<p>STANLEY五金店</p>
					<span>店铺号：539043</span>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<!-- 分类 -->
		<div class="center">
			<div class="sort">
				<span>分类 : </span>
				<ul>
					<li>防尘/防化/隔热头罩</li>
					<li>防护面屏</li>
					<li>安全帽配件</li>
					<li>长管呼吸器及配件</li>
				</ul>
			</div>
		</div>
		<!-- 商品 -->
		<div class="goods">
			<div class="center">
				<ul>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
					<li>
						<img style="width: 228px;height: 228px;margin: 16px 16px 28px 16px;" src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">SATA/世达/公制全抛光两用扳手单手操作</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：4v2487B</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥24.50</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;margin-left: 90px">已售2980</span>
						</div>
					</li>
				</ul>
				<el-pagination style="text-align: center;margin-top: 32px;margin-bottom: 48px;"
				  background
				  layout="prev, pager, next"
				  :total="100">
				</el-pagination>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../components/Top.vue'
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Dpxq',
		data(){
			return{
				
			}
		},
		components:{
			Footer,Top
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="less">
	.line{
		width: 100%;
		height: 1px;
		background: #EEEEEE;
	}
	.dp{
		display: flex;
		align-items: center;
		height:200px;
		.left{
			width: 136px;
			height: 136px;
			margin-left: 20px;
		}
		.right{
			p{
				width: 183px;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: 36px;
			}
			span{
				width: 122px;
				height: 24px;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
				line-height: 24px;
				margin-top: 8px;
			}
		}
	}
	.sort{
		width: 100%;
		height: 54px;
		background: #FFFFFF;
		line-height: 54px;
		display: flex;
		align-items: center;
		span{
			width: 42px;
			height: 22px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;
		}
		ul{
			display: flex;
			li{
				
				margin-left: 32px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
			}
		}
	}
	.goods{
		width: 100%;background-color: #F4F5F5;
		padding: 1px;
		ul{
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li{
				width: 260px;
				height: 400px;
				background: #FFFFFF;
				border-radius: 4px;
				margin-bottom: 16px;
			}
		}
	}
</style>