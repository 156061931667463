<template>
	<div style="">
		<div class="center">
			<div class="top">
				<img src="../assets/image/3q_logo.png" alt="">
			</div>
		</div>
		<div class="bg">
			<div class="center">
				<p class="title">数字化工业用品服务平台</p>
				<div class="mtk">
					<p>手机快速注册</p>
					<div>
						<span><b style="color: #D70A16;">*</b> 手机号码</span><input v-model="phone" type="text" placeholder="请输入手机号">
						<span v-if="false" style="position: absolute;margin-top: 70px;margin-left: 90px;color: #D70A16;font-size: 12px;">请输入正确的手机号</span>
					</div>
					<div class="hq">
						<div>
							<span style="width: 62px;margin-left:15px;"><b style="color: #D70A16;">*</b> 验证码</span></i><input style="width: 145px;" type="text" placeholder="请输入验证码" v-model="code">
							<span v-if="cut" style="position: absolute;margin-top: 70px;margin-left: 90px;color: #D70A16;font-size: 12px;">验证码错误</span>
						</div>
						<div class="yzm" style="cursor: pointer;">
							<div @click="getCode" v-if="back_time <=0">获取验证码</div>
							<div v-else style="font-size: 12px;">{{back_time}}秒后重新发送</div>
						</div>
					</div>
					<!-- <el-checkbox :checked="checked" style="margin-top: 24px;margin-left: 49px;font-size: 14px;"> -->
						<input type="checkbox" v-model='aaa' style="margin-top: 24px;margin-left: 49px;">
						同意3Q五金城
						<router-link :to="{ path: '/ysxy' }" style="color: blue;">《隐私协议》</router-link>&
						<router-link :to="{ path: '/yhxy' }" style="color: blue;">《网站服务协议》</router-link>
					<!-- </el-checkbox> -->
					<button @click="submit()">立即注册</button>
					<p class="zhuce">已有账号?<router-link :to="{ path: '/login' }"><span>立即登录</span></router-link></p>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../components/Footer.vue'
	export default {
		name: 'Register',
		data(){
			return{
				phone:"",
				code:"",
				code1:"",
				back_time:0, // 验证码倒计时
				cut:false,
				user_news:[],
				aaa:true
			}
		},
		components:{
			Footer
		},
		methods:{
			// 提交验证
			submit(){
				let t=this
				if(t.phone==''){
					 this.$message.error('请您输入您的手机号码');
				}else if(t.code==''){
					this.$message.error('请您先获取验证码,并正确输入');
				}else if(this.aaa==false){
					this.$message.error('请勾选同意本网站服务协议和用户隐私协议');
				}else{
					this.$axios.get('/Index/phone_register',{params:{phone:t.phone,code:t.code,code1:t.code1}}).then(res=>{
						console.log(res)
						t.user_news=res.data.data
						window.localStorage.setItem('user',JSON.stringify(t.user_news))
						if(t.code!=t.code1){
							t.cut==true
							this.$message.error('请输入正确的验证码');
							return
						}else{
							this.$message('注册成功,请在登录页进行操作')
							this.$router.replace({
								path:'/Login',
							})
						}
					}).catch(function (error) {
						console.log(error);
					});
				}
				// 判断验证码是否正确
				if(t.code!=t.code1){
					t.cut==true
					return
				}
			},
			// 获取验证码
			getCode(){
				let t=this
				if(t.phone==''){
					 this.$message.error('请您输入您的手机号码');
				}else if(this.aaa==false){
					this.$message.error('请勾选同意本网站服务协议和用户隐私协议');
				}else{
					this.$axios.get('/Index/send_sale',{params:{phone:t.phone,type:2}}).then(res=>{
						if(res.data.error_code==1){
							this.$message.error('短信发送失败,请10分钟后重试');
						}else if(res.data.error_code==2){
							this.$message.error('账号已存在,请勿重复注册账号');
							return
						}else{
							console.log(res)
							t.code1 = res.data.data
							t.back_time = 60
							t.startBackTime()
							t.checked=true
						}
					})
				}
			},
			// 验证码倒计时
			startBackTime(){
				let t = this
				let interval = setInterval(()=>{
					if(t.back_time <=0){
						clearInterval(interval)
						t.back_time = 0
					}else{
						t.back_time--
					}
				},1000)
			},
		}
	}
</script>

<style scoped lang="less">
	.top{
		height: 215px;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		div{
			width: 188px;
			height: 43px;
			font-size: 41px;
			font-family: ReeJi-BigRuixain-BlackGBV1.0, ReeJi-BigRuixain;
			font-weight: normal;
			color: #000000;
			line-height: 43px;
			margin-left: 6px;
		}
	}
	.bg{
		width: 100%;
		height: 821px;
		background: url('../assets/register_pic_bg.png') no-repeat;
		background-size: 100%;
		padding: 1px;
		.title{
			height: 120px;
			font-size: 100px;
			font-family: YouSheBiaoTiHei;
			color: #FFFFFF;
			line-height: 120px;
			margin-top: 40px;
			text-align: center;
		}
		.mtk{
			padding: 1px;
			margin:24px auto;
			width: 486px;
			height: 427px;
			background: #FFFFFF;
			box-shadow: 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 9px 4px 0px rgba(0,0,0,0.05), 0px 12px 48px 16px rgba(0,0,0,0.03);
			p{
				width: 168px;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #333333;
				line-height: 36px;
				margin:40px auto;
			}
			div{
				width: 390px;
				display: flex;
				align-items: center;
				margin-left: 48px;
				margin-top: 24px;
				input{
					width: 280px;
					height: 48px;
					background: #FFFFFF;
					border-radius: 2px;
					border: 1px solid #EEEEEE;
					padding-left: 16px;
				}
				span{
					margin-right: 12px;
					border-radius: 2px;
					text-align: center;
					line-height: 48px;
				}
				.yzm{
					margin-left: 0px;
					cursor:pointer;
					div{
						width: 98px;
						height: 48px;
						background: #FFFFFF;
						border-radius: 2px;
						border: 1px solid #EEEEEE;
						margin-left: 12px;
						margin-top: 0px;
						// margin-right: 48px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #D70A16;
						padding-left: 22px;
					}
				}
			}
			.hq{
				display: flex;
				margin-left: 0px;
				margin-top: 0px;
				align-items: center;
			}
			button{
				width: 390px;
				height: 51px;
				background: #CD2E2F;
				border-radius: 2px;
				border: none;
				margin-left: 48px;
				margin-top: 24px;
				font-size: 18px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				cursor:pointer;
			}
			.zhuce{
				width: 140px;
				height: 22px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #606060;
				line-height: 22px;
				margin-left: 48px;
				margin-top: 16px;
				span{
					color: #D70A16;
					margin-left: 5px;
				}
			}
		}
	}
</style>