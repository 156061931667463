<template>
	<div style="">
		<Top1></Top1>
		<div class="main">
			<div class="center">
				<!-- 顶部 -->
				<img class="topimg" src="../assets/image/index/coupon_banner.png" alt="">
				<!-- 内容 -->
				<div class="coupon" style="margin-bottom: 48px;">
					<!-- <div class="quan">
						<div class="big">
							<div class="small">
								<div>
									<span style="width: 48px;height: 64px;font-size: 40px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #999999;line-height: 64px;">￥99</span><br>
									<span style="width: 42px;height: 22px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 22px;">新人券</span>
								</div>
								<div class="line" style="height: 80px;border: 1px dashed #E6E6E6;"></div>
								<div>
									<span style="width: 109px;height: 27px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 27px;">满1000减99</span><br>
									<span style="width: 75px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">有效期: 30天</span>
									<el-progress :percentage="100" style="text-align: center;margin-top: 14px;" :color="colors"></el-progress>
								</div>
							</div>
						</div>
						<span style="width: 30px;height:102px;background-color: #DDDDDD;color: #FFFFFF;text-align: center;padding-top: 12px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 30px;">已领完</span>
					</div> -->
					
					<div class="quan" v-for="item in yhq_lsit" @click="get_conpon(item)">
						<div class="big">
							<div class="small">
								<div style="width: 116px;">
									<span>
										<span style="width: 10px;height: 24px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 24px;margin-left: 0px;margin-right: 0px;">￥</span>
										<span style="width: 48px;height: 64px;font-size: 27px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 64px;">{{item.credit}}</span><br>
									</span>
									<span style="width: 42px;height: 22px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #000000;line-height: 22px;margin-left: 34px;">{{item.catename}}</span>
								</div>
								<div class="line" style="height: 80px;border: 1px dashed #E6E6E6;"></div>
								<div>
									<span style="width: 109px;height: 27px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #000000;line-height: 27px;">满{{item.credit}}减{{item.limit_money}}</span><br>
									<span style="width: 75px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">有效期: {{item.get_over_hour}}天</span>
								</div>
							</div>
						</div>
						<span style="width: 30px;height:102px;background-color: #F67B3E;color: #FFFFFF;text-align: center;padding-top: 12px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FFFFFF;line-height: 22px;">立即领取</span>
					</div>
					
				</div>
				<!-- <el-pagination style="text-align: center;margin-top: 32px;margin-bottom: 48px;"
				  background
				  layout="prev, pager, next"
				  :total="100">
				</el-pagination> -->
			</div>
		</div>
		
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../components/Top1.vue"
	import Footer from "../components/Footer.vue"
	export default {
		name: 'Yhq',
		data() {
		      return {
				  // value: 80,
				  colors: '#D73D46',
				  yhq_lsit:'',
				  user_news:'',
				  member_id:'',
		      };
		},
		components:{
			Footer,Top1
		},
		created(){
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.member_id = this.user_news.member_id
			this.yhq()
		},
		methods:{
			yhq(){
				this.$axios.get('/Home/cluop').then(res=>{
					// console.log(res.data.data)
					this.yhq_lsit=res.data.data
					
				})
			},
			// 领取优惠券
			get_conpon(item){
				console.log(item.id)
				this.$axios.get('/Category/getQuan',{params:{member_id:this.member_id,id:item.id}}).then(res=>{
					console.log(res)
					if(res.data.code==0){
						this.$message.error('优惠券被抢光了')
					}else{
						this.$message.success('领取成功')
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.main{
		background-color: #F4F5F5;
		padding: 1px;
	}
	.topimg{
		margin-top: 21px;
	}
	
	.coupon{
		width: 1360px;
		height: auto;
		background: #F5F5F5;
		border-radius: 4px;
		margin-top: 40px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		.quan{
			display: flex;
			margin-top: 40px;
			margin-right: 105px;
			cursor: pointer;
			.big{
				width: 343px;
				height: 112px;
				background: #FFFFFF;
				border: 1px solid #DDDDDD;
				background-image: url('../assets/image/jhd/date_coupon_bg_nor.png');
				display: flex;
				justify-content: space-between;
			}
			.small{
				width: 343px;
				height: 112px;
				background: #CCCCCC;
				border-radius: 4px;
				background-image: url('../assets/image/jhd/date_coupon_bg_sel.png');
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				align-items: center;
				span{
					// margin-left: 34px;
					// margin-right: 34px;
				}
			}
		}
	}
</style>