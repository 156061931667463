<template>
	<div class="whole">
		<div class="top">
			<div class="center">
				<div class="top-head">
					<div>
						<img style="width: 16px;height: 16px;" src="../assets/image/index/header_icon_address_default.png" alt="">
						<span style="color: #777777;margin-left: 4px;">天津市 河东区</span>
						<span style="width: 220px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
							<div v-if="user_news==null">
								<router-link :to="{ path: '/' }"><span>登录</span></router-link>
								<span style="margin-left: 10px;margin-right: 10px;">|</span>
								<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
							</div>
							<div v-else>
								Hi~ {{telephone}} 欢迎您
								<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
							</div>
						</span>
					</div>
					<div>
						<img style="width: 13px;height: 13px;" src="../assets/image/index/header_icon_iphone_default.png" alt="">
						<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
					</div>
				</div>
			</div>
		</div>
		<div class="top1">
			<div class="center">
				<div class="top-main">
					<div class="logo">
						<div class="logo">
							<img src="../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;cursor: pointer;" @click="to_index()">
						</div>
						<div class="search">
							<input type="text" placeholder="请输入产品名称或产品编码" v-model="name" ref="name">
							<div @click="search()"><span>搜索</span></div>
						</div>
						<div class="jinhuo">
							<img style="width: 21px;height: 20px;color: #333333;" src="../assets/image/index/header_icon_shopcart_default.png" alt="">
							<span class="text1" @click="to_jhd()">我的进货单</span>
							<!-- <span class="text2">5</span> -->
						</div>
					</div>
					<div style="width:538px;height: 22px;margin:24px auto;font-size: 14px;">
						热门搜索：好货1元抢先试   尺子  口罩  手套  劳保鞋  工具  压力表  轴承  安全帽  扳手
					</div>
					<!-- <div class="sort">
						<div class="div1"><img style="width: 19px;height: 17px;" src="../assets/image/index/header_icon_pm_default.png" alt=""><span>产品分类</span></div>
						<router-link  :to="{ path: '/Index' }"><div class="div2">首页</div></router-link>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'Top',
	  data(){
		return{
			user_news:[],  // 用户信息
			telephone:'',
			name:''
		}
	  },
	  created() {
	  	this.user_news = JSON.parse(localStorage.getItem('user'));
	  	this.telephone=this.user_news.telephone
	  	// console.log(this.user_news.username,16515616684168)
	  },
	  methods:{
	  	// 搜索商品
	  	search(){
	  		let input=this.$refs.name.value
	  		console.log(input)
	  		this.$router.replace({
	  			path:'/Search',
	  			query:{
	  				'name':input
	  			}
	  		})
	  	},
	  	to_jhd(){
	  		this.$router.replace({
	  			path:'/jhd',
	  		})
	  	},
		to_index(){
			console.log(15165132)
			this.$router.replace({
				path:'/',
			})
			this.$router.go()
		},
		// 退出登录
		loginOut(){
			localStorage.clear();
			this.$router.go()
		}
	  }
	}
</script>

<style scoped lang="less">
	.el-input{
		width: 95%;
	}
	.el-input__suffix{
		display: none;
	}
	.el-input__inner{
		border: none;
		background-color: #F8F8F8;
	}
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
			
		}
	}
	.top-main{
		height: 215px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.search{
				width: 732px;
				height: 46px;
				margin-top: 49px;
				display: flex;
				margin-left: 92px;
				input{
					width: 633px;
					height: 46px;
					border: none;
					background: #FFFFFF;
					border-radius: 2px;
					border: 2px solid #D70A16;
					padding-left: 16px;
				}
				div{
					width: 99px;
					height:49px;
					line-height: 49px;
					background-color: #D70A16;
					color: white;
					span{
						width: 47px;
						height: 30px;
						font-size: 20px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 30px;
						margin-left: 28px;
						margin-top: 8px;
					}
				}
			}
			.jinhuo{
				width: 180px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #D70A16;
				display: flex;
				// justify-content: space-around;
				align-items: center;
				margin-top: 49px;
				margin-left: 48px;
				img{
					margin-right: 10px;
					margin-left: 21px;
				}
				.text1{
					width: 80px;
					height: 22px;
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #333333;
					line-height: 22px;
					margin-right: 10px;
				}
				.text2{
					width: 20px;
					height: 20px;
					background: #D70A16;
					border-radius: 10px;
					text-align: center;
					color: white;
				}
			}
		}
		.sort{
			height: 46px;
			// background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
			border-radius: 2px 2px 0px 0px;
			margin-top: 65px;
			display: flex;
			margin-bottom: 5px;
			.div1{
				width: 270px;
				height: 46px;
				line-height: 46px;
				background: linear-gradient(180deg, #FF7988 0%, #D70A16 100%);
				border-radius: 2px 2px 0px 0px;
				img{
					margin-left: 82px;
					width: 19px;
					height: 17px;
					color: #FFFFFF;
				}
				span{
					width: 80px;
					height: 30px;
					font-size: 20px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 30px;
					margin-left: 15px;
				}
			}
			.div2{
				width: 99px;
				height: 46px;
				span-align: center;
				line-height: 46px;
				margin-left: 16px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #D70A16;
			}
		}
	}
</style>