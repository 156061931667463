<template>
	<div style="">
		<div style="background-color: #F4F5F5;height: auto;padding: 1px;">
			<!-- <div class="center" style="padding: 1px;"> -->
				<el-breadcrumb style="margin-top: 20px;margin-bottom: 20px;" separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item>个人中心</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="main">
					<div class="left">
						<p>个人中心</p>
						<div class="line"></div>
						<el-menu
						      default-active="2"
						      class="el-menu-vertical-demo"
						      @open="handleOpen"
						      @close="handleClose">
							  <el-menu-item index="1">
								  <router-link :to="{ path: '/Mydd' }"><el-menu-item index="1-1">我的订单</el-menu-item></router-link>
								</el-menu-item>
							  <el-menu-item index="2">
								  <router-link :to="{ path: '/Zhxx' }"><el-menu-item index="1-2">账号信息</el-menu-item></router-link>
								</el-menu-item>
								<el-menu-item index="3">
								  <router-link :to="{ path: '/Gr_dzgl' }"><el-menu-item index="1-3">地址管理</el-menu-item></router-link>
								</el-menu-item>
								<el-menu-item index="4">
								  <router-link :to="{ path: '/Wdyhq' }"><el-menu-item index="1-4">我的优惠券</el-menu-item></router-link>
								</el-menu-item>
						</el-menu>
					</div>
				</div>
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Grzx',
		data() {
		      return {
		        
		      };
		},
		components:{
			
		},
		methods:{
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}

		}
	}
</script>

<style lang="less" scoped>
	.main{
		width: 270px;
		margin-bottom: 48px;
		.left{
			width: 270px;
			height: 757px;
			background: #FFFFFF;
			border-radius: 4px;
			padding: 1px;
			p{
				width: 96px;
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #000000;
				line-height: 36px;
				margin: 24px;
			}
		}
		.line{
			width: 270px;
			height: 1px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.el-menu{
			border: none;
		}
	}
</style>