<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>配送政策 > 配送服务</p>
					<div class="line"></div>
					<div class="cont">
						<p class="p1">一、3Q五金城物流</p>
						<p class="p2">3Q五金城支持各区域配送，具体配送范围以提交订单页配送方式为准。</p>
						
						<p class="p1">二、发货时间规则</p>
						<p class="p2">客户当天16：00前下达的订单，在当日发出；16：00后下达的订单，在次日发出。</p>
						<p class="p3">注：受车辆限行、路况、天气等因素影响，实际配送时效可能发生变化，敬请谅解！</p>
						
						<p class="p1">三、配送运费</p>
						<table border="1px" style="margin-left: 40px;width: 400px;height: 50px;text-align: center;line-height: 50px;">
							<tr>
								<td>订单金额</td>
								<td>基础运费</td>
							</tr>
							<tr>
								<td>0元<=订单金额</td>
								<td>免费送货</td>
							</tr>
						</table>
						<p class="p3">注：特殊品类商品因体积、重量等原因需加收运费，具体运费以下单后系统实际计算为准。</p>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Dzgl',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: auto;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		margin-bottom: 48px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			.p1{
				font-size: 16px;
			}
			.p2{
				font-size: 14px;
			}
			.p3{
				font-size: 10px;
				color: #D70A16;
			}
		}
	}
</style>