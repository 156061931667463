<template>
	<div style="">
		<div class="whole">
			<div class="top">
				<div class="center">
					<div class="top-head">
						<div>
							<img style="width: 16px;height: 16px;" src="../../assets/image/index/header_icon_address_default.png" alt="">
							<span style="color: #777777;margin-left: 4px;">
								<div class="block">
								  <el-cascader size="mini"
								    :options="options"
								  	placeholder="天津市"
								    ></el-cascader>
								</div>
							</span>
							<span style="width: 180px;margin-left: 25px;font-weight: 400;color: #000000;font-family: PingFangSC-Regular, PingFang SC;">
								<div v-if="user_news==null">
									<router-link :to="{ path: '/' }"><span>登录</span></router-link>
									<span style="margin-left: 10px;margin-right: 10px;">|</span>
									<router-link :to="{ path: '/Register' }"><span>注册</span></router-link>
								</div>
								<div style="width: 230px;" v-else>
									Hi~ {{telephone}} 欢迎您
									<span style="margin-left: 20px;cursor:pointer;" @click="loginOut()">退出</span>
								</div>
							</span>
						</div>
						<div>
							<img style="width: 13px;height: 13px;" src="../../assets/image/index/header_icon_iphone_default.png" alt="">
							<span style="margin-left: 4px;color:#D70A16;"><span style="color:#777777;">客服电话</span> 022-26482742</span>
						</div>
					</div>
				</div>
			</div>
			<div class="top1">
				<div class="center">
					<div class="top-main">
						<div class="logo">
							<div class="logo">
								<img src="../../assets/image/3q_logo.png" alt="" srcset="" style="margin-top: 20px;" @click="to_index()">
							</div>
							<div class="steps">
								<el-steps :space="320" :active="1" finish-status="success">
								  <el-step title="线下支付"></el-step>
								  <el-step title="完成"></el-step>
								</el-steps>
							</div>
						</div>
						<div style="width:538px;height: 22px;margin:24px auto;font-size: 14px;">
							
						</div>
					</div>
				</div>
			</div>
			<div class="line" style="width: 100%;height: 1px;background: #EEEEEE;"></div>
			<div class="center" style="border-top: 4px solid #D70A16;border: 1px solid #979797;height: 726px;">
				<div class="orderNew">
					<div style="margin-top: 43px;display: flex;align-items: center;justify-content: center;">
						<img src="../../assets/image/jhd/pay_icon_succ_56.png" alt="">
						<span>订单已提交成功，请您及时付款，我们将尽快为您安排发货!</span>
					</div>
					<p>订单编号：{{order_all_id}}    应付款：<span style="color: #D70A16;">￥{{total_money}}</span></p>
					<p>( {{count}}内完成付款，否则订单将自动取消! )</p>
				</div>
				<div class="saoma">
					<span style="display: flex;align-items: center;">
						<img src="../../assets/image/jhd/details_butten_xxpay.png" alt="" srcset="" style="margin: auto;">
						<span style="margin-right: 35px;font-size: 13px;cursor: pointer;background-color: #D70A16;color: white;padding: 5px;" @click="to_index()">返回首页</span>
					</span>
				</div>
				<div class="xianxia">
					<p class="xtop">线下转账信息<span>（温馨提示：请按照以下信息进行线下转账汇款操作）</span></p>
					<div class="order">
						<div class="left">
							<div>
								<p>用户名</p>
								<span>天津大鹏展程科技有限公司</span>
							</div>
							<div class="line"></div>
							<div>
								<p>账号</p>
								<span>200062000260883</span>
							</div>
							<div class="line"></div>
							<div>
								<p>开户银行</p>
								<span>天津市北辰村镇银行股份有限公司津围路支行</span>
							</div>
							<div class="line"></div>
							<div>
								<p>行联号</p>
								<span>3141100900166</span>
							</div>
							<div class="line"></div>
							<div>
								<p>用途/备注/摘要 <br>（重要：必填）</p>
								<span style="line-height: 108px;">订单编号:{{order_all_id}}</span>
							</div>
						</div>
						<div class="right">
							<p class="p1">注意事项（转账必读）</p>
							<p class="p2">1.公司转账汇款时请将订单编号{{order_all_id}}填写至汇款单中“用途/备注/摘要”等栏;</p>
							<p class="p2">2.下单后请尽快转账，务必保证转账金额与订单金额一致，请勿多转/少转或分次转账，否则影响订单对账进度:如未及时付款订单将在下单5天后自动取消;</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Tjxx',
		data(){
			return{
				options: [{
					value: 'tainjin',
					label: '天津',
					children: [
						{
							value: '1',
							label: '河东区',
						},{
							value: '2',
							label: '红桥区',
						},{
							value: '3',
							label: '西青区',
						},{
							value: '4',
							label: '津南区',
						},{
							value: '5',
							label: '河西区',
						}
					]
				}],
				count: '', //倒计时
				seconds: 432000 ,// 5天的秒数
				total_money:'', // 应支付价格
				order_all_id:'', // 订单号
			}
		},
		components:{
			Footer
		},
		created () {
			let t=this
			this.user_news = JSON.parse(localStorage.getItem('user'));
			this.telephone=this.user_news.telephone
			this.order_all_id=this.$router.currentRoute.query.order_all_id
			this.total_money=this.$router.currentRoute.query.total_money
			// this.petty=Number(this.num)*Number(this.danjia)
			this.Time() //5天倒计时
		},
		methods:{
			// 退出登录
			loginOut(){
				localStorage.clear();
				this.$router.go()
			},
			to_index(){
				console.log(15165132)
				this.$router.replace({
					path:'/',
				})
				this.$router.go()
			},
			// 5天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.seconds / (24 * 60 * 60))
				d = d < 10 ? "0" + d : d
				let h = parseInt(this.seconds / (60 * 60) % 24);
				h = h < 10 ? "0" + h : h
				let m = parseInt(this.seconds / 60 % 60);
				m = m < 10 ? "0" + m : m
				let s = parseInt(this.seconds % 60);
				s = s < 10 ? "0" + s : s
				this.count = d + '天' + h + '时' + m + '分' + s + '秒'
			},
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					this.seconds -= 1
					this.countDown()
				}, 1000)
			},
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-input{
		width: 85%;
	}
	/deep/.el-input__suffix{
		display: none;
	}
	/deep/ .el-cascader .el-input .el-input__inner{
		border: none;
		background-color: #F8F8F8;
		width: 70%;
		padding: 0px;
	}
	.whole{
		width: 100%;
		background-color: #FFFFFF;
	}
	.top{
		width: 100%;
		background: #F8F8F8;
		.center{
			.top-head{
				height: 44px;
				display: flex;
				line-height: 44px;
				font-size: 14px;
				justify-content: space-between;
				background-color: #F8F8F8;
				div{
					display: flex;
					align-items: center;
				}
			}
		}
	}
	.top-main{
		height: 200px;
		background: #FFFFFF;
		.logo{
			height: 64px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			align-self: center;
			.logo{
				margin-top: 40px;
			}
			.steps{
				width: 960px;
				margin-top: 86px;
			}
		}
	}
	
	.orderNew{
		width: 1280px;
		height: 206px;
		background: #FAF4F4;
		margin: auto;
		text-align: center;
		margin-top: 40px;
		padding: 1px;
		div{
			height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #52C41A;
			line-height: 36px;
			span{
				margin-left: 10px;
			}
		}
		p{
			margin-top: 23px;
		}
	}
	.saoma{
		margin-top: 41px;
		text-align: center;
		
	}
	
	.xianxia{
		.xtop{
			font-size: 20px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			margin-left: 40px;
			span{
				font-size: 14px;
				color: #979797;
			}
		}
		.order{
			margin-top: 16px;
			display: flex;
			.left{
				width: 826px;
				height: 325px;
				background: #FFFFFF;
				border-radius: 4px;
				border: 1px solid #DDDDDD;
				margin-left: 40px;
				div{
					display: flex;
					height: 54px;
					line-height: 54px;
					p{
						width: 185px;
						height: 54px;
						padding-left: 24px;
						font-size: 16px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: rgba(0,0,0,0.85);
					}
					span{
						width: 640px;
						height: 54px;
					}
				}
				.line{
					width: 100%;
					height: 1px;
					background: #EEEEEE;
				}
			}
			.right{
				width: 446px;
				height: 296px;
				background: #FAF4F4;
				margin-left: 8px;
				padding-left: 24px;
				padding-right: 24px;
				.p1{
					font-size: 20px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #D70A16;
					margin-top: 24px;
				}
				.p2{
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: rgba(0,0,0,0.85);
					margin-top: 13px;
				}
			}
		}
	}
</style>