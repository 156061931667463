<template>
	<div style="">
		<Top></Top>
		<div class="main">
			<div class="center">
				<div class="tit">
					<div class="top">
						<div style="display: flex;align-items: center;">
							<p>整点秒杀</p>
							<span>｜更多好货限时疯抢</span>
						</div>
					</div>
				</div>
				<!-- <div class="time">
					<div class="cut">
						<p>12:00</p>
						<span>正在进行中</span><br>
						<span>距离活动结束:00:00:00</span>
					</div>
					<div>
						<p>12:00</p>
						<span>即将开始</span>
					</div>
					<div>
						<p>12:00</p>
						<span>即将开始</span>
					</div>
					<div>
						<p>12:00</p>
						<span>即将开始</span>
					</div>
					<div>
						<p>12:00</p>
						<span>即将开始</span>
					</div>
				</div> -->
				<ul>
					<li v-for="item in zdmsList" @click="click_shopping(item)">
						<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 10px 28px 10px;">
							<img src="../../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
							<img style="width: 228px;height: 228px;" :src="item.images" alt="">
						</div>
						<p style="margin-bottom: 8px;height: 42px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.goodsname}}</p>
						<p style="margin-bottom: 8px;font-size: 12px;color: #666666;">产品编码:{{item.id}}</p>
						<p class="p">￥{{item.price}}</p>
					</li>
					
				</ul>
				<el-pagination style="text-align: center;margin-bottom: 48px;"
				  @current-change="handleCurrentChange"
				  :current-page.sync="currentPage"
				  background
				  layout="prev, pager, next"
				  :total="counts"
				  :page-size="20">
				</el-pagination>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../../components/Top.vue';
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Index',
		data(){
			return{
				zdmsList:[] ,// 整点秒杀
				currentPage:1, // 当前页
				counts:null, // 总页数
			}
		},
		components: {
			Top,Footer
		},
		created(){
			this.zdms()
		},
		methods:{
			handleCurrentChange(val) {
				console.log(val);
				// console.log(this.currentPage1)
				this.zdms()
			},
			// 整点秒杀
			zdms(){
				// console.log(4165165165165)
				this.$axios.get('/Home/msdate',{params:{page:this.currentPage}}).then(res=>{
					this.zdmsList=res.data.data
					this.counts=Number(res.data.count.count) 
				})
			},
			// 点击商品跳转详情页
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
		},
	}
</script>

<style lang="less" scoped>
	.main{
		background-color: #F4F5F5;
		padding: 1px;
		margin-top: 6px;
	}
	.center{
		height: auto;
		width: 1408px;
		padding-bottom: 20px;
	}
	.tit{
		background-image: url('../../assets/huodong/ohsale_pic_bg.png');
		height: 505px;
	}
	.top{
		display: flex;
		margin-top: 40px;
		color: #FFFFFF;
		align-items: center;
		justify-content: space-between;
		p{
			font-size: 36px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			margin: 32px 0px 32px 24px;
		}
		span{
			font-size: 20px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
	
	.time{
		margin-top: -400px;
		display: flex;
		justify-content: space-around;
		text-align: center;
		div{
			width: 282px;
			height: 76px;
			background: url('../../assets/huodong/ohtime_bg_nor.png') no-repeat;
			p{
				margin-top: 16px;
			}
			span{
				margin-top: 2px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #999999;
			}
		}
		.cut{
			background: url('../../assets/huodong/ohtime_bg_sel.png') no-repeat;
			p{
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #D70A16;
				margin-top: 5px;
			}
			span{
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #D70A16;
			}
		}
	}
	ul{
		height: auto;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		// margin-top: 40px;
		padding-bottom: 40px;
		margin-top: -410px;
		.el-button--primary{
			width: 32px;
			height: 64px;
			background: #000000;
			opacity: 0.2;
			margin-top: 163px;
		}
		li{
			width: 248px;
			height: 392px;
			background: #FFFFFF;
			margin-top: 16px;
			margin-left: 28px;
			p{
				width: 220px;
				margin-left: 16px;
			}
			.p{
				width: 220px;
				height: 38px;
				line-height: 38px;
				background-image: url('../../assets/image/index/sy_butten_salebuy.png');
				padding-left: 8px;
				color: #D70A16;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
</style>