<template>
	<div style="">
		<Top></Top>
		<div class="main">
			<div class="center">
				<div class="tit">
					<div class="top">
						<div style="display: flex;align-items: center;">
							<p>每日新品</p>
							<span>｜新品速递 好货快享</span>
						</div>
						<div style="margin-right: 24px;">
							<!-- 日期：2022年10月1日 -->
						</div>
					</div>
				</div>
				<ul>
					<li v-for="item in mrxp_List" @click="click_shopping(item)">
						<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 16px 28px 16px;">
							<img src="../../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
							<img style="width: 228px;height: 228px;" :src="item.image" alt="">
						</div>
						<p style="width: 228px;margin-left: 16px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;display: -webkit-box;-webkit-box-orient: vertical;">{{item.goodsname}}</p>
						<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：{{item.id}}</p>
						<div style="margin-top: 8px;">
							<span style="width: 66px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;">¥{{item.protmoney}}</span>
							<span style="width: 53px;margin-right:16px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">已售{{item.sales}}</span>
						</div>
					</li>
				</ul>
				<el-pagination style="text-align: center;margin-bottom: 48px;"
				  @current-change="handleCurrentChange"
				  :current-page.sync="currentPage"
				  background
				  layout="prev, pager, next"
				  :total="counts"
				  :page-size="20">
				</el-pagination>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top from '../../components/Top.vue';
	import Footer from '../../components/Footer.vue'
	export default {
		name: 'Index',
		data(){
			return{
				mrxp_List:[],
				currentPage:1, // 当前页
				counts:null, // 总页数
			}
		},
		components: {
			Top,Footer
		},
		created(){
			this.mrxp()
		},
		methods:{
			handleCurrentChange(val) {
				console.log(val);
				// console.log(this.currentPage)
				this.mrxp()
			},
			mrxp(){
				this.$axios.get('/Detail/eve_day',{params:{page:this.currentPage}}).then(res=>{
					// console.log(res.data.data)
					this.mrxp_List=res.data.data
					this.counts=Number(res.data.count[0].count) 
				})
			},
			// 点击商品跳转详情页
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			}
		},
	}
</script>

<style lang="less" scoped>
	.main{
		background-color: #F4F5F5;
		padding: 1px;
		margin-top: 6px;
	}
	.center{
		height: auto;
	}
	.tit{
		background-image: url('../../assets/huodong/newpro_pic_bg.png');
		height: 535px;
	}
	.top{
		display: flex;
		margin-top: 40px;
		color: #FFFFFF;
		align-items: center;
		justify-content: space-between;
		p{
			font-size: 36px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			margin: 32px 0px 32px 24px;
		}
		span{
			font-size: 20px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
	}
	ul{
		width: 100%;
		display: flex;
		margin-top: -440px;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: 48px;
		li{
			margin-top: 10px;
			width: 248px;
			height: 400px;
			background: #FFFFFF;
			border-radius: 4px;
			margin-left: 20px;
			div{
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
</style>