<template>
	<div style="">
		<Top1></Top1>
		<div class="box">
			<div class="center">
				<Bzzx></Bzzx>
				<div class="main">
					<p>关于我们 > 公司介绍</p>
					<div class="line"></div>
					<div class="cont">
						<p class="p1">公司介绍：</p>
						<p class="">
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;">天津大鹏展程科技有限公司成立于2016年04月18日，注册地位于天津市东丽区金钟街道金钟公路3699号C16-29号-2室，法定代表人为王鹏。</p>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;">3Q五金城（3qwujin.com）是一家数字化的工业用品服务平台，覆盖多地区的服务，为多地区企业带来更专业、更稳定、更持续的服务。涵盖行业包括建筑材料、装饰装修材料、管材管件、五金电料、金属材料、橡塑制品、机械设备、卫浴洁具、化工产品（化学危险品及易制毒品除外）批发兼零售。</p>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;">3Q五金城以优质的客户服务，成就自身价值。为客户提供一站式的工业用品采购与管理服务，实现工业用品供应链的透明、高效、降成本。全面满足客户对高性能商品的采购需求，帮助客户简化采购流程，节约采购成本，提高运营效率。</p>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">企业精神 </p><br>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">勤奋、敬业、进取、团结。 </p><br>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">核心价值观 </p><br>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">客户为先、以人为本、用心履职、求真务实、持续改进、激情奋斗。</p><br>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">工作作风</p> <br>
							<p style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;text-indent: 2em;margin-top:0px;margin-bottom:0px;">知行合一、快速行动。</p><br>
						</p>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Top1 from "../../components/Top1.vue"
	import Footer from "../../components/Footer.vue"
	import Bzzx from "../../components/Bzzx.vue"
	export default {
		name: 'Gsjj',
		data() {
		      return {
		        
		      };
		},
		components:{
			Bzzx,Footer,Top1
		},
		methods:{

		}
	}
</script>

<style lang="less" scoped>
	.box{
		background-color: #F4F5F5;
		.center{
			display: flex;
			justify-content: space-between;
		}
	}
	.main{
		width: 1074px;
		height: 757px;
		background: #FFFFFF;
		border-radius: 2px;
		margin-top: 55px;
		p{
			margin-top: 24px;
			margin-bottom: 24px;
			margin-left: 40px;
			font-size: 32px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
		.line{
			width: 1074px;
			height: 2px;
			background: #EEEEEE;
			border-radius: 0px 0px 4px 4px;
		}
		.cont{
			width: 980px;
			.p1{
				height: 36px;
				font-size: 24px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 36px;
			}
			.p2{
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
				text-indent: 2em;
			}
		}
	}
</style>