<template>
	<div style="">
		<div class="bottom_img" style="width:100%;">
			<img style="width: 100%;" src="../assets/image/index/sy_footer_sellingpoint.png" alt="" srcset="">
		</div>
		<div class="footer">
			<div class="center">
				<div class="top">
					<ul>
						<li class="li1">购物指南</li>
						<li class="li2"><router-link :to="{ path: '/Zclc' }">帮助中心</router-link></li>
					</ul>
					<ul>
						<li class="li1">服务政策</li>
						<li class="li2"><router-link :to="{ path: '/Psfw' }">配送服务</router-link></li>
						<li class="li2"><router-link :to="{ path: '/Yhxy' }">用户协议</router-link></li>
						<li class="li2"><router-link :to="{ path: '/Ysxy' }">隐私政策</router-link></li>
					</ul>
					<ul>
						<li class="li1">关于我们</li>
						<li class="li2"><router-link :to="{ path: '/Gsjj' }">公司介绍</router-link></li>
						<li class="li2"><router-link :to="{ path: '/Lxwm' }">联系我们</router-link></li>
					</ul>
					<ul style="text-align: center;">
						<li><img style="width: 385px;height: 102px;background: #FFFFFF;margin-top: 49px;" src="../assets/image/footer_3qlogo_white.png" alt=""></li>
						<li style="margin-top: 14px;height: 36px;font-size: 24px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D8D8D8;line-height: 36px;">数字化的工业用品服务平台</li>
						<li class="line"></li>
						<li style="height: 27px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #D8D8D8;line-height: 27px;">工作时间：周一到周五 8:30-17:30</li>
					</ul>
				</div>
			</div>
			
		</div>
		<div class="bottom">
			<router-link target="_blank" :to="{path:'/Yyzz'}" style="color: #ccc;">营业执照</router-link>
			<p style="margin-top: 10px;">津ICP备2022000121号-1</p>
		</div>
	</div>
</template>

<script>
	export default {
	  name: 'Footer',
	  methods:{
		
	  }
	}
</script>

<style lang="less" scoped>
	.bottom_img{
		width: 1903px;
		overflow: hidden;
	}
	.footer{
		width: 100%;
		height: 304px;
		background: rgba(40,42,44,0.95);
		.center{
			padding: 1px;
			.top{
				display: flex;
				justify-content: space-around;
				ul{
					.li1{
						margin-top: 49px;
						width: 80px;
						height: 28px;
						font-size: 20px;
						font-family: PingFangSC-Semibold, PingFang SC;
						font-weight: 600;
						color: #D8D8D8;
						line-height: 28px;
					}
					.li2{
						margin-top: 12px;
						width: 56px;
						height: 28px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #CCCCCC;
						line-height: 28px;
					}
					.line{
						width: 387px;
						height: 1px;
						background: #CCCCCC;
						opacity: 0.36;
						margin-top: 16px;
						margin-bottom: 16px;
					}
					.router-link-active {
					  text-decoration: none;
					  color: #CCCCCC;
					}
					a{
						text-decoration: none;
						color: #CCCCCC;
					}
				}
			}
			
		}
		
	}
	.bottom{
		width: 100%;
		height: 96px;
		background: #282A2C;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #CCCCCC;
		text-align: center;
		padding-top: 30px;
	}
</style>