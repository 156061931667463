<template>
	<div class="noselect" style="overflow: ;">
		<Top></Top>
		<div class="nav">
			<div class="center">
				<el-breadcrumb separator-class="el-icon-arrow-right">
				  <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
				  <el-breadcrumb-item>产品详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="main">
			<div class="center">
				<!-- 商品信息 -->
				<div class="ProductInfo" v-for="item in goodsxq">
					<div style="display: flex;">
						<!-- 预览图 -->
						<div class="preview">
							<div class="big" style="position: relative;">
								<img src="../assets/image/3Qshuiyin.png" style="width: 430px;height:490px;position: absolute;top:-6px;left:23px;" alt="">
								<img :src='item.img' alt="" v-if="thumb==''">
								<img :src="thumb" alt="" v-else>
							</div>
							<div style="display: flex;">
								<ul class="small" v-for="(item,index) in smallimg2">
									<!-- <el-button size="mini" type="primary" icon="el-icon-arrow-left"></el-button> -->
									<li style="position: relative;cursor: pointer;" @click="yiru(item)">
										<img src="../assets/image/3Qshuiyin.png" style="width: 100px;height:108px;position: absolute;top:-6px;left:0px;" alt="">
										<img :src="item.thumb" alt="">
									</li>
									<!-- <el-button size="mini" type="primary" icon="el-icon-arrow-right"></el-button> -->
								</ul>
								<ul class="small" v-for="(item,index) in smallimg1">
									<li style="position: relative;cursor: pointer;" @click="yiru(item)">
										<img src="../assets/image/3Qshuiyin.png" style="width: 100px;height:108px;position: absolute;top:-6px;left:0px;" alt="">
										<img :src="item.thumb" alt="">
									</li>
								</ul>
							</div>
						</div>
						<!-- 规格信息 -->
						<div class="norms">
							<p class="title">{{item.goodsname}}<span>产品编码：{{item.id}}</span></p>
							<!-- 限时秒杀 -->
							<!-- <div class="xsms">
								<div class="tit">限时秒杀</div>
								<div class="djs">本场距结束还剩<span>01</span><span>02</span><span>03</span></div>
							</div> -->
							<div class="jg">
								<div style="width: 400px;">
									<span style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;margin: 24px 20px;">价格：</span>
									<span style="width: 70px;height: 48px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;" v-if="number==''">￥{{item.number}}</span>
									<span style="width: 70px;height: 48px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;" v-else>￥{{number}}</span>
								</div>
								<div style="font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #333333;margin-left: 350px;">
									<span>销量：</span>
									<span>{{item.sales}}</span>
								</div>
							</div>
							<!-- 商品信息 -->
							<div class="goods">
								<div>
									<span class="span1">已选商品：</span>
									<span class="span2" v-if="guige==''">请您先选择您需要的规格</span>
									<span class="span2" v-else>{{guige}}</span>
								</div>
								<!-- <div>
									<span class="span1">包装数量：</span>
									<span class="span2">{{itm.number}}</span>
								</div>
								<div>
									<span class="span1">订货号：</span>
									<span class="span2">012101012231</span>
								</div>
								<div>
									<span class="span1">重量：</span>
									<span class="span2">150g</span>
								</div>
								<div>
									<span class="span1">商品型号：</span>
									<span class="span2">3DM-241</span>
								</div> -->
							</div>
							<div class="line"></div>
							<!-- 规格信息 -->
							<div class="guige">
								<span class="span1">规格名称：</span>
								<span v-for="(itm,index) in item.option">
									<div ref="guige" :class="{'active':isActive===index}" @click="guige_color(itm,index)" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{itm.title}}</div>
								</span>
								
							</div>
							<div class="line"></div>
							<!-- 地址 -->
							<div class="site">
								<span class="span1">配送至：</span>
								<div class="zuobiao"><img src="../assets/image/index/header_icon_address_default.png" alt=""><span>天津市</span> <span style="padding-right: 8px;">天津市</span></div>
							</div>
							<div class="line"></div>
							<!-- 支付方式 -->
							<div class="pay">
								<span class="span1">支付方式：</span>
								<img src="../assets/image/index/details_butten_wxpay.png" alt="">
								<img src="../assets/image/index/details_butten_xxpay.png" alt="">
							</div>
							<div class="line"></div>
							<!-- 采购量 -->
							<div class="quantity">
								<span class="span1">采购量：</span>
								<el-input-number size="small" v-model="num" @change="handleChange" :min="1" :max="100000" style="margin-right: 24px;"></el-input-number>
								<span class="span2">起批增量：{{num}}个</span>
							</div>
							<div class="settlement">
								<div class="jhd" @click="to_cart(item)">加入进货单</div>
								<div class="ljgm" @click="go_order(item)">立即购买</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 店铺介绍 -->
				<!-- <div class="shop">
					<div>
						<img src="../assets/58dcd9ae7261d0d861ce47d431b3ff1.jpg" alt="">
					</div>
					<div>
						<p class="tit">STANLEY五金店</p>
						<p class="dph">店铺号：539043</p>
						<p class="yewu">主营业务: <span>五金</span><span>扳手</span><span>轮胎</span></p>
					</div>
					<div>
						<p class="jrdp">进入店铺</p>
					</div>
				</div> -->
				<!-- 相关推荐 -->
				<div class="sell">
					<div class="left">
						<p>相关商品</p>
						<ul>
							<li v-for="item in shopping" @click="click_shopping(item)">
								<div style="position: relative;margin-left: 16px;width: 228px;height: 228px;margin: 16px 16px 28px 16px;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 230px;height:220px;position: absolute;top:6px;left:0px;" alt="">
									<img style="width: 228px;height: 228px;" :src="item.img" alt="">
								</div>
								<p style="width: 228px;margin-left: 9px;height: 48px;font-size: 16px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 24px;">{{item.goodsname}}</p>
								<p style="margin-left: 16px;margin-top:8px;width: 110px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 18px;">产品编码：{{item.codes}}</p>
								<div style="margin-top: 8px;display: flex;justify-content: space-between;align-items: center;">
									<span style="width: 150px;margin-left: 16px;height: 30px;font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #D70A16;line-height: 30px;overflow: hidden;text-overflow: ellipsis;">¥{{item.protmoney}}</span>
									<span style="width: 53px;height: 18px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #999999;line-height: 18px;">已售{{item.sales}}</span>
								</div>
							</li>
						</ul>
					</div>
					<div class="right">
						<div><p>产品详情</p></div>
						<div class="xqt">
							<!-- <img src="../assets/image/3Qshuiyin.png" style="position: absolute;top:54px;left:-27px;width: 435px;height: 480px;" alt="" srcset=""> -->
							<!-- <ul style="width: 100%;" v-html="content"></ul> -->
							<ul class="small" v-for="(item,index) in smallimg2">
								<!-- <el-button size="mini" type="primary" icon="el-icon-arrow-left"></el-button> -->
								<li style="position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 710px;height:770px;position: absolute;top:15px;left:45px;" alt="">
									<img style="width: 800px;height: 800px;" :src="item.thumb" alt="">
								</li>
								<!-- <el-button size="mini" type="primary" icon="el-icon-arrow-right"></el-button> -->
							</ul>
							<ul class="small" v-for="(item,index) in smallimg1">
								<li style="position: relative;">
									<img src="../assets/image/3Qshuiyin.png" style="width: 720px;height:775px;position: absolute;top:15px;left:38px;" alt="">
									<img style="width:800px;height:800px;" :src="item.thumb" alt="">
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Footer from '../components/Footer.vue'
	import Top from '../components/Top.vue'
	export default {
		name: 'Cpxq',
		data(){
			return{
				// imgSrc:'http://localhost:8080/img/58dcd9ae7261d0d861ce47d431b3ff1.d45edaa7.jpg',
				num: 1,  // 起批量
				gid:'',
				goodsxq:[],  // 商品详情
				shopping:[], // 相关商品
				codes:'',
				option_item_ids:'',  // 商品规格
				content:'',  // 商品详情介绍
				number:'',  // 点击商品的价格
				thumb:'', // 选中的商品大图
				guige:'' ,// 选中商品规格
				user_news:'',
				user_id:'',
				color:'',
				isActive:'',
				qipi:'',
				smallimg:[],
				smallimg1:[],
				smallimg2:[],
				xqt:[],
				form:{
					thumb:''
				}
			}
		},
		components:{
			Footer,Top
		},
		created() {
			// 获取codes
			this.codes=this.$router.currentRoute.query.shopping_codes
			this.goods()
			this.xg_goods()
		},
		methods:{
			yiru(item){
				// this.isActive=index
				// console.log(item,111111)
				this.thumb=item.thumb
			},
			handleChange(value) {
				console.log(value);
			},
			// 商品详情
			goods(){
				// console.log(this.codes)
				this.$axios.get('/Detail/goods_details',{params:{gid:this.codes}}).then(res=>{
					// console.log(res.data.data[0].img)
					this.goodsxq=res.data.data
					this.content=res.data.data[0].content
					this.number=res.data.data[0].goods_scope
					this.smallimg=res.data.data[0].option
					this.smallimg.forEach(val=>{
						// console.log(val)
						this.smallimg2.push(val)
						if(this.smallimg2.length>1){
							this.smallimg2.splice(1)
						}
					})
					this.form.thumb=res.data.data[0].img
					this.smallimg1.unshift(this.form)
					// console.log(this.smallimg)
					// console.log(this.content)
					if(res.data.cide==1){
						this.$message.error('暂无商品信息,请稍后重试');
					}
				})
			},
			// 相关商品
			xg_goods(){
				// this.codes=this.$router.query.shopping_codes;
				// console.log(this.$router.query.shopping_codes)
				this.$axios.get('/Detail/any_goods',{params:{gid:this.codes}}).then(res=>{
					// console.log(res.data.data)
					this.shopping=res.data.data
				})
			},
			// 选中规格
			guige_color(itm,index){
				this.isActive=index
				this.number=itm.protmoney // 商品价格
				this.option_item_ids=itm.option_item_ids  // 商品规格
				this.thumb=itm.thumb // 商品大图
				this.guige=itm.title
				this.num=Number(itm.number)
				// console.log(this.qipi)
			},
			// 加入购物车
			to_cart(item){
				this.user_news = JSON.parse(localStorage.getItem('user'));
				// this.user_id = this.user_news.member_id
				if(this.user_news==null){
					this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
					setTimeout(res=>{
						this.$router.replace({
							path:'/login',
						})
					},3000)
				}else if(this.option_item_ids==''){
					this.$message.error('请您先选择商品规格');
					return
				}else{
					this.user_news = JSON.parse(localStorage.getItem('user'));
					this.user_id = this.user_news.member_id
					this.$axios.get('/Detail/add_car',{params:{goods_id:item.id,quantity:this.num,sku_str:this.option_item_ids,user_id:this.user_id}}).then(res=>{
						console.log(132132465)
						if(res.data.code==1){
							// console.log('已成功加入购物车!')
							this.$message.success('已成功加入进货单!');
						}
					})	
				}
			},
			// 立即购买
			go_order(item){
				this.user_news = JSON.parse(localStorage.getItem('user'));
				// this.user_id = this.user_news.member_id
				console.log(this.user_id)
				if(this.user_news==null){
					this.$message.error('请先登录您的账号,3秒后为您跳转登录页面');
					setTimeout(res=>{
						this.$router.replace({
							path:'/login',
						})
					},3000)
				}else if(this.option_item_ids==''){
					this.$message.error('请您先选择商品规格');
					return
				}
				// else{
				// 	this.user_news = JSON.parse(localStorage.getItem('user'));
				// 	this.user_id = this.user_news.member_id
				// 	this.$axios.get('/Detail/add_car',{params:{goods_id:item.id,quantity:this.num,sku_str:this.option_item_ids,user_id:this.user_id}}).then(res=>{
				// 		console.log(132132465)
				// 		if(res.data.code==1){
							this.$router.replace({
								path:'/Tjdhd',
								query:{
									'order_id':item.id,
									'mat':'one',
									'quantity':this.num,
									'goods_id':item.id,
									'sku_str':this.option_item_ids
								}
							})
				// 		}
				// 	})
				// }
			},
			// 跳转商品详情页面
			click_shopping(item){
				// console.log(item.codes)
				let Detail=this.$router.resolve({
					path:'/Cpxq',
					query:{
						'shopping_codes':item.id
					}
				})
				window.open(Detail.href,'_blank')
			},
		}
	}
</script>

<style scoped lang="less">
	
	.active{
		color: red;
		border: 1px solid red !important;
	}
	.nav{
		margin-top: 5px;
		background-color: #F5F5F5;
		height: 50px;
		width: 100%;
		.el-breadcrumb{
			line-height: 3.5;
		}
	}
	.ProductInfo{
		margin-top: 61px;
		display: flex;
		justify-content: space-between;
	}
	// 预览图
	.preview{
		width: 480px;
		.big{
			width: 480px;
			height: 480px;
			background: #FFFFFF;
			border: 1px solid #DDDDDD;
			img{
				width: 480px;
				height: 480px;
			}
		}
		.small{
			display: flex;
			align-items: center;
			justify-content: space-between;
			// width: 480px;
			margin-top: 20px;
			height: 102px;
			
			li{
				width: 101px;
				height: 101px;
			}
			// li:hover{
			// 	width: 100px;
			// 	height: 100px;
			// 	background: #FFFFFF;
			// 	border: 1px solid #D70A16;
			// }
			img{
				width: 100px;
				height: 100px;
			}
			.el-button--primary{
				width: 32px;
				height: 64px;
				background: #000000;
				opacity: 0.2;
				border-radius: 2px;
			}
		}
	}
	// 规格信息
	.norms{
		width: 840px;
		margin-left: 40px;
		.title{
			// height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #000000;
			line-height: 36px;
			display: flex;
			justify-content: space-between;
			span{
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				margin-left: 300px;
			}
		}
		.xsms{
			width: 840px;
			height: 62px;
			margin-top: 20px;
			line-height: 62px;
			background-image: url('../assets/image/index/ltsale_details_pic_bg.png');
			display: flex;
			.tit{
				margin-left: 20px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
			}
			.djs{
				margin-left: 40px;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				span{
					width: 36px;
					height: 36px;
					background: #323436;
					border-radius: 2px;
					margin-left: 12px;
					font-size: 16px;
					color: #FFFFFF;
					font-weight: 400;
					padding: 6px 8px;
				}
			}
		}
		// 价格
		.jg{
			width: 840px;
			height: 72px;
			background: #F5F5F5;
			display: flex;
			line-height: 72px;
		}
		.goods{
			display: flex;
			flex-wrap: wrap;
			div{
				margin-top: 12px;
			}
		}
		.line{
			width: 523px;
			height: 1px;
			background: #EEEEEE;
			margin-top: 20px;
		}
		// 规格名称
		.guige{
			width: 689px;
			display: flex;
			flex-wrap: wrap;
			div{
				cursor:pointer;
				width: 240px;
				height: 46px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #D9D9D9;
				line-height: 46px;
				text-align: center;
				margin: 20px 16px 0px 24px;
			}
		}
		// 地址
		.site{
			margin-top: 20px;
			display: flex;
			img{
				width: 12px;
				height: 14px;
				padding: 9px 0px 9px 10px;
			}
			.zuobiao{
				display: flex;
				justify-content: space-around;
				width: 135px;
				height: 32px;
				border-radius: 2px;
				border: 1px solid #D9D9D9;
				line-height: 32px;
				margin-top: 13px;
			}
		}
		// 支付方式
		.pay{
			display: flex;
			align-items: center;
			img{
				margin-left: 24px;
				margin-top: 10px;
			}
		}
		// 采购量
		.quantity{
			margin-top: 20px;
		}
		.settlement{
			display: flex;
			margin-top: 36px;
			div{
				width: 180px;
				height: 54px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #D70A16;
				text-align: center;
				line-height: 54px;
				cursor:pointer;
			}
			.jhd{
				width: 180px;
				height: 54px;
				background: #D70A16;
				border-radius: 2px;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #FFFFFF;
				margin-left: 104px;
			}
			.ljgm{
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #D70A16;
				margin-left: 42px;
			}
		}
		.span1{
			margin: 20px 24px 12px 20px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
		}
		.span2{
			margin: 20px 168px 12px 0px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
		}
	}
	// 店铺介绍
	.shop{
		height: 200px;
		background: #FCFCFC;
		border: 1px solid #DDDDDD;
		margin-top: 40px;
		display: flex;
		img{
			width: 136px;
			height: 136px;
			border-radius: 4px;
			border: 1px solid #EEEEEE;
			margin: 32px;
		}
		.tit{
			height: 36px;
			font-size: 24px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: 36px;
			margin-top: 32px;
		}
		.dph{
			height: 24px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #666666;
			line-height: 24px;
			margin-top: 8px;
		}
		.yewu{
			margin-top: 46px;
			text-align: center;
			height: 22px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 22px;
			display: flex;
			span{
				width: 44px;
				height: 23px;
				border-radius: 2px;
				border: 1px solid #EEEEEE;
				margin-left: 8px;
			}
		}
		.jrdp{
			width: 260px;
			height: 56px;
			background: #F4F5F5;
			border-radius: 0px 0px 4px 4px;
			border: 1px solid #DDDDDD;
			font-size: 16px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #D70A16;
			text-align: center;
			line-height: 56px;
			margin-top: 120px;
			margin-left: 620px;
		}
	}
	// 推荐商品
	.sell{
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		.left{
			width: 260px;
			p{
				width: 260px;
				height: 56px;
				background: #F4F5F5;
				border-radius: 4px 4px 0px 0px;
				line-height: 56px;
				text-align: center;
				font-size: 16px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #D70A16;
			}
			ul{
				li{
					cursor:pointer;
					width: 260px;
					height: 400px;
					background: #FFFFFF;
					border: 1px solid #DDDDDD;
					p{
						background-color: #FFFFFF;
					}
				}
			}
		}
		.right{
			width: 1060px;
			div{
				width: 1060px;
				height: 56px;
				// background: #F4F5F5;
				p{
					width: 136px;
					height: 56px;
					background: #FFFFFF;
					text-align: center;
					line-height: 56px;
					border-top: 4px solid #D70A16;
					font-size: 16px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #D70A16;
				}
			}
			.xqt{
				margin-top: 24px;
				width: 1060px;
				// background: #F4F5F5;
				border: 1px solid #ccc;
				margin-bottom: 48px;
				height: auto;
				position: relative;
				ul{
					display: flex;
					flex-wrap: wrap;
				}
			}
		}
	}
</style>